var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLanguage } from '../../locale';
import { useGetCustomerApi, useGetFamilyPlanStatusApi, useGetPersonApi, } from '../../hooks';
import { useSelector } from 'react-redux';
import { isFamilyMemberMinor, isFamilyMemberRegisterRequested, } from '../../redux';
import { useUpdateProfileHelper } from './helpers/useUpdateProfileHelper';
import { PlanStatus, } from '../../models';
import { useFamilyMemberComposer } from './useFamilyMemberComposer.facade';
import { isMinor } from '../../utils';
export var useInputAvoidIngredientsFormFacade = function (_a) {
    var onAddPress = _a.onAddPress, onNext = _a.onNext, personId = _a.personId, _b = _a.isFamilyMemberUpdate, isFamilyMemberUpdate = _b === void 0 ? false : _b, confirmAlert = _a.confirmAlert;
    var t = useLanguage()[0];
    var _c = useState([]), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = useState([]), selectedCommonAllergies = _d[0], setSelectedCommonAllergies = _d[1];
    var _e = useState(false), isParentalResponsibilitySelected = _e[0], setIsParentalResponsibilitySelected = _e[1];
    //** REDUX **//
    var isNewFamilyMember = useSelector(isFamilyMemberRegisterRequested);
    var isFamilyMember = isNewFamilyMember || isFamilyMemberUpdate;
    var isMinorCreation = useSelector(isFamilyMemberMinor);
    var maxSelectableItemsValue = 5;
    var titleChangeValue = t('memberRegistry.inputInfiniteScrollLabelAvoidIngredients');
    //** API **//
    var customer = useGetCustomerApi(isFamilyMember).data;
    var person = useGetPersonApi(personId || (customer === null || customer === void 0 ? void 0 : customer.personId)).data;
    var profile = useFamilyMemberComposer().profile;
    var updateProfile = useUpdateProfileHelper({
        isFamilyMember: isNewFamilyMember,
        person: person,
        customer: customer,
        callFamilyUpdateApi: true,
        confirmAlert: confirmAlert,
    }).updateProfile;
    var family = useGetFamilyPlanStatusApi(person === null || person === void 0 ? void 0 : person.Family.id).data;
    var isMinorPerson = useMemo(function () { return isMinor(person === null || person === void 0 ? void 0 : person.birthDate); }, [person === null || person === void 0 ? void 0 : person.birthDate]);
    // EFFECTS
    useEffect(function () {
        var _a, _b, _c;
        var objectWithIngredients = undefined;
        var isPersonAndInitialized = person && !isNewFamilyMember;
        var isFamilyMemberAndInitialized = profile && isNewFamilyMember;
        if (isPersonAndInitialized) {
            objectWithIngredients = { ingredients: person.excludedIngredients };
        }
        if (isFamilyMemberAndInitialized) {
            objectWithIngredients = profile;
        }
        if (objectWithIngredients === undefined)
            return;
        var excludedIngredientsOptions = (_b = (_a = objectWithIngredients.ingredients) === null || _a === void 0 ? void 0 : _a.map(function (a) { return ({
            type: 'custom',
            label: a.Ingredient.name,
            value: a.Ingredient.id,
        }); })) !== null && _b !== void 0 ? _b : [];
        setSelectedOptions(excludedIngredientsOptions);
        setSelectedCommonAllergies((_c = (isNewFamilyMember ? profile.commonAllergies : person === null || person === void 0 ? void 0 : person.commonAllergies)) !== null && _c !== void 0 ? _c : undefined);
    }, [isNewFamilyMember, person, profile]);
    //** CALLBACK **//
    var onExpandableOptionPress = useCallback(function () {
        return onAddPress &&
            onAddPress({
                maxSelectableItems: maxSelectableItemsValue,
                selectedOptions: selectedOptions,
                titleChange: titleChangeValue,
                onConfirmSelection: setSelectedOptions,
            });
    }, [onAddPress, selectedOptions, titleChangeValue]);
    var avoidIngredientsValue = useMemo(function () {
        return selectedOptions.map(function (ai) { return ({
            Ingredient: {
                id: ai.value,
                name: ai.label,
            },
        }); });
    }, [selectedOptions]);
    var onUpdateAvoidIngredients = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateProfile({ ingredients: avoidIngredientsValue })];
                case 1:
                    _a.sent();
                    onNext === null || onNext === void 0 ? void 0 : onNext();
                    return [2 /*return*/];
            }
        });
    }); }, [updateProfile, avoidIngredientsValue, onNext]);
    var onPressParentalResponsibility = useCallback(function () { return setIsParentalResponsibilitySelected(function (current) { return !current; }); }, []);
    var isPlanCreated = (family === null || family === void 0 ? void 0 : family.status) !== PlanStatus.PLAN_PENDING;
    return {
        selectedOptions: selectedOptions,
        setSelectedOptions: setSelectedOptions,
        onExpandableOptionPress: onExpandableOptionPress,
        onUpdateAvoidIngredients: onUpdateAvoidIngredients,
        isFamilyMember: isFamilyMember,
        selectedCommonAllergies: selectedCommonAllergies,
        isParentalResponsibilitySelected: isParentalResponsibilitySelected,
        onPressParentalResponsibility: onPressParentalResponsibility,
        isMinor: isMinorPerson || isMinorCreation,
        isPlanCreated: isPlanCreated,
    };
};
