var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getLabelsConjugationCondition } from '@planter/common/src/utils/labelsConjugationCondition';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetCustomerApi, useGetPersonApi } from '../../hooks';
import { useGetActivityApi } from '../../hooks';
import { useFamilyMemberComposer } from './useFamilyMemberComposer.facade';
import { useSelector } from 'react-redux';
import { isFamilyMemberRegisterRequested } from '../../redux';
import { useUpdateProfileHelper } from './helpers/useUpdateProfileHelper';
import { useLanguage } from '../../locale';
export var useInputPhysicalActivityFormFacade = function (_a) {
    var onNext = _a.onNext, personId = _a.personId, _b = _a.isFamilyMemberUpdate, isFamilyMemberUpdate = _b === void 0 ? false : _b, confirmAlert = _a.confirmAlert;
    var t = useLanguage()[0];
    //** REDUX **//
    var isNewFamilyMember = useSelector(isFamilyMemberRegisterRequested);
    var isFamilyMember = isNewFamilyMember || isFamilyMemberUpdate;
    var _c = useState([]), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    //** API **//
    var customer = useGetCustomerApi(!isFamilyMember).data;
    var person = useGetPersonApi(personId || (customer === null || customer === void 0 ? void 0 : customer.personId)).data;
    var activities = useGetActivityApi().data;
    var profile = useFamilyMemberComposer().profile;
    var updateProfile = useUpdateProfileHelper({
        isFamilyMember: isNewFamilyMember,
        person: person,
        customer: customer,
        confirmAlert: confirmAlert,
    }).updateProfile;
    var labelsConjugationCondition = getLabelsConjugationCondition(person, isFamilyMember, customer);
    //** DATA **//
    var optionMemberFamily = useMemo(function () {
        return [
            {
                type: 'default',
                label: t('memberRegistry.inputFamilyPhysicalActivityFirstOptionLabel'),
                value: 'cl7uk2y2m00003b6ljxmhmmfr',
            },
            {
                type: 'default',
                label: t('memberRegistry.inputFamilyPhysicalActivitySecondOptionLabel'),
                value: 'cl7yy4lxa00023b6l1gzgp5m5',
            },
            {
                type: 'default',
                label: t('memberRegistry.inputFamilyPhysicalActivityThirdOptionLabel'),
                value: 'cl7yy4lxa00043b6l3919w3v7',
            },
            {
                type: 'default',
                label: t('memberRegistry.inputFamilyPhysicalActivityFourthOptionLabel'),
                value: 'cl7yy4lxa00063b6l06w5b937',
            },
        ];
    }, [t]);
    var option = useMemo(function () {
        if (!activities) {
            return [];
        }
        var memberFamily = optionMemberFamily.map(function (item) { return ({
            type: 'default',
            label: item.label,
            value: item.value,
        }); });
        var leaderFamily = activities.map(function (item) { return ({
            type: 'default',
            label: item.name,
            value: item.id,
        }); });
        return isFamilyMember ? memberFamily : leaderFamily;
    }, [activities, isFamilyMember, optionMemberFamily]);
    var subTitle = useMemo(function () {
        return labelsConjugationCondition
            ? t('memberRegistry.inputPhysicalActivitySubtitle')
            : t('memberRegistry.inputFamilyPhysicalActivitySubtitle');
    }, [labelsConjugationCondition, t]);
    //** CALLBACK **//
    var onSelectedOptionsChange = useCallback(function (options) {
        if (errorMessage) {
            setErrorMessage(undefined);
        }
        setSelectedOptions(options);
    }, [errorMessage]);
    var selectedActivity = selectedOptions
        .map(function (activity) { return activity.value; })
        .toString();
    useEffect(function () {
        if (option.length > 0 && person) {
            var selectedActivity_1 = option.filter(function (o) {
                return o.value ===
                    (isNewFamilyMember ? profile.activity : person.activitiesId);
            });
            setSelectedOptions(selectedActivity_1);
        }
    }, [isNewFamilyMember, option, option.length, person, profile.activity]);
    var onUpdatePhysicalActivity = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateProfile({ activity: selectedActivity })];
                case 1:
                    _a.sent();
                    onNext === null || onNext === void 0 ? void 0 : onNext();
                    return [2 /*return*/];
            }
        });
    }); }, [updateProfile, selectedActivity, onNext]);
    return {
        selectedOptions: selectedOptions,
        setSelectedOptions: onSelectedOptionsChange,
        option: option,
        subTitle: subTitle,
        errorMessage: errorMessage,
        confirmDisabled: !selectedOptions.length,
        onUpdatePhysicalActivity: onUpdatePhysicalActivity,
        isFamilyMember: isFamilyMember,
    };
};
