var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguage } from '@planter/common';
import * as React from 'react';
import PlDescription from '../../../components/PlDescription';
var SwitchToDoses = function (_a) {
    var checked = _a.checked, onDosesChange = _a.onDosesChange;
    var t = useLanguage()[0];
    return (_jsxs("div", __assign({ className: "flex justify-between items-center py-4" }, { children: [_jsx(PlDescription, { label: t('recipeDetail.showDosesMembers') }), _jsxs("label", __assign({ className: "relative inline-flex items-center cursor-pointer" }, { children: [_jsx("input", { type: "checkbox", checked: checked, onChange: onDosesChange, className: "hidden peer" }), _jsx("div", { className: "w-14 h-8 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-grey after:border after:rounded-full after:h-7 after:w-7 after:transition-all bg-grey peer-checked:bg-orange-medium" })] }))] })));
};
export default SwitchToDoses;
