var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationPosition, NotificationType, } from '@planter/common';
import CheckCircleIcon from './icons/CheckCircleIcon';
import colors from '../styles/colors.module.scss';
import ExclamationMarkIcon from './icons/ExclamationMarkIcon';
export var PlNotificationBadge = memo(function (_a) {
    var text = _a.text, _b = _a.position, position = _b === void 0 ? NotificationPosition.bottom : _b, className = _a.className, _c = _a.disappearTimerSeconds, disappearTimerSeconds = _c === void 0 ? 3000 : _c, _d = _a.type, type = _d === void 0 ? NotificationType.success : _d, rightIcon = _a.rightIcon, _e = _a.onRightIconClick, onRightIconClick = _e === void 0 ? function () { return setVisualizeMessage(false); } : _e;
    var _f = useState(true), visualizeMessage = _f[0], setVisualizeMessage = _f[1];
    var badgeDivRef = useRef(null);
    var _g = useState(0), divOffset = _g[0], setDivOffset = _g[1];
    useEffect(function () {
        var _a, _b;
        if ((_a = badgeDivRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth)
            setDivOffset(((_b = badgeDivRef.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) / 2);
    }, []);
    setTimeout(function () { return setVisualizeMessage(false); }, disappearTimerSeconds);
    var positionClassName = useMemo(function () {
        switch (position) {
            case NotificationPosition.up:
                return 'top-20';
            case NotificationPosition.bottom:
                return 'bottom-5';
            default:
                return 'bottom-5';
        }
    }, [position]);
    var backgroundColor = useMemo(function () {
        switch (type) {
            case NotificationType.success:
                return 'bg-green-medium';
            case NotificationType.warning:
                return 'bg-orange-dark';
            case NotificationType.error:
                return 'bg-red';
            default:
                return '';
        }
    }, [type]);
    var icon = useMemo(function () {
        switch (type) {
            case NotificationType.success:
                return _jsx(CheckCircleIcon, { size: "4", color: colors['white'] });
            case NotificationType.warning:
                return _jsx(ExclamationMarkIcon, { color: colors['white'] });
            case NotificationType.error:
                return _jsx(ExclamationMarkIcon, { color: colors['white'] });
            default:
                return '';
        }
    }, [type]);
    return (_jsx(_Fragment, { children: visualizeMessage ? (_jsx("div", __assign({ className: "fixed ".concat(positionClassName, " ").concat(className, " z-[99999]"), style: {
                right: "calc(50% - ".concat(divOffset, "px)"),
            }, ref: badgeDivRef }, { children: _jsxs("div", __assign({ className: "p-5 rounded-[20px] flex items-center \n            ".concat(backgroundColor, " ") }, { children: [icon, _jsx("p", __assign({ className: "text-white pl-3 ".concat(rightIcon && 'pr-3') }, { children: text })), rightIcon && (_jsx("button", __assign({ onClick: onRightIconClick, className: "p-2 rounded-full" }, { children: rightIcon })))] })) }))) : (_jsx(_Fragment, {})) }));
});
