var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
var CircleCloseIcon = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 23 : _b, width = _a.width, height = _a.height;
    return (_jsxs("svg", __assign({ width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, viewBox: "0 0 23 23", fill: "black", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: [_jsx("path", { d: "m13.41 12 4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" }), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "a" }, { children: _jsx("path", { fill: "#fff", d: "M0 0h28.718v25.846H0z" }) })) })] })));
};
export default CircleCloseIcon;
