var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QonversionError } from '@qonversion/web-sdk';
export var InAppPurchaseErrorType;
(function (InAppPurchaseErrorType) {
    InAppPurchaseErrorType[InAppPurchaseErrorType["userCanceled"] = 0] = "userCanceled";
    InAppPurchaseErrorType[InAppPurchaseErrorType["generic"] = 1] = "generic";
    InAppPurchaseErrorType[InAppPurchaseErrorType["notFound"] = 2] = "notFound";
})(InAppPurchaseErrorType || (InAppPurchaseErrorType = {}));
var InAppPurchaseError = /** @class */ (function (_super) {
    __extends(InAppPurchaseError, _super);
    function InAppPurchaseError(type, error) {
        if (type === void 0) { type = InAppPurchaseErrorType.generic; }
        var _this = _super.call(this) || this;
        if (error instanceof QonversionError) {
            _this.message = error.message;
        }
        _this.type = type;
        return _this;
    }
    return InAppPurchaseError;
}(Error));
export { InAppPurchaseError };
