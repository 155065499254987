var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguage } from '@planter/common';
import * as React from 'react';
import AddIcon from '../../../components/icons/AddIcon';
import SubtractIcon from '../../../components/icons/SubtractIcon';
import PlTitle, { TitleSizeType } from '../../../components/PlTitle';
var PeopleNumber = React.memo(function (_a) {
    var className = _a.className, peopleNumber = _a.peopleNumber, _b = _a.editable, editable = _b === void 0 ? false : _b, onClickAdd = _a.onClickAdd, onClickSubtract = _a.onClickSubtract;
    var editBtnClassName = 'rounded-full border-[1px] border-black p-1 h-[22px] flex justify-center items-center';
    var t = useLanguage()[0];
    return (_jsxs("div", __assign({ className: "".concat(className) }, { children: [_jsx(PlTitle, { label: t('recipeDetail.recipeFor'), size: TitleSizeType.Regular }), _jsxs("div", __assign({ className: "flex items-center justify-between flex-wrap mt-4 p-3 rounded-2xl bg-grey-extra-light" }, { children: [_jsx("p", __assign({ className: "font-bold\ttext-sm" }, { children: t('recipeDetail.personNumber') })), _jsxs("div", __assign({ className: "flex" }, { children: [editable && (_jsx("button", __assign({ onClick: onClickSubtract }, { children: _jsx("div", __assign({ className: "".concat(editBtnClassName) }, { children: _jsx(SubtractIcon, {}) })) }))), _jsx("div", __assign({ className: "mx-4" }, { children: peopleNumber })), editable && (_jsx("button", __assign({ onClick: onClickAdd }, { children: _jsx("div", __assign({ className: "".concat(editBtnClassName) }, { children: _jsx(AddIcon, {}) })) })))] }))] }))] })));
});
export default PeopleNumber;
