var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { translatePlanterError } from '../../utils/general.utils';
import { isNil } from 'lodash';
var PlanterHttpClient = /** @class */ (function () {
    function PlanterHttpClient(_a) {
        var baseUrl = _a.baseUrl, axiosInstance = _a.axiosInstance, errorHandler = _a.errorHandler, accessToken = _a.accessToken, refreshToken = _a.refreshToken, locale = _a.locale, t = _a.t;
        var _this = this;
        this.get = function (url, configs) {
            if (configs === void 0) { configs = { isAuthorized: true }; }
            return __awaiter(_this, void 0, Promise, function () {
                var preparedHeaders, isAuthorized, options, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.prepareRequest(configs)];
                        case 1:
                            preparedHeaders = (_a.sent()).headers;
                            isAuthorized = configs.isAuthorized, options = __rest(configs, ["isAuthorized"]);
                            return [4 /*yield*/, this.axiosInstance.get(url, __assign(__assign({}, options), { headers: __assign(__assign({}, configs === null || configs === void 0 ? void 0 : configs.headers), preparedHeaders) }))];
                        case 2:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        };
        this.post = function (url, configs) {
            if (configs === void 0) { configs = { isAuthorized: true }; }
            return __awaiter(_this, void 0, Promise, function () {
                var preparedHeaders, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.prepareRequest(configs)];
                        case 1:
                            preparedHeaders = (_a.sent()).headers;
                            return [4 /*yield*/, this.axiosInstance.post(url, configs === null || configs === void 0 ? void 0 : configs.body, {
                                    headers: __assign(__assign({}, configs === null || configs === void 0 ? void 0 : configs.headers), preparedHeaders),
                                })];
                        case 2:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        };
        this.put = function (url, configs) {
            if (configs === void 0) { configs = { isAuthorized: true }; }
            return __awaiter(_this, void 0, Promise, function () {
                var preparedHeaders, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.prepareRequest(configs)];
                        case 1:
                            preparedHeaders = (_a.sent()).headers;
                            return [4 /*yield*/, this.axiosInstance.put(url, configs === null || configs === void 0 ? void 0 : configs.body, {
                                    headers: __assign(__assign({}, configs === null || configs === void 0 ? void 0 : configs.headers), preparedHeaders),
                                })];
                        case 2:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        };
        this.patch = function (url, configs) {
            if (configs === void 0) { configs = { isAuthorized: true }; }
            return __awaiter(_this, void 0, Promise, function () {
                var preparedHeaders, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.prepareRequest(configs)];
                        case 1:
                            preparedHeaders = (_a.sent()).headers;
                            return [4 /*yield*/, this.axiosInstance.patch(url, configs === null || configs === void 0 ? void 0 : configs.body, {
                                    headers: __assign(__assign({}, configs === null || configs === void 0 ? void 0 : configs.headers), preparedHeaders),
                                })];
                        case 2:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        };
        this.delete = function (url, configs) {
            if (configs === void 0) { configs = { isAuthorized: true }; }
            return __awaiter(_this, void 0, Promise, function () {
                var preparedHeaders, isAuthorized, options, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.prepareRequest(configs)];
                        case 1:
                            preparedHeaders = (_a.sent()).headers;
                            isAuthorized = configs.isAuthorized, options = __rest(configs, ["isAuthorized"]);
                            return [4 /*yield*/, this.axiosInstance.delete(url, __assign(__assign({}, options), { headers: __assign(__assign({}, configs === null || configs === void 0 ? void 0 : configs.headers), preparedHeaders) }))];
                        case 2:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        };
        this.setupAuth = function (accessToken, refreshToken) {
            if (accessToken) {
                _this.accessToken = accessToken;
            }
            if (refreshToken) {
                _this.refreshToken = refreshToken;
            }
        };
        this.removeAuth = function () {
            _this.accessToken = undefined;
            _this.refreshToken = undefined;
        };
        this.setupRefreshDelegate = function (refreshAuthDelegate) {
            _this.refreshAuthDelegate = refreshAuthDelegate;
        };
        this.setupErrorHandler = function (errorHandler) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.axiosInstance.interceptors.response.use(function (response) {
                    _this.retry = true;
                    return response;
                }, function (e) { return __awaiter(_this, void 0, void 0, function () {
                    var shouldCallAfterRefresh;
                    var _a, _b, _c, _d;
                    return __generator(this, function (_e) {
                        switch (_e.label) {
                            case 0:
                                (_a = errorHandler.sendLog) === null || _a === void 0 ? void 0 : _a.call(errorHandler, {
                                    url: (_b = e.config) === null || _b === void 0 ? void 0 : _b.url,
                                    data: JSON.stringify((_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data),
                                    arguments: (_d = e === null || e === void 0 ? void 0 : e.config) === null || _d === void 0 ? void 0 : _d.data,
                                });
                                return [4 /*yield*/, this.handlePrepareRefreshToken(e, errorHandler)];
                            case 1:
                                shouldCallAfterRefresh = _e.sent();
                                if (!shouldCallAfterRefresh) {
                                    throw e === null || e === void 0 ? void 0 : e.response;
                                }
                                this.retry = false;
                                return [2 /*return*/, this.runCallAfterRefresh(e)];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.prepareRequest = function (params) { return __awaiter(_this, void 0, Promise, function () {
            var tokenInfo, expirationDate, isExpired, res, _a, RefreshToken, IdToken, error_1;
            var _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        if (!params.isAuthorized) {
                            return [2 /*return*/, {}];
                        }
                        if (!this.accessToken) {
                            (_c = (_b = this.errorHandler).sendUnauthorized) === null || _c === void 0 ? void 0 : _c.call(_b);
                            return [2 /*return*/, {}];
                        }
                        tokenInfo = jwtDecode(this.accessToken);
                        expirationDate = dayjs(tokenInfo.exp * 1000);
                        isExpired = expirationDate.isBefore(dayjs());
                        if (!isExpired) {
                            return [2 /*return*/, this.populateHeaderWithToken()];
                        }
                        if (!this.refreshAuthDelegate || !this.refreshToken) {
                            (_e = (_d = this.errorHandler).sendUnauthorized) === null || _e === void 0 ? void 0 : _e.call(_d);
                            return [2 /*return*/, {}];
                        }
                        _h.label = 1;
                    case 1:
                        _h.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.refreshAuthDelegate.refresh(this.refreshToken)];
                    case 2:
                        res = _h.sent();
                        _a = res.AuthenticationResult, RefreshToken = _a.RefreshToken, IdToken = _a.IdToken;
                        this.accessToken = IdToken;
                        this.refreshToken = RefreshToken;
                        return [2 /*return*/, this.populateHeaderWithToken()];
                    case 3:
                        error_1 = _h.sent();
                        (_g = (_f = this.errorHandler).sendUnauthorized) === null || _g === void 0 ? void 0 : _g.call(_f);
                        return [2 /*return*/, {}];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.runCallAfterRefresh = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch ((_a = e.config) === null || _a === void 0 ? void 0 : _a.method) {
                    case 'get':
                        return [2 /*return*/, this.get(e.request.responseURL, {
                                isAuthorized: true,
                                headers: e.config.headers,
                                params: e.config.params,
                            })];
                    case 'post':
                        return [2 /*return*/, this.post(e.request.responseURL, {
                                isAuthorized: true,
                                headers: e.config.headers,
                                params: e.config.params,
                                body: e.config.data,
                            })];
                    case 'put':
                        return [2 /*return*/, this.put(e.request.responseURL, {
                                isAuthorized: true,
                                headers: e.config.headers,
                                params: e.config.params,
                                body: e.config.data,
                            })];
                    case 'patch':
                        return [2 /*return*/, this.patch(e.request.responseURL, {
                                isAuthorized: true,
                                headers: e.config.headers,
                                params: e.config.params,
                                body: e.config.data,
                            })];
                    case 'delete':
                        return [2 /*return*/, this.delete(e.request.responseURL, {
                                isAuthorized: true,
                                headers: e.config.headers,
                                params: e.config.params,
                            })];
                }
                return [2 /*return*/];
            });
        }); };
        this.handlePrepareRefreshToken = function (e, errorHandler) { return __awaiter(_this, void 0, void 0, function () {
            var errorCode, errorTranslation, res, _a, RefreshToken, IdToken, error_2;
            var _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        errorCode = 
                        // @ts-ignore
                        ((_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.status) === 401 ? 401 : (_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errorCode;
                        if (isNil(errorCode)) {
                            // errorHandler.nonBlockingInteractionError(
                            //   this.t('notificationAlert.genericError'),
                            // );
                            return [2 /*return*/, false];
                        }
                        if (errorCode !== 401 ||
                            !this.refreshToken ||
                            !this.refreshAuthDelegate ||
                            !((_e = e.config) === null || _e === void 0 ? void 0 : _e.method)) {
                            errorTranslation = translatePlanterError(this.t, errorCode);
                            if (errorTranslation === this.t('notificationAlert.genericError')) {
                                return [2 /*return*/, false];
                            }
                            errorHandler.nonBlockingInteractionError(errorTranslation);
                            return [2 /*return*/, false];
                        }
                        if (!this.retry) {
                            (_f = errorHandler.sendUnauthorized) === null || _f === void 0 ? void 0 : _f.call(errorHandler);
                            return [2 /*return*/, false];
                        }
                        _h.label = 1;
                    case 1:
                        _h.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.refreshAuthDelegate.refresh(this.refreshToken)];
                    case 2:
                        res = _h.sent();
                        _a = res.AuthenticationResult, RefreshToken = _a.RefreshToken, IdToken = _a.IdToken;
                        this.accessToken = IdToken;
                        if (RefreshToken) {
                            this.refreshToken = RefreshToken;
                        }
                        return [2 /*return*/, true];
                    case 3:
                        error_2 = _h.sent();
                        (_g = errorHandler.sendUnauthorized) === null || _g === void 0 ? void 0 : _g.call(errorHandler);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.t = t;
        this.errorHandler = errorHandler;
        this.axiosInstance = axiosInstance !== null && axiosInstance !== void 0 ? axiosInstance : axios.create({});
        this.axiosInstance.defaults.baseURL = baseUrl;
        this.axiosInstance.defaults.timeout = 30000;
        this.axiosInstance.defaults.headers.common = {
            'Content-Type': 'application/json',
            lang: locale,
        };
        this.retry = true;
        this.setupErrorHandler(errorHandler);
        if (accessToken && refreshToken)
            this.setupAuth(accessToken, refreshToken);
    }
    PlanterHttpClient.prototype.populateHeaderWithToken = function () {
        return {
            headers: {
                Authorization: "Bearer ".concat(this.accessToken),
            },
        };
    };
    return PlanterHttpClient;
}());
export { PlanterHttpClient };
