var WebStorage = /** @class */ (function () {
    function WebStorage() {
        this.getItem = function (key) {
            return Promise.resolve(localStorage.getItem(key));
        };
        this.setItem = function (key, val) {
            return Promise.resolve(localStorage.setItem(key, val));
        };
        this.removeItem = function (key) {
            return Promise.resolve(localStorage.removeItem(key));
        };
    }
    return WebStorage;
}());
export { WebStorage };
