var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CommonAllergy, useLanguage, } from '../../../../../common/index';
import { useGetIngredientsApi } from '../../../hooks/api/ingredients/useGetIngredients.api';
export var useInfiniteScrollListFacade = function (_a) {
    var navigateBack = _a.navigateBack, selectedOptions = _a.selectedOptions, _b = _a.maxSelectableItems, maxSelectableItems = _b === void 0 ? 5 : _b, onConfirmSelection = _a.onConfirmSelection, selectedCommonAllergies = _a.selectedCommonAllergies;
    var t = useLanguage()[0];
    var _c = useState(), searchText = _c[0], setSearchText = _c[1];
    var _d = useState(), hasGluten = _d[0], setHasGluten = _d[1];
    var _e = useState(), hasSoy = _e[0], setHasSoy = _e[1];
    var _f = useState(), hasDriedFruit = _f[0], setHasDriedFruit = _f[1];
    var _g = useState(selectedOptions), selectedItems = _g[0], setSelectedItems = _g[1];
    var _h = useGetIngredientsApi({
        search: searchText,
        hasGluten: hasGluten,
        hasSoy: hasSoy,
        hasDriedFruit: hasDriedFruit,
    }), dataItems = _h.dataItems, dataPagination = _h.dataPagination, isFetching = _h.isFetching, fetchNextPage = _h.fetchNextPage;
    var setCommonAllergyValue = useCallback(function (allergy) {
        return Boolean(!selectedCommonAllergies
            ? selectedOptions.find(function (option) { return option.value === allergy; })
            : selectedCommonAllergies === null || selectedCommonAllergies === void 0 ? void 0 : selectedCommonAllergies.find(function (a) { return a === allergy; }));
    }, [selectedCommonAllergies, selectedOptions]);
    useEffect(function () {
        setSelectedItems(selectedOptions);
        setHasGluten(setCommonAllergyValue(CommonAllergy.Gluten));
        setHasSoy(setCommonAllergyValue(CommonAllergy.Soy));
        setHasDriedFruit(setCommonAllergyValue(CommonAllergy.DriedFruit));
    }, [selectedOptions, selectedCommonAllergies, setCommonAllergyValue]);
    //** DATA **//
    var counterTitle = useMemo(function () {
        return t('infiniteScrollList.counterTitle', {
            x: selectedItems.length,
            y: maxSelectableItems,
        });
    }, [maxSelectableItems, selectedItems.length, t]);
    // ** CALLBACKS ** //
    var onResetSelectionPress = useCallback(function () { return setSelectedItems([]); }, []);
    var onResetValueSearchBar = useCallback(function () { return setSearchText(''); }, []);
    var onConfirmPress = useCallback(function () {
        navigateBack();
        onConfirmSelection(selectedItems);
    }, [navigateBack, onConfirmSelection, selectedItems]);
    var onItemPress = useCallback(function (item) {
        return setSelectedItems(function (current) {
            var selectedItemsWithoutPressed = current.filter(function (selectedItem) { return selectedItem.label !== item.label; });
            if (selectedItemsWithoutPressed.length !== current.length) {
                return selectedItemsWithoutPressed;
            }
            if (current.length === maxSelectableItems) {
                return current;
            }
            return __spreadArray(__spreadArray([], current, true), [item], false);
        });
    }, [maxSelectableItems]);
    var onEndReached = useCallback(function () {
        return fetchNextPage();
    }, [fetchNextPage]);
    return {
        searchText: searchText,
        setSearchText: setSearchText,
        selectedItems: selectedItems,
        counterTitle: counterTitle,
        dataItems: dataItems,
        dataPagination: dataPagination,
        isFetching: isFetching,
        onResetSelectionPress: onResetSelectionPress,
        onItemPress: onItemPress,
        onConfirmPress: onConfirmPress,
        onEndReached: onEndReached,
        onResetValueSearchBar: onResetValueSearchBar,
    };
};
