var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FamilyRole, useLanguage, } from '@planter/common';
import * as React from 'react';
import PlDescription from '../../../components/PlDescription';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/it';
import PlUserIcon from '../../../components/PlUserIcon';
var PlanRecipeInformation = React.memo(function (_a) {
    var personsPlanInformation = _a.personsPlanInformation, mealType = _a.mealType, _b = _a.date, date = _b === void 0 ? '' : _b;
    var t = useLanguage()[0];
    var familyMembers = personsPlanInformation.filter(function (person) { return person.familyRole !== FamilyRole.FAMILY_GUEST; });
    var guests = personsPlanInformation.filter(function (person) { return person.familyRole === FamilyRole.FAMILY_GUEST; });
    return (_jsxs("div", __assign({ className: "pt-8" }, { children: [_jsx(PlDescription, { label: t('recipeDetail.planInformationSectionTitle'), className: "text-base font-medium" }), _jsx("div", __assign({ className: "rounded-2xl bg-grey-extra-light mt-2" }, { children: _jsxs("div", __assign({ className: "grid grid-cols-3 px-4 py-5 gap-3" }, { children: [_jsxs("div", __assign({ className: "col-span-1 relative border-r-[0.5px] border-grey-medium" }, { children: [_jsx(PlDescription, { label: t('recipeDetail.people'), className: "text-xs text-grey" }), familyMembers === null || familyMembers === void 0 ? void 0 : familyMembers.map(function (person, i) { return (_jsx("div", __assign({ className: "absolute rounded-full", style: {
                                        left: i > 0 ? 12 * i : 0,
                                    } }, { children: _jsx(PlUserIcon, { personId: person.id, hasPrefix: true, marginTop: '1px' }) }), person.id)); }), guests && guests.length > 0 && (_jsxs("span", __assign({ style: {
                                        position: 'absolute',
                                        left: 12 * (familyMembers.length + 1),
                                    } }, { children: ["+", guests.length, ' '] })))] })), _jsxs("div", __assign({ className: "col-span-1 border-r-[0.5px] border-grey-medium" }, { children: [_jsx(PlDescription, { label: t('recipeDetail.date'), className: "text-xs text-grey" }), _jsx(PlDescription, { label: dayjs(date, 'YYYY/MM/DD')
                                        .locale(locale)
                                        .format('DD MMMM YYYY'), className: "text-sm font-medium" })] })), _jsxs("div", __assign({ className: "col-span-1" }, { children: [_jsx(PlDescription, { label: t('recipeDetail.meal'), className: "text-xs text-grey" }), _jsx(PlDescription, { label: t("planPage.sectionTitles.".concat(mealType)).slice(0, -1), className: "text-sm font-medium" })] }))] })) }))] })));
});
export default PlanRecipeInformation;
