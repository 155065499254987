var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var PacifierIcon = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? '#1C1C1E' : _b, height = _a.height, width = _a.width, _c = _a.size, size = _c === void 0 ? 20 : _c;
    return (_jsx("svg", __assign({ viewBox: "0 0 20 20", width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: _jsx("path", { d: "M13.525 8.041a4.168 4.168 0 0 0 .508-3.258 4.109 4.109 0 0 0-3.041-3A4.167 4.167 0 0 0 6.475 8.04c-1.917.642-3.142 1.809-3.142 3.209 0 1.4 1.309 2.65 3.334 3.275V15a3.333 3.333 0 0 0 6.666 0v-.475c2.025-.625 3.334-1.834 3.334-3.275 0-1.442-1.234-2.567-3.142-3.209ZM8.45 3.875A2.45 2.45 0 0 1 10 3.333c.21 0 .42.025.625.075a2.5 2.5 0 0 1 1.792 1.775 2.5 2.5 0 0 1-.684 2.442c-.146.143-.308.27-.483.375a.834.834 0 0 0-.417.716V10H9.167V8.716A.833.833 0 0 0 8.75 8a2.734 2.734 0 0 1-.492-.375 2.5 2.5 0 0 1-.507-2.88 2.5 2.5 0 0 1 .699-.87ZM11.667 15a1.667 1.667 0 0 1-3.334 0v-.117c1.106.155 2.228.155 3.334 0V15ZM10 13.333c-3.1 0-5-1.217-5-2.083 0-.6.925-1.359 2.5-1.767V10a1.667 1.667 0 0 0 1.667 1.666h1.666A1.667 1.667 0 0 0 12.5 10v-.517c1.575.408 2.5 1.167 2.5 1.767 0 .866-1.9 2.083-5 2.083Z", fill: color }) })));
};
export default PacifierIcon;
