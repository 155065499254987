export var LanguagesOptions;
(function (LanguagesOptions) {
    LanguagesOptions["EnglishUK"] = "EN";
    LanguagesOptions["EnglishUS"] = "US";
    LanguagesOptions["Italian"] = "IT";
})(LanguagesOptions || (LanguagesOptions = {}));
export var UnitOfMeasureOptions;
(function (UnitOfMeasureOptions) {
    UnitOfMeasureOptions["metric"] = "METRIC";
    UnitOfMeasureOptions["imperial"] = "IMPERIAL";
})(UnitOfMeasureOptions || (UnitOfMeasureOptions = {}));
