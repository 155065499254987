var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from 'react';
import { NotImplementedAuthRepository, createRepositories, NotImplementedCountriesRepository, NotImplementedPlanRepository, NotImplementedShoppingListRepository, NotImplementedNotificationsRepository, NotImplementedInAppPurchaseRepository, NotImplementedRedeemCouponRepository, } from '../../repositories';
import { useStorage } from '../StorageContext/useStorage';
import { useErrorHandler } from '../ErrorHandlerContext/useErrorHandler';
import { NotImplementedUserRepository, } from '../../repositories';
import { NotImplementedCustomerRepository, } from '../../repositories/customer/ICustomerRepository';
import { NotImplementedCodeRepository, } from '../../repositories/code/ICodeRepository';
import { NotImplementedIngredientRepository, } from '../../repositories/ingredients/IIngredientsRepository';
import { NotImplementedActivityRepository, } from '../../repositories/activity/IActivityRepository';
import { NotImplementedRecipeRepository, } from '../../repositories';
import { useLanguage } from '../../locale';
import { NotImplementedFamilyRepository, } from '../../repositories';
import { NotImplementedFavouriteRepository, } from '../../repositories';
import { NotImplementedFiltersRepository } from '../../repositories/filters/IFiltersRepository';
import { NotImplementedCalorieRangeRepository, } from '../../repositories/calorieRange/ICalorieRangeRepository';
export var RepositoriesContext = createContext({
    authRepo: new NotImplementedAuthRepository(),
    userRepo: new NotImplementedUserRepository(),
    countriesRepo: new NotImplementedCountriesRepository(),
    customerRepo: new NotImplementedCustomerRepository(),
    codeRepo: new NotImplementedCodeRepository(),
    ingredientRepo: new NotImplementedIngredientRepository(),
    activityRepo: new NotImplementedActivityRepository(),
    recipeRepo: new NotImplementedRecipeRepository(),
    familyRepo: new NotImplementedFamilyRepository(),
    favouriteRepo: new NotImplementedFavouriteRepository(),
    planRepo: new NotImplementedPlanRepository(),
    filtersRepo: new NotImplementedFiltersRepository(),
    calorieRangeRepo: new NotImplementedCalorieRangeRepository(),
    shoppingListRepo: new NotImplementedShoppingListRepository(),
    notificationsRepo: new NotImplementedNotificationsRepository(),
    inAppPurchaseRepo: new NotImplementedInAppPurchaseRepository(),
    redeemCouponRepo: new NotImplementedRedeemCouponRepository(),
});
export var RepositoriesProvider = function (_a) {
    var children = _a.children, baseUrl = _a.baseUrl, cognitoRegion = _a.cognitoRegion, userPoolWebClientId = _a.userPoolWebClientId, repositoriesInstances = _a.repositoriesInstances;
    var locale = useLanguage()[2];
    var storage = useStorage();
    var errorHandler = useErrorHandler();
    var t = useLanguage()[0];
    var _b = useState(), repositories = _b[0], setRepositories = _b[1];
    useEffect(function () {
        if (!repositories && !repositoriesInstances) {
            createRepositories({
                storage: storage,
                errorHandler: errorHandler,
                baseUrl: baseUrl,
                cognitoRegion: cognitoRegion,
                userPoolWebClientId: userPoolWebClientId,
                locale: locale,
                t: t,
            }).then(function (repos) {
                setRepositories(repos);
            });
        }
        if (repositoriesInstances) {
            setRepositories(repositoriesInstances);
        }
    }, [
        baseUrl,
        cognitoRegion,
        errorHandler,
        locale,
        repositories,
        repositoriesInstances,
        storage,
        t,
        userPoolWebClientId,
    ]);
    return (_jsx(_Fragment, { children: repositories ? (_jsx(RepositoriesContext.Provider, __assign({ value: repositories }, { children: children }))) : null }));
};
