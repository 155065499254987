import { useSearchFilters } from './useSearchFilters';
import { useLocalCategoriesSelection } from './useLocalCategoriesSelection';
export var useFiltersCategoriesSectionFacade = function () {
    var _a = useSearchFilters(), selectedCategoriesFilter = _a.selectedCategoriesFilter, categoriesFilters = _a.categoriesFilters, setFilterCategories = _a.setFilterCategories;
    var results = useLocalCategoriesSelection({
        allCategories: categoriesFilters,
        selectedCategoriesFilter: selectedCategoriesFilter,
        setFilterCategories: setFilterCategories,
    });
    return results;
};
