var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var AskIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 20 : _b, width = _a.width, height = _a.height, _c = _a.color, color = _c === void 0 ? '#8E8E93' : _c;
    return (_jsx("svg", __assign({ viewBox: "0 0 20 20", width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M14.1667 9.16667V4.16667C14.1667 3.72464 13.9911 3.30071 13.6785 2.98815C13.3659 2.67559 12.942 2.5 12.5 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98815C2.67559 3.30071 2.5 3.72464 2.5 4.16667V13.3333C2.50082 13.4979 2.55032 13.6585 2.64227 13.7949C2.73421 13.9313 2.86448 14.0375 3.01667 14.1C3.11557 14.1467 3.22402 14.1695 3.33333 14.1667C3.44301 14.1673 3.55172 14.1463 3.65326 14.1048C3.75479 14.0633 3.84713 14.0022 3.925 13.925L7.00833 10.8333H12.5C12.942 10.8333 13.3659 10.6577 13.6785 10.3452C13.9911 10.0326 14.1667 9.60869 14.1667 9.16667ZM6.66667 9.16667C6.55699 9.16603 6.44828 9.18705 6.34674 9.22852C6.24521 9.26999 6.15287 9.33111 6.075 9.40834L4.16667 11.325V4.16667H12.5V9.16667H6.66667ZM17.5 5V16.6667C17.4992 16.8312 17.4497 16.9918 17.3577 17.1282C17.2658 17.2647 17.1355 17.3708 16.9833 17.4333C16.8844 17.48 16.776 17.5028 16.6667 17.5C16.557 17.5006 16.4483 17.4796 16.3467 17.4382C16.2452 17.3967 16.1529 17.3356 16.075 17.2583L12.9917 14.1667H8.33333C8.11232 14.1667 7.90036 14.0789 7.74408 13.9226C7.5878 13.7663 7.5 13.5543 7.5 13.3333C7.5 13.1123 7.5878 12.9004 7.74408 12.7441C7.90036 12.5878 8.11232 12.5 8.33333 12.5H13.3333C13.443 12.4994 13.5517 12.5204 13.6533 12.5618C13.7548 12.6033 13.8471 12.6644 13.925 12.7417L15.8333 14.6583V5C15.8333 4.77899 15.9211 4.56704 16.0774 4.41076C16.2337 4.25448 16.4457 4.16667 16.6667 4.16667C16.8877 4.16667 17.0996 4.25448 17.2559 4.41076C17.4122 4.56704 17.5 4.77899 17.5 5Z", fill: color }) })));
};
export default AskIcon;
