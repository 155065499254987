import * as React from 'react';
import { useLanguage } from '../../locale';
import { useSearchFilters } from './useSearchFilters';
export var useFavouritesFilterSectionFacade = function () {
    var t = useLanguage()[0];
    var _a = React.useState(), favouritesFilterOption = _a[0], setFavouritesFilterOption = _a[1];
    var _b = useSearchFilters(), setIsFavouritesToFilter = _b.setIsFavouritesToFilter, selectedIsFavouritesFilter = _b.selectedIsFavouritesFilter;
    var options = React.useMemo(function () {
        return [
            {
                type: 'default',
                label: "".concat(t('yes')),
                value: true,
            },
            {
                type: 'default',
                label: "".concat(t('no')),
                value: false,
            },
        ];
    }, [t]);
    React.useEffect(function () {
        if (selectedIsFavouritesFilter !== null) {
            var favouriteFilterOptionToSet = options.find(function (o) { return o.value === selectedIsFavouritesFilter; });
            setFavouritesFilterOption(favouriteFilterOptionToSet !== null && favouriteFilterOptionToSet !== void 0 ? favouriteFilterOptionToSet : null);
        }
    }, [options, selectedIsFavouritesFilter]);
    var reset = React.useCallback(function () {
        setFavouritesFilterOption(null);
    }, []);
    var applyFilter = React.useCallback(function () {
        var _a;
        setIsFavouritesToFilter((_a = favouritesFilterOption === null || favouritesFilterOption === void 0 ? void 0 : favouritesFilterOption.value) !== null && _a !== void 0 ? _a : null);
    }, [favouritesFilterOption === null || favouritesFilterOption === void 0 ? void 0 : favouritesFilterOption.value, setIsFavouritesToFilter]);
    return {
        options: options,
        favouritesFilterOption: favouritesFilterOption,
        setFavouritesFilterOption: setFavouritesFilterOption,
        reset: reset,
        applyFilter: applyFilter,
    };
};
