import { managedLanguages } from '../locale/localizationContext';
export var getPreferredLanguage = function (language) {
    if (managedLanguages.includes(language.toLowerCase())) {
        return language.toLowerCase();
    }
    var languageToSearch = language.includes('-')
        ? language.split('-')[0].toLowerCase()
        : undefined;
    return managedLanguages.find(function (el) { return el === languageToSearch; });
};
