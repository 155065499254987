var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FamilyRole, RecipeDetailsType, RecipeImageDevice, recipeTimeFormatter, useFavouriteFacade, useGetCustomerApi, useGetPersonApi, useLanguage, useNotificationsMessage, useRecipeDetailsFacade, } from '@planter/common';
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import FavoriteIcon, { IconFavoriteType, } from '../../components/icons/FavoriteIcon';
import GlutenFreeIcon from '../../components/icons/GlutenFreeIcon';
import TimerIcon from '../../components/icons/TimerIcon';
import PlLayout, { LayoutType } from '../../components/layouts/PlLayout';
import PlSinglePageLayoutBoxedSection from '../../components/layouts/PlSinglePageLayoutBoxedSection';
import PlButton, { ButtonType } from '../../components/PlButton';
import PlTabNavigation from '../../components/PlTabNavigation';
import PlText from '../../components/PlText';
import PlTitle, { TitleSizeType } from '../../components/PlTitle';
import { useBack } from '../../hooks/useBack';
import { getImageUrl } from '../../utils';
import AddRecipeToPlan from './components/AddRecipeToPlan';
import FoodGroups from './components/FoodGroups';
import IngredientsSection from './components/IngredientsSection';
import PeopleNumber from './components/PeopleNumber';
import PlanRecipeInformation from './components/PlanRecipeInformation';
import PreparationStepsSection from './components/PreparationStepsSection';
import RecipeDetailsHeader from './components/RecipeDetailsHeader';
var RecipeDetailsTab;
(function (RecipeDetailsTab) {
    RecipeDetailsTab["Ingredients"] = "ingredients";
    RecipeDetailsTab["Preparation"] = "preparation";
    RecipeDetailsTab["Notes"] = "note";
})(RecipeDetailsTab || (RecipeDetailsTab = {}));
export var RecipeDateParamKey = 'date';
export var MealTypeParamKey = 'mealType';
export var IsPlanSearchParamKey = 'isPlanSearch';
export var onBackCloseTabParamKey = 'onBackCloseTab';
var RecipePage = function (_a) {
    var _b, _c, _d;
    var type = _a.type;
    var t = useLanguage()[0];
    var goBack = useBack();
    var queryParams = useSearchParams()[0];
    var planDate = (_b = queryParams.get(RecipeDateParamKey)) !== null && _b !== void 0 ? _b : undefined;
    var planMealType = (_c = queryParams.get(MealTypeParamKey)) !== null && _c !== void 0 ? _c : undefined;
    var isFromPlanSearch = (_d = queryParams.get(IsPlanSearchParamKey)) !== null && _d !== void 0 ? _d : undefined;
    var recipeId = useParams().recipeId;
    var _e = useRecipeDetailsFacade({
        recipeId: recipeId,
        type: type,
        date: planDate,
        mealType: planMealType,
    }), recipeDetail = _e.recipeDetail, hasCereals = _e.hasCereals, hasFruit = _e.hasFruit, hasGoodFats = _e.hasGoodFats, hasProteinFood = _e.hasProteinFood, hasVegetables = _e.hasVegetables, personsPlanDetail = _e.personsPlanDetail;
    var customer = useGetCustomerApi().data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _f = React.useState(RecipeDetailsTab.Ingredients), selectedTab = _f[0], setSelectedTab = _f[1];
    var _g = React.useState(false), showDrawer = _g[0], setShowDrawer = _g[1];
    var showNotifications = useNotificationsMessage().showNotifications;
    var onBackCloseTab = queryParams.get(onBackCloseTabParamKey);
    var recipeDetailsTabs = React.useMemo(function () { return [
        {
            label: t('recipeDetail.ingredients'),
            value: RecipeDetailsTab.Ingredients,
        },
        {
            label: t('recipeDetail.preparation'),
            value: RecipeDetailsTab.Preparation,
        },
        {
            label: t('recipeDetail.notes'),
            value: RecipeDetailsTab.Notes,
        },
    ]; }, [t]);
    var _h = useFavouriteFacade(), favouritesIds = _h.favouritesIds, onPressFavourite = _h.onPressFavourite;
    var onFavouriteChange = React.useCallback(function (event) {
        event.stopPropagation();
        (recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.id) && onPressFavourite(recipeDetail.id);
    }, [onPressFavourite, recipeDetail]);
    var header = React.useMemo(function () {
        var _a, _b;
        var image = getImageUrl((_b = (_a = recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.image) === null || _a === void 0 ? void 0 : _a.find(function (el) { return el.device === RecipeImageDevice.DESKTOP; })) === null || _b === void 0 ? void 0 : _b.src);
        return (_jsx(RecipeDetailsHeader, { onBack: onBackCloseTab ? function () { return window.close(); } : goBack, imgSrc: image, alt: recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.name }));
    }, [goBack, onBackCloseTab, recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.image, recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.name]);
    var sectionToShow = React.useMemo(function () {
        if (!recipeDetail)
            return null;
        switch (selectedTab) {
            case RecipeDetailsTab.Ingredients:
                return (_jsx(IngredientsSection, { ingredients: recipeDetail.ingredients, recipeType: type, personsPlanDetail: personsPlanDetail, notifyIngredients: recipeDetail.notifyIngredients, hidePortions: recipeDetail.hidePortions }));
            case RecipeDetailsTab.Preparation:
                return (_jsx(PreparationStepsSection, { preparationSteps: recipeDetail.preparationSteps, isWeaning: recipeDetail.isWeaning }));
            case RecipeDetailsTab.Notes:
                return (_jsx(PlSinglePageLayoutBoxedSection, { children: _jsx(PlText, { html: recipeDetail.generalNotes, className: "my-8" }) }));
        }
    }, [personsPlanDetail, recipeDetail, selectedTab, type]);
    var rightActions = React.useMemo(function () {
        return [
            {
                icon: (recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.forCeliacs) ? _jsx(GlutenFreeIcon, {}) : null,
                roundedBtn: false,
            },
            {
                icon: (_jsx(FavoriteIcon, { type: favouritesIds.includes((recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.id) || '')
                        ? IconFavoriteType.FILLED
                        : IconFavoriteType.EMPTY })),
                onClick: onFavouriteChange,
            },
        ];
    }, [favouritesIds, onFavouriteChange, recipeDetail]);
    var onAddRecipeToPlan = React.useCallback(function () { return setShowDrawer(true); }, []);
    var onCloseDrawer = React.useCallback(function (successAdded) {
        successAdded &&
            showNotifications({
                text: t('recipeDetail.hasBeenAddedToPlan'),
            });
        setShowDrawer(false);
    }, [showNotifications, t]);
    return recipeDetail ? (_jsxs(PlLayout, __assign({ type: LayoutType.SinglePage, header: header }, { children: [_jsxs(PlSinglePageLayoutBoxedSection, __assign({ rightActions: rightActions }, { children: [_jsx(PlTitle, { size: TitleSizeType.ExtraLarge, label: recipeDetail.name, className: "text-[32px]" }), _jsxs("div", __assign({ className: "mt-2 flex items-center" }, { children: [_jsx(TimerIcon, {}), _jsx(PlText, __assign({ className: "ml-2 text-grey font-light" }, { children: recipeTimeFormatter(recipeDetail.preparationTime, t) }))] })), type === RecipeDetailsType.Plan ? (_jsx(PlanRecipeInformation, { personsPlanInformation: personsPlanDetail || [], date: planDate, mealType: planMealType })) : (_jsx(PeopleNumber, { className: "mt-8", peopleNumber: recipeDetail.minimumNumberOfPersons })), _jsx("div", __assign({ className: "mt-8" }, { children: _jsx(FoodGroups, { hasCereals: hasCereals, hasFruit: hasFruit, hasGoodFats: hasGoodFats, hasProteinFood: hasProteinFood, hasVegetables: hasVegetables }) }))] })), _jsx("div", { className: "mt-8 h-2 w-full bg-grey-extra-light " }), _jsx(PlSinglePageLayoutBoxedSection, { children: _jsx("div", __assign({ className: "my-4" }, { children: _jsx(PlTabNavigation, { elements: recipeDetailsTabs, selectedValue: selectedTab, onChangeElement: setSelectedTab }) })) }), _jsx("div", { className: "h-[1px] w-full bg-grey-light" }), sectionToShow, (person === null || person === void 0 ? void 0 : person.familyRole) === FamilyRole.FAMILY_LEADER &&
                type !== RecipeDetailsType.Plan &&
                !isFromPlanSearch && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex items-center justify-center my-5" }, { children: _jsx(PlButton, { type: ButtonType.Primary, label: t('recipeDetail.addToPlan'), className: "min-w-[273px] text-white bg-black", onClick: onAddRecipeToPlan }) })), showDrawer && (_jsx(AddRecipeToPlan, { showDrawer: showDrawer, onCloseDrawer: onCloseDrawer }))] }))] }))) : null;
};
export default RecipePage;
