import { useMemo } from 'react';
import { useLanguage } from '../../locale';
import { PreparationTime } from '../../models';
import { stringifyCategories } from '../../utils';
import { useSearchFilters } from './useSearchFilters';
import { LoaderType } from '../../hooks/api/utils';
export var useFiltersSectionFacade = function (loaderType) {
    if (loaderType === void 0) { loaderType = LoaderType.FullPage; }
    var t = useLanguage()[0];
    var _a = useSearchFilters({}, loaderType), selectedCategoriesFilter = _a.selectedCategoriesFilter, selectedFromWorldFilter = _a.selectedFromWorldFilter, selectedIngredientFilter = _a.selectedIngredientFilter, selectedIsSeasonalFilter = _a.selectedIsSeasonalFilter, selectedPreparationTimeFilter = _a.selectedPreparationTimeFilter, selectedIsFavouritesFilter = _a.selectedIsFavouritesFilter, selectedMacroNutrientsFilter = _a.selectedMacroNutrientsFilter, clearFilters = _a.clearFilters, apply = _a.apply, confirmedFilters = _a.confirmedFilters;
    var existFilter = useMemo(function () {
        return (selectedCategoriesFilter ||
            selectedFromWorldFilter ||
            selectedIngredientFilter ||
            selectedIsSeasonalFilter ||
            selectedPreparationTimeFilter ||
            selectedMacroNutrientsFilter ||
            selectedIsFavouritesFilter);
    }, [
        selectedCategoriesFilter,
        selectedFromWorldFilter,
        selectedIngredientFilter,
        selectedIsFavouritesFilter,
        selectedIsSeasonalFilter,
        selectedMacroNutrientsFilter,
        selectedPreparationTimeFilter,
    ]);
    var preparationTimeText = useMemo(function () {
        switch (selectedPreparationTimeFilter) {
            case PreparationTime.Small:
                return t('filters.preparationTime.short');
            case PreparationTime.Medium:
                return t('filters.preparationTime.medium');
            case PreparationTime.Long:
                return t('filters.preparationTime.long');
            default:
                return t('filters.all');
        }
    }, [selectedPreparationTimeFilter, t]);
    var categoriesFilterText = useMemo(function () {
        if (!selectedCategoriesFilter || selectedCategoriesFilter.length === 0)
            return t('filters.all');
        return stringifyCategories(selectedCategoriesFilter);
    }, [selectedCategoriesFilter, t]);
    var fromWorldFilterText = useMemo(function () {
        if (!selectedFromWorldFilter || selectedFromWorldFilter.length === 0)
            return t('filters.all');
        return stringifyCategories(selectedFromWorldFilter);
    }, [selectedFromWorldFilter, t]);
    var ingredientsFilterText = useMemo(function () {
        if (!selectedIngredientFilter)
            return t('filters.all');
        return selectedIngredientFilter.reduce(function (pv, cv, i) {
            var isFirst = i === 0;
            return pv.concat("".concat(isFirst ? '' : ' + ').concat(cv.name));
        }, '');
    }, [selectedIngredientFilter, t]);
    var isSeasonalFilterText = useMemo(function () {
        switch (selectedIsSeasonalFilter) {
            case true:
                return t('yes');
            case false:
            default:
                return t('filters.indifferent');
        }
    }, [selectedIsSeasonalFilter, t]);
    var isFavouriteFilterText = useMemo(function () {
        switch (selectedIsFavouritesFilter) {
            case true:
                return t('yes');
            case false:
            default:
                return t('no');
        }
    }, [selectedIsFavouritesFilter, t]);
    var nutrientsFilterText = useMemo(function () {
        if (!selectedMacroNutrientsFilter ||
            selectedMacroNutrientsFilter.length === 0)
            return t('filters.all');
        return stringifyCategories(selectedMacroNutrientsFilter);
    }, [selectedMacroNutrientsFilter, t]);
    return {
        selectedCategoriesFilter: selectedCategoriesFilter,
        selectedFromWorldFilter: selectedFromWorldFilter,
        selectedIngredientFilter: selectedIngredientFilter,
        selectedIsSeasonalFilter: selectedIsSeasonalFilter,
        selectedPreparationTimeFilter: selectedPreparationTimeFilter,
        categoriesFilterText: categoriesFilterText,
        fromWorldFilterText: fromWorldFilterText,
        preparationTimeText: preparationTimeText,
        ingredientsFilterText: ingredientsFilterText,
        isSeasonalFilterText: isSeasonalFilterText,
        isFavouriteFilterText: isFavouriteFilterText,
        nutrientsFilterText: nutrientsFilterText,
        clearFilters: clearFilters,
        existFilter: existFilter,
        apply: apply,
        confirmedFilters: confirmedFilters,
    };
};
