var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useEffect, useState, } from 'react';
export var NotificationPosition;
(function (NotificationPosition) {
    NotificationPosition[NotificationPosition["up"] = 0] = "up";
    NotificationPosition[NotificationPosition["bottom"] = 1] = "bottom";
})(NotificationPosition || (NotificationPosition = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["error"] = 0] = "error";
    NotificationType[NotificationType["warning"] = 1] = "warning";
    NotificationType[NotificationType["success"] = 2] = "success";
})(NotificationType || (NotificationType = {}));
export var NotificationsMessageContext = React.createContext({
    showNotifications: function (options) {
        throw new Error('No show method assigned to the provider');
    },
    hideNotifications: function () {
        throw new Error('No hide method assigned to the provider');
    },
});
export var DURATION = 4000;
export var NotificationsMessageProvider = memo(function (_a) {
    var children = _a.children, Component = _a.Component;
    var _b = useState(false), showNotification = _b[0], setShowNotification = _b[1];
    var _c = useState({ text: '', isError: false }), componentProps = _c[0], setComponentProps = _c[1];
    var hideNotifications = useCallback(function () {
        setShowNotification(function () {
            setComponentProps({ text: '', isError: false });
            return false;
        });
    }, []);
    useEffect(function () {
        if (!showNotification) {
            return;
        }
        setTimeout(hideNotifications, DURATION);
    }, [showNotification, hideNotifications]);
    var showNotifications = useCallback(function (options) {
        setComponentProps(function () {
            setShowNotification(true);
            return options;
        });
    }, []);
    return (_jsxs(NotificationsMessageContext.Provider, __assign({ value: {
            hideNotifications: hideNotifications,
            showNotifications: showNotifications,
        } }, { children: [children, showNotification && _jsx(Component, __assign({}, componentProps, { duration: DURATION }))] })));
});
