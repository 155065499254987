import { useCallback, useEffect, useRef } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient, } from '@tanstack/react-query';
import uuid from 'react-native-uuid';
import { GeneralActions } from '../../redux';
import { useDispatch } from 'react-redux';
export var InvalidationTag;
(function (InvalidationTag) {
    InvalidationTag["Person"] = "person";
    InvalidationTag["Countries"] = "countries";
    InvalidationTag["Ingredients"] = "ingredients";
    InvalidationTag["Customer"] = "customer";
    InvalidationTag["Code"] = "code";
    InvalidationTag["Activity"] = "activity";
    InvalidationTag["FeaturedContents"] = "featuredContents";
    InvalidationTag["SeasonalRecipes"] = "seasonalRecipes";
    InvalidationTag["WeeklyRecipe"] = "weeklyRecipe";
    InvalidationTag["SundayRecipes"] = "sundayRecipes";
    InvalidationTag["Family"] = "family";
    InvalidationTag["FamilyStatus"] = "familyStatus";
    InvalidationTag["Recipe"] = "recipe";
    InvalidationTag["Recipes"] = "recipes";
    InvalidationTag["Favourite"] = "favourite";
    InvalidationTag["Plan"] = "plan";
    InvalidationTag["Categories"] = "categories";
    InvalidationTag["FromWorld"] = "fromWorld";
    InvalidationTag["CalorieRange"] = "calorieRange";
    InvalidationTag["ShoppingList"] = "shoppingList";
    InvalidationTag["Notifications"] = "notifications";
    InvalidationTag["InAppPurchase"] = "inAppPurchase";
    InvalidationTag["FoodStorage"] = "foodStorage";
})(InvalidationTag || (InvalidationTag = {}));
export var LoaderType;
(function (LoaderType) {
    LoaderType["FullPage"] = "full-page";
    LoaderType["Contextual"] = "contextual";
    LoaderType["None"] = "none";
})(LoaderType || (LoaderType = {}));
var LOADING = 'loading';
export var useAppQuery = function (key, fetch, configs, loader) {
    if (loader === void 0) { loader = LoaderType.FullPage; }
    var dispatch = useDispatch();
    var queryObj = useQuery(key, fetch, configs);
    var loaderRef = useRef(null);
    useEffect(function () {
        if ((configs === null || configs === void 0 ? void 0 : configs.enabled) === false || loader !== LoaderType.FullPage) {
            return;
        }
        if (loaderRef.current === null && queryObj.status === LOADING) {
            var loaderId = uuid.v4();
            loaderRef.current = loaderId;
            dispatch(GeneralActions.addFullPageLoader(loaderId));
        }
        else if (loaderRef.current) {
            dispatch(GeneralActions.removeFullPageLoader(loaderRef.current));
            loaderRef.current = null;
        }
    }, [configs === null || configs === void 0 ? void 0 : configs.enabled, dispatch, loader, queryObj.status]);
    useEffect(function () {
        return function () {
            if (loaderRef.current) {
                dispatch(GeneralActions.removeFullPageLoader(loaderRef.current));
            }
        };
    }, [dispatch]);
    return queryObj;
};
export var useAppMutation = function (fetch, configs, loader) {
    if (loader === void 0) { loader = LoaderType.FullPage; }
    var dispatch = useDispatch();
    var mutation = useMutation(fetch, configs);
    var loaderRef = useRef(null);
    useEffect(function () {
        if (loader === LoaderType.FullPage) {
            if (loaderRef.current === null && mutation.status === LOADING) {
                var loaderId = uuid.v4();
                loaderRef.current = loaderId;
                dispatch(GeneralActions.addFullPageLoader(loaderId));
            }
            else if (loaderRef.current) {
                dispatch(GeneralActions.removeFullPageLoader(loaderRef.current));
                loaderRef.current = null;
            }
        }
    }, [dispatch, loader, mutation.status]);
    useEffect(function () {
        return function () {
            if (loaderRef.current) {
                dispatch(GeneralActions.removeFullPageLoader(loaderRef.current));
            }
        };
    }, [dispatch]);
    return mutation;
};
export var useAppInfiniteQuery = function (_a) {
    var key = _a.key, fetch = _a.fetch, configs = _a.configs, _b = _a.loader, loader = _b === void 0 ? LoaderType.FullPage : _b;
    var dispatch = useDispatch();
    var infiniteQueryObj = useInfiniteQuery(key, fetch, configs);
    var loaderRef = useRef(null);
    useEffect(function () {
        if (loader !== LoaderType.FullPage) {
            return;
        }
        if (loaderRef.current) {
            dispatch(GeneralActions.removeFullPageLoader(loaderRef.current));
            loaderRef.current = null;
            return;
        }
        if (!infiniteQueryObj.isFetching) {
            return;
        }
        var loaderId = uuid.v4();
        loaderRef.current = loaderId;
        dispatch(GeneralActions.addFullPageLoader(loaderId));
    }, [
        dispatch,
        loader,
        infiniteQueryObj.status,
        infiniteQueryObj.isFetchingNextPage,
        infiniteQueryObj.isFetching,
    ]);
    return infiniteQueryObj;
};
export var useClearCache = function () {
    var queryClient = useQueryClient();
    return useCallback(function () {
        queryClient.clear();
    }, [queryClient]);
};
export var invalidateQueriesForPlan = function (queryClient) {
    void queryClient.invalidateQueries([InvalidationTag.Customer], {
        refetchType: 'active',
    });
    void queryClient.invalidateQueries([InvalidationTag.Person], {
        refetchType: 'active',
    });
    void queryClient.invalidateQueries([InvalidationTag.Family], {
        refetchType: 'active',
    });
    void queryClient.invalidateQueries([InvalidationTag.FamilyStatus], {
        refetchType: 'active',
    });
    void queryClient.invalidateQueries([InvalidationTag.ShoppingList], {
        refetchType: 'none',
    });
    void queryClient.invalidateQueries([InvalidationTag.FoodStorage], {
        refetchType: 'active',
    });
    void queryClient.invalidateQueries([InvalidationTag.FeaturedContents], {
        refetchType: 'all',
    });
    void queryClient.invalidateQueries([InvalidationTag.WeeklyRecipe], {
        refetchType: 'all',
    });
    void queryClient.invalidateQueries([InvalidationTag.SeasonalRecipes], {
        refetchType: 'all',
    });
    void queryClient.invalidateQueries([InvalidationTag.SundayRecipes], {
        refetchType: 'all',
    });
    void queryClient.invalidateQueries([InvalidationTag.Plan], {
        refetchType: 'none',
    });
};
