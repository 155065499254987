function getLowestFraction(x0) {
    var eps = 1.0e-15;
    var h;
    var h1;
    var h2;
    var k;
    var k1;
    var k2;
    var a;
    var x;
    x = x0;
    a = Math.floor(x);
    h1 = 1;
    k1 = 0;
    h = a;
    k = 1;
    while (x - a > eps * k * k) {
        x = 1 / (x - a);
        a = Math.floor(x);
        h2 = h1;
        h1 = h;
        k2 = k1;
        k1 = k;
        h = h2 + a * h1;
        k = k2 + a * k1;
    }
    var formattedFraction;
    switch (true) {
        case h === 1 && k === 2:
            formattedFraction = '½';
            break;
        case h === 1 && k === 4:
            formattedFraction = '¼';
            break;
        case h === 3 && k === 4:
            formattedFraction = '¾';
            break;
        default:
            formattedFraction = h + '/' + k;
    }
    return formattedFraction;
}
export default getLowestFraction;
