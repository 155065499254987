var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import DefaultLayout from './PlDefaultLayout';
import PlHalfPageLayout from './PlHalfPageLayout';
import IntroLayout from './PlIntroLyaout';
import PlSinglePageLayout from './PlSingePageLayout';
import SmallLayout from './PlSmallLyaout';
export var LayoutType;
(function (LayoutType) {
    LayoutType["Small"] = "small";
    LayoutType["FullPage"] = "full-page";
    LayoutType["SinglePage"] = "single-page";
    LayoutType["HalfPage"] = "half-page";
    LayoutType["Default"] = "default";
})(LayoutType || (LayoutType = {}));
var PlLayout = function (props) {
    var correctLayout = React.useMemo(function () {
        switch (props.type) {
            case LayoutType.Small:
                return _jsx(SmallLayout, __assign({}, props));
            case LayoutType.Default:
                return _jsx(DefaultLayout, __assign({}, props));
            case LayoutType.FullPage:
                return _jsx(IntroLayout, __assign({}, props));
            case LayoutType.SinglePage:
                return _jsx(PlSinglePageLayout, __assign({}, props));
            case LayoutType.HalfPage:
                return _jsx(PlHalfPageLayout, __assign({}, props));
            default:
                return _jsx(_Fragment, {});
        }
    }, [props]);
    return correctLayout;
};
export default PlLayout;
