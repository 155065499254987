var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { extractDeepChildrenIds, extractPreparationTime } from '../../utils';
import { useGetRecipes } from '../../hooks/api/recipe/useGetRecipes.api';
import { PaginatedRecipeSearchType, } from '../../models';
import { useSearchFilters } from './useSearchFilters';
import { useFiltersResultFacade } from './useFiltersResult.facade';
import { useGetCustomerApi, useGetPersonApi } from '../../hooks';
var DEFAULT_PAGE_SIZE = 15;
var DEFAULT_PAGE_NUMBER = 0;
export var useRemoteSearchFilters = function (_a) {
    var _b;
    var _c = _a.searchType, searchType = _c === void 0 ? PaginatedRecipeSearchType.SEARCH_PAGE : _c, _d = _a.isPartialSearch, isPartialSearch = _d === void 0 ? false : _d, pageSize = _a.pageSize, pageNumber = _a.pageNumber, mealType = _a.mealType, newRecipesIds = _a.newRecipesIds, recipeId = _a.recipeId, personToChange = _a.personToChange, loader = _a.loader, filtersInit = __rest(_a, ["searchType", "isPartialSearch", "pageSize", "pageNumber", "mealType", "newRecipesIds", "recipeId", "personToChange", "loader"]);
    var customer = useGetCustomerApi(true).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _e = React.useState(false), isSearching = _e[0], setIsSearching = _e[1];
    var _f = React.useState(false), isSearchboxFocused = _f[0], setIsSearchboxFocused = _f[1];
    var _g = React.useState(''), searchText = _g[0], setSearchText = _g[1];
    var _h = useSearchFilters(filtersInit), selectedCategoriesFilter = _h.selectedCategoriesFilter, selectedIngredientFilter = _h.selectedIngredientFilter, selectedFromWorldFilter = _h.selectedFromWorldFilter, selectedIsSeasonalFilter = _h.selectedIsSeasonalFilter, selectedPreparationTimeFilter = _h.selectedPreparationTimeFilter, selectedMacroNutrientsFilter = _h.selectedMacroNutrientsFilter, selectedIsFavouritesFilter = _h.selectedIsFavouritesFilter, confirmedFilters = _h.confirmedFilters, filtersMethods = __rest(_h, ["selectedCategoriesFilter", "selectedIngredientFilter", "selectedFromWorldFilter", "selectedIsSeasonalFilter", "selectedPreparationTimeFilter", "selectedMacroNutrientsFilter", "selectedIsFavouritesFilter", "confirmedFilters"]);
    var selectedFiltersChips = useFiltersResultFacade().selectedFiltersChips;
    var categoriesIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.categories) ||
            confirmedFilters.categories.length === 0)
            return undefined;
        return extractDeepChildrenIds(confirmedFilters.categories);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.categories]);
    var fromWorldIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.fromWorld) || confirmedFilters.fromWorld.length === 0)
            return undefined;
        return extractDeepChildrenIds(confirmedFilters.fromWorld);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.fromWorld]);
    var ingredientsIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.ingredients) ||
            confirmedFilters.ingredients.length === 0)
            return undefined;
        var ingred = confirmedFilters.ingredients.map(function (i) { return i.id; });
        return ingred;
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.ingredients]);
    var preparationTime = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.preparationTime))
            return undefined;
        return extractPreparationTime(confirmedFilters.preparationTime);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.preparationTime]);
    var macroNutrientsIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.macroNutrients) ||
            confirmedFilters.macroNutrients.length === 0)
            return undefined;
        return extractDeepChildrenIds(confirmedFilters.macroNutrients);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.macroNutrients]);
    var filters = {
        categoriesId: categoriesIdsToSearch,
        cuisineTypeId: fromWorldIdsToSearch,
        ingredientsId: ingredientsIdsToSearch,
        preparationTime: preparationTime,
        seasonal: selectedIsSeasonalFilter !== null && selectedIsSeasonalFilter !== void 0 ? selectedIsSeasonalFilter : undefined,
        macroNutrients: macroNutrientsIdsToSearch,
        favourites: selectedIsFavouritesFilter !== null && selectedIsFavouritesFilter !== void 0 ? selectedIsFavouritesFilter : undefined,
        search: searchText,
        take: pageSize !== null && pageSize !== void 0 ? pageSize : DEFAULT_PAGE_SIZE,
        skip: pageNumber !== null && pageNumber !== void 0 ? pageNumber : DEFAULT_PAGE_NUMBER,
    };
    var _j = useGetRecipes(__assign(__assign({ searchType: searchType, familyId: (_b = person === null || person === void 0 ? void 0 : person.familyId) !== null && _b !== void 0 ? _b : '', mealType: mealType, newRecipesIds: newRecipesIds, recipeId: recipeId, personToChange: personToChange, filters: __assign({}, filters) }, (searchType === PaginatedRecipeSearchType.SEARCH_PAGE && filters)), { enabled: !isPartialSearch, loader: loader })), dataItems = _j.dataItems, isFetching = _j.isFetching, fetchRecipes = _j.refetch, fetchNextPage = _j.fetchNextPage;
    var recipes = React.useMemo(function () {
        return dataItems === null || dataItems === void 0 ? void 0 : dataItems.reduce(function (pv, cv) {
            return __spreadArray(__spreadArray([], pv, true), cv.items, true);
        }, []);
    }, [dataItems]);
    React.useEffect(function () {
        if (isSearchboxFocused && ((dataItems === null || dataItems === void 0 ? void 0 : dataItems[0].items) || isFetching)) {
            setIsSearching(true);
        }
    }, [dataItems, isFetching, isSearchboxFocused]);
    React.useEffect(function () {
        if (searchText && searchText.length > 2) {
            var timerRef_1 = setTimeout(function () {
                fetchRecipes();
            }, 750);
            return function () { return clearTimeout(timerRef_1); };
        }
        else {
            setIsSearching(false);
        }
    }, [fetchRecipes, searchText]);
    var endSearch = React.useCallback(function () {
        !isSearchboxFocused && setIsSearching(false);
    }, [isSearchboxFocused]);
    var focusSearchInput = React.useCallback(function () {
        setIsSearchboxFocused(true);
    }, []);
    var blurSearchInput = React.useCallback(function () {
        setIsSearchboxFocused(false);
    }, []);
    var clearText = React.useCallback(function () {
        setSearchText('');
    }, []);
    var onEndReached = React.useCallback(function () {
        return fetchNextPage();
    }, [fetchNextPage]);
    var hasMorePages = React.useMemo(function () {
        if ((dataItems === null || dataItems === void 0 ? void 0 : dataItems.length) === 0)
            return true;
        var lastElementIndex = ((dataItems === null || dataItems === void 0 ? void 0 : dataItems.length) || 0) - 1;
        return dataItems === null || dataItems === void 0 ? void 0 : dataItems[lastElementIndex].pagination.hasMore;
    }, [dataItems]);
    return __assign({ recipes: recipes, searchText: searchText, setSearchText: setSearchText, confirmedFilters: confirmedFilters, focusSearchInput: focusSearchInput, blurSearchInput: blurSearchInput, isSearching: isSearching, endSearch: endSearch, isFetching: isFetching, hasMoreElements: hasMorePages, totalRecipes: dataItems === null || dataItems === void 0 ? void 0 : dataItems[0].pagination.total_items, clearText: clearText, onEndReached: onEndReached, selectedCategoriesFilter: selectedCategoriesFilter, selectedIngredientFilter: selectedIngredientFilter, selectedFromWorldFilter: selectedFromWorldFilter, selectedIsSeasonalFilter: selectedIsSeasonalFilter, selectedPreparationTimeFilter: selectedPreparationTimeFilter, selectedFiltersChips: selectedFiltersChips, isLoading: isSearching || isFetching }, filtersMethods);
};
