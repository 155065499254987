import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterCategoriesSelector, filterFromWorldSelector, filterIngredientsSelector, filterIsFavouritesSelector, filterIsSeasonalSelector, filterMacroNutrientsSelector, filterPreparationTimeSelector, FiltersActions, selectedFiltersSelector, } from '../../redux';
import { useGetCategories, useGetFromWorld } from '../../hooks';
import { LoaderType } from '../../hooks/api/utils';
export var useSearchFilters = function (_a, loaderType) {
    var _b = _a === void 0 ? {} : _a, initCategories = _b.initCategories, initFromWorld = _b.initFromWorld, initIngredients = _b.initIngredients, initIsSeasonal = _b.initIsSeasonal, initPreparationTime = _b.initPreparationTime, initMacroNutrients = _b.initMacroNutrients, initIsFavourite = _b.initIsFavourite;
    if (loaderType === void 0) { loaderType = LoaderType.FullPage; }
    var dispatch = useDispatch();
    var selectedPreparationTimeFilter = useSelector(filterPreparationTimeSelector);
    var selectedIngredientFilter = useSelector(filterIngredientsSelector);
    var selectedCategoriesFilter = useSelector(filterCategoriesSelector);
    var selectedFromWorldFilter = useSelector(filterFromWorldSelector);
    var selectedIsSeasonalFilter = useSelector(filterIsSeasonalSelector);
    var confirmedFilters = useSelector(selectedFiltersSelector);
    var selectedMacroNutrientsFilter = useSelector(filterMacroNutrientsSelector);
    var selectedIsFavouritesFilter = useSelector(filterIsFavouritesSelector);
    var _c = useGetCategories(loaderType), _d = _c.data, categoriesFilters = _d === void 0 ? [] : _d, isCategoryLoading = _c.isLoading;
    var _e = useGetFromWorld(loaderType), _f = _e.data, fromWorldFilters = _f === void 0 ? [] : _f, isFromWorldLoading = _e.isLoading;
    useEffect(function () {
        if (initCategories)
            dispatch(FiltersActions.setCategories(initCategories));
    }, [dispatch, initCategories]);
    useEffect(function () {
        if (initFromWorld)
            dispatch(FiltersActions.setFromWorldFilters(initFromWorld));
    }, [dispatch, initFromWorld]);
    useEffect(function () {
        if (initIngredients)
            dispatch(FiltersActions.setIngredients(initIngredients));
    }, [dispatch, initIngredients]);
    useEffect(function () {
        if (initIsSeasonal)
            dispatch(FiltersActions.setIsSeasonal(initIsSeasonal));
    }, [dispatch, initIsSeasonal]);
    useEffect(function () {
        if (initPreparationTime)
            dispatch(FiltersActions.setPreparationTime(initPreparationTime));
    }, [dispatch, initPreparationTime]);
    useEffect(function () {
        if (initMacroNutrients)
            dispatch(FiltersActions.setMacroNutrients(initMacroNutrients));
    }, [dispatch, initMacroNutrients]);
    useEffect(function () {
        if (initIsFavourite)
            dispatch(FiltersActions.setIsFavourites(initIsFavourite));
    }, [dispatch, initIsFavourite, initPreparationTime]);
    var setFilterIngredient = useCallback(function (ingredients) {
        var ingredientToSet = ingredients.length === 0 ? null : ingredients;
        dispatch(FiltersActions.setIngredients(ingredientToSet));
    }, [dispatch]);
    var toggleFilterIngredient = useCallback(function (ingredientToAdd) {
        dispatch(FiltersActions.toggleIngredient(ingredientToAdd));
    }, [dispatch]);
    var toggleFilterCategory = useCallback(function (categoryToAdd) {
        dispatch(FiltersActions.toggleCategory(categoryToAdd));
    }, [dispatch]);
    var setFilterCategories = useCallback(function (categoriesFiltersToAdd) {
        dispatch(FiltersActions.setCategories(categoriesFiltersToAdd));
    }, [dispatch]);
    var toggleFilterFromWorld = useCallback(function (fromWorldFilterToAdd) {
        dispatch(FiltersActions.toggleFromWorld(fromWorldFilterToAdd));
    }, [dispatch]);
    var toggleFilterMacroNutrients = useCallback(function (macroNutrients) {
        dispatch(FiltersActions.toggleMacroNutrients(macroNutrients));
    }, [dispatch]);
    var setFilterFromWorld = useCallback(function (fromWorldFilterToAdd) {
        dispatch(FiltersActions.setFromWorldFilters(fromWorldFilterToAdd));
    }, [dispatch]);
    var setPreparationTimeToFilter = useCallback(function (preparationTime) {
        if (preparationTime === selectedPreparationTimeFilter) {
            dispatch(FiltersActions.setPreparationTime(null));
        }
        else {
            dispatch(FiltersActions.setPreparationTime(preparationTime));
        }
    }, [dispatch, selectedPreparationTimeFilter]);
    var setIsSeasonalToFilter = useCallback(function (isSeasonal) {
        if (selectedIsSeasonalFilter === isSeasonal) {
            dispatch(FiltersActions.setIsSeasonal(null));
        }
        else {
            dispatch(FiltersActions.setIsSeasonal(isSeasonal));
        }
    }, [dispatch, selectedIsSeasonalFilter]);
    var setFilterMacroNutrients = useCallback(function (macroNutrientsFilterToAdd) {
        dispatch(FiltersActions.setMacroNutrients(macroNutrientsFilterToAdd));
    }, [dispatch]);
    var setIsFavouritesToFilter = useCallback(function (isFavourite) {
        if (selectedIsFavouritesFilter === isFavourite) {
            dispatch(FiltersActions.setIsFavourites(null));
        }
        else {
            dispatch(FiltersActions.setIsFavourites(isFavourite));
        }
    }, [dispatch, selectedIsFavouritesFilter]);
    var clearFilters = useCallback(function () {
        dispatch(FiltersActions.clearFilters());
    }, [dispatch]);
    var apply = useCallback(function () {
        dispatch(FiltersActions.applyFilters());
    }, [dispatch]);
    return {
        categoriesFilters: categoriesFilters,
        fromWorldFilters: fromWorldFilters,
        isCategoryLoading: isCategoryLoading,
        isFromWorldLoading: isFromWorldLoading,
        selectedPreparationTimeFilter: selectedPreparationTimeFilter,
        selectedIngredientFilter: selectedIngredientFilter,
        selectedCategoriesFilter: selectedCategoriesFilter,
        selectedFromWorldFilter: selectedFromWorldFilter,
        selectedIsSeasonalFilter: selectedIsSeasonalFilter,
        selectedMacroNutrientsFilter: selectedMacroNutrientsFilter,
        selectedIsFavouritesFilter: selectedIsFavouritesFilter,
        confirmedFilters: confirmedFilters,
        setPreparationTimeToFilter: setPreparationTimeToFilter,
        setIsSeasonalToFilter: setIsSeasonalToFilter,
        toggleFilterCategory: toggleFilterCategory,
        toggleFilterFromWorld: toggleFilterFromWorld,
        toggleFilterIngredient: toggleFilterIngredient,
        toggleFilterMacroNutrients: toggleFilterMacroNutrients,
        setFilterCategories: setFilterCategories,
        setFilterFromWorld: setFilterFromWorld,
        setFilterIngredient: setFilterIngredient,
        setFilterMacroNutrients: setFilterMacroNutrients,
        setIsFavouritesToFilter: setIsFavouritesToFilter,
        clearFilters: clearFilters,
        apply: apply,
    };
};
