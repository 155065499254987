import { createNotImplementedMethod } from '../repositories/utils';
var NotImplementedErrorHandler = /** @class */ (function () {
    function NotImplementedErrorHandler() {
        this.blockInteraction = createNotImplementedMethod();
        this.nonBlockingInteractionError = createNotImplementedMethod();
        this.sendLog = createNotImplementedMethod();
        this.sendUnauthorized = createNotImplementedMethod();
    }
    return NotImplementedErrorHandler;
}());
export { NotImplementedErrorHandler };
