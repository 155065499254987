import { createNotImplementedMethod } from '../utils';
var NotImplementedFamilyRepository = /** @class */ (function () {
    function NotImplementedFamilyRepository() {
        this.getFamily = createNotImplementedMethod();
        this.getFamilyPlanStatus = createNotImplementedMethod();
        this.updateFamily = createNotImplementedMethod();
        this.removeMember = createNotImplementedMethod();
    }
    return NotImplementedFamilyRepository;
}());
export { NotImplementedFamilyRepository };
