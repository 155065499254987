var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguage } from '@planter/common';
import * as React from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import IdeaIcon from '../../../components/icons/IdeaIcon';
import PlText from '../../../components/PlText';
var TipPopover = function (_a) {
    var tipDescription = _a.tipDescription, isPopoverOpen = _a.isPopoverOpen, closePopover = _a.closePopover, togglePopover = _a.togglePopover;
    var t = useLanguage()[0];
    return (_jsx(Popover, __assign({ isOpen: isPopoverOpen, positions: ['top', 'left', 'bottom', 'right'], padding: 10, reposition: true, onClickOutside: closePopover, content: function (_a) {
            var position = _a.position, childRect = _a.childRect, popoverRect = _a.popoverRect;
            return (_jsx(ArrowContainer, __assign({ position: position, childRect: childRect, popoverRect: popoverRect, arrowColor: 'white', arrowSize: 5 }, { children: _jsxs("div", __assign({ onClick: togglePopover, className: "bg-white p-4 rounded-2xl max-w-[272px]" }, { children: [_jsx(PlText, __assign({ className: "mb-2 font-semibold text-[16px]" }, { children: t('recipeDetail.tipTitle') })), _jsx(PlText, { html: tipDescription })] })) })));
        } }, { children: _jsx("button", __assign({ onClick: togglePopover, className: "h-9 w-9 bg-white flex items-center justify-center rounded-full ".concat(isPopoverOpen ? 'z-10' : '') }, { children: _jsx(IdeaIcon, { size: 24 }) })) })));
};
export default TipPopover;
