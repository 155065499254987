var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isAuthorizedSelector, useFiltersSectionFacade, useGetCustomerApi, useGetPersonApi, useLanguage, } from '@planter/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { AppRoutes } from '../routes/routes';
import colors from '../styles/colors.module.scss';
import { getImageUrl } from '../utils';
import PlProfileImage from './PlProfileImage';
import AskIcon from './icons/AskIcon';
import ExploreIcon from './icons/ExploreIcon';
import PlanIcon from './icons/PlanIcon';
var PlMenu = function (_a) {
    var className = _a.className;
    var t = useLanguage()[0];
    var clearFilters = useFiltersSectionFacade().clearFilters;
    var isAuthenticated = useSelector(isAuthorizedSelector);
    var customer = useGetCustomerApi(isAuthenticated).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    return (_jsxs("div", __assign({ className: "grid content-between p-6 ".concat(className) }, { children: [_jsx("div", __assign({ className: "w-full" }, { children: _jsx("nav", { children: _jsxs("ul", { children: [_jsx("li", __assign({ className: "pb-6" }, { children: _jsx(NavLink, { to: AppRoutes.Plan, children: function (_a) {
                                        var isActive = _a.isActive;
                                        return (_jsxs("div", __assign({ className: "flex items-center", onClick: function () { return clearFilters(); } }, { children: [_jsx(PlanIcon, { color: colors[!isActive ? 'grey' : 'black'] }), _jsx("p", __assign({ className: "pl-2 ".concat(!isActive ? 'text-grey' : 'text-black') }, { children: t('menu.plan') }))] })));
                                    } }) })), _jsx("li", __assign({ className: "pb-6" }, { children: _jsx(NavLink, { to: AppRoutes.Explore, children: function (_a) {
                                        var isActive = _a.isActive;
                                        return (_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(ExploreIcon, { color: colors[!isActive ? 'grey' : 'black'] }), _jsx("p", __assign({ className: "pl-2 ".concat(!isActive ? 'text-grey' : 'text-black') }, { children: t('menu.explore') }))] })));
                                    } }) })), _jsx("li", __assign({ className: "pb-6" }, { children: _jsx(NavLink, { to: AppRoutes.Ask, children: function (_a) {
                                        var isActive = _a.isActive;
                                        return (_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(AskIcon, { color: colors[!isActive ? 'grey' : 'black'] }), _jsx("p", __assign({ className: "pl-2 ".concat(!isActive ? 'text-grey' : 'text-black') }, { children: t('menu.ask') }))] })));
                                    } }) }))] }) }) })), _jsx(Link, __assign({ to: AppRoutes.ProfilePage }, { children: _jsxs("div", __assign({ className: "w-full flex items-center" }, { children: [_jsx(PlProfileImage, { profileImage: getImageUrl(person === null || person === void 0 ? void 0 : person.profileImageUrl), profileId: person === null || person === void 0 ? void 0 : person.id }), _jsx("p", __assign({ className: "font-normal text-grey pl-2" }, { children: person === null || person === void 0 ? void 0 : person.name }))] })) }))] })));
};
export default PlMenu;
