var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { toggleElementOnTree } from '../../utils';
import { filtersInitialState } from './filters.slice';
export var setPreparationTimeReducer = function (state, _a) {
    var payload = _a.payload;
    state.preparationTime = payload;
};
export var toggleIngredientReducer = function (state, _a) {
    var payload = _a.payload;
    if (!state.ingredients)
        state.ingredients = [];
    var ingredientIndex = state.ingredients.findIndex(function (i) { return i.id === payload.id; });
    if (ingredientIndex > -1) {
        state.ingredients.splice(ingredientIndex, 1);
    }
    else {
        state.ingredients.push(payload);
    }
};
export var setIngredientsReducer = function (state, _a) {
    var payload = _a.payload;
    state.ingredients = payload;
};
export var toggleCategoryReducer = function (state, _a) {
    var _b;
    var payload = _a.payload;
    toggleElementOnTree(payload, (_b = state.categories) !== null && _b !== void 0 ? _b : []);
};
export var setCategoriesReducer = function (state, _a) {
    var payload = _a.payload;
    state.categories = payload;
};
export var toggleFromWorldReducer = function (state, _a) {
    var _b;
    var payload = _a.payload;
    toggleElementOnTree(payload, (_b = state.fromWorld) !== null && _b !== void 0 ? _b : []);
};
export var setFromWorldFiltersReducer = function (state, _a) {
    var payload = _a.payload;
    state.fromWorld = payload;
};
export var setIsSeasonalReducer = function (state, _a) {
    var payload = _a.payload;
    state.isSeasonal = payload;
};
export var setIsFavouritesReducer = function (state, _a) {
    var payload = _a.payload;
    state.isFavourites = payload;
};
export var toggleMacroNutrientsReducer = function (state, _a) {
    var _b;
    var payload = _a.payload;
    toggleElementOnTree(payload, (_b = state.macroNutrients) !== null && _b !== void 0 ? _b : []);
};
export var setMacroNutrientsReducer = function (state, _a) {
    var payload = _a.payload;
    state.macroNutrients = payload;
};
export var cancelFiltersEdit = function (state) {
    if (!state.selectedFilters) {
        state = filtersInitialState;
        return;
    }
    state.categories = state.selectedFilters.categories;
    state.fromWorld = state.selectedFilters.fromWorld;
    state.ingredients = state.selectedFilters.ingredients;
    state.isSeasonal = state.selectedFilters.isSeasonal;
    state.preparationTime = state.selectedFilters.preparationTime;
    state.macroNutrients = state.selectedFilters.macroNutrients;
    state.isFavourites = state.selectedFilters.isFavourites;
};
export var applyFiltersReducer = function (state) {
    var selectedFilters = state.selectedFilters, tempFilters = __rest(state, ["selectedFilters"]);
    state.selectedFilters = tempFilters;
};
export var clearSelectedFiltersReducer = function (state) {
    state.preparationTime = filtersInitialState.preparationTime;
    state.categories = filtersInitialState.categories;
    state.fromWorld = filtersInitialState.fromWorld;
    state.ingredients = filtersInitialState.ingredients;
    state.isSeasonal = filtersInitialState.isSeasonal;
    state.selectedFilters = filtersInitialState.selectedFilters;
    state.macroNutrients = filtersInitialState.macroNutrients;
    state.isFavourites = filtersInitialState.isFavourites;
};
