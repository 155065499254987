var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FamilyRole, RecipeDetailsType, useLanguage, } from '@planter/common';
import * as React from 'react';
import PlSinglePageLayoutBoxedSection from '../../../components/layouts/PlSinglePageLayoutBoxedSection';
import ManageIngredientInformation from './ManageIngredientInformation';
import NotifyIngredients from './NotifyIngredients';
import SwitchToDoses from './SwitchToDoses';
var IngredientsSection = function (_a) {
    var ingredients = _a.ingredients, recipeType = _a.recipeType, personsPlanDetail = _a.personsPlanDetail, _b = _a.notifyIngredients, notifyIngredients = _b === void 0 ? false : _b, hidePortions = _a.hidePortions;
    var t = useLanguage()[0];
    var _c = React.useState(false), showDosesForMembers = _c[0], setShowDosesForMembers = _c[1];
    var onDosesChange = React.useCallback(function () {
        setShowDosesForMembers(function (prevValue) { return !prevValue; });
    }, []);
    return (_jsxs(PlSinglePageLayoutBoxedSection, __assign({ className: "bg-grey-extra-light ".concat(notifyIngredients ? 'pt-4 pb-8' : 'py-8') }, { children: [notifyIngredients && (_jsx("div", __assign({ className: "mb-4" }, { children: _jsx(NotifyIngredients, {}) }))), recipeType === RecipeDetailsType.Plan && !hidePortions && (_jsx(SwitchToDoses, { checked: showDosesForMembers, onDosesChange: onDosesChange })), _jsx("div", __assign({ className: "rounded-2xl bg-white p-4" }, { children: ingredients === null || ingredients === void 0 ? void 0 : ingredients.map(function (e, i) {
                    var isFirst = i === 0;
                    var isLast = i === ingredients.length - 1;
                    return (_jsxs("div", __assign({ className: "".concat(isLast ? '' : 'pb-3', " border-gray-light ").concat(!isFirst ? 'border-t pt-3' : '') }, { children: [_jsx(ManageIngredientInformation, { ingredient: e, nameToShow: e.name }), showDosesForMembers &&
                                (personsPlanDetail === null || personsPlanDetail === void 0 ? void 0 : personsPlanDetail.map(function (person) {
                                    var _a;
                                    var personIngredient = (_a = person.ingredients) === null || _a === void 0 ? void 0 : _a.find(function (i) { return i.id === e.id; });
                                    var name = person.familyRole === FamilyRole.FAMILY_GUEST
                                        ? t('recipeDetail.quest')
                                        : person.name;
                                    return personIngredient ? (_jsx(ManageIngredientInformation, { ingredient: personIngredient, nameToShow: name, showPortion: true })) : (_jsx(_Fragment, {}));
                                }))] }), i));
                }) }))] })));
};
export default IngredientsSection;
