var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import PlLayoutAction from './PlLayoutAction';
var PlSinglePageLayoutBoxedSection = function (_a) {
    var children = _a.children, leftActions = _a.leftActions, rightActions = _a.rightActions, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (_jsxs("div", __assign({ className: "flex justify-between ".concat(className) }, { children: [_jsx("div", __assign({ className: "flex flex-col mt-8 ml-4" }, { children: leftActions === null || leftActions === void 0 ? void 0 : leftActions.map(function (actionProps, i) {
                    if (i === 0)
                        return _jsx(PlLayoutAction, __assign({}, actionProps), "left-action-".concat(i));
                    return (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx(PlLayoutAction, __assign({}, actionProps)) }), "left-action-".concat(i)));
                }) })), _jsx("div", __assign({ className: "flex-1 max-w-xl mx-8 mt-8" }, { children: children })), _jsx("div", __assign({ className: "flex flex-col mt-8 mr-4" }, { children: rightActions === null || rightActions === void 0 ? void 0 : rightActions.map(function (actionProps, i) {
                    if (i === 0)
                        return (_jsx(PlLayoutAction, __assign({}, actionProps), "right-action-".concat(i)));
                    return (_jsx("div", __assign({ className: "mt-4" }, { children: _jsx(PlLayoutAction, __assign({}, actionProps)) }), "right-action-".concat(i)));
                }) }))] })));
};
export default PlSinglePageLayoutBoxedSection;
