import { useEffect } from 'react';
import { InAppPurchase } from '../services/InAppPurchase/InAppPurchase';
export var useInAppPurchase = function (_a) {
    var onEntitlementChange = _a.onEntitlementChange;
    useEffect(function () {
        if (!onEntitlementChange) {
            return;
        }
        var unsubscribe = InAppPurchase.onEntitlementChange(onEntitlementChange);
        return function () {
            if (!unsubscribe) {
                return;
            }
            unsubscribe();
        };
    }, [onEntitlementChange]);
    return {
        initSdk: InAppPurchase.identifyUser,
        checkEntitlements: InAppPurchase.checkEntitlements,
        logout: InAppPurchase.logout,
        errorManager: InAppPurchase.errorManager,
    };
};
