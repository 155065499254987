var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import dayjs from 'dayjs';
import weekdayPlugin from 'dayjs/plugin/weekday';
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay, isInclusivelyAfterDay, } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './datePicker.scss';
import moment from 'moment';
import 'react-dates/constants';
import 'moment/locale/it';
dayjs.extend(weekdayPlugin);
moment.locale('it');
var PlSingleDatePicker = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, selectedDate = _a.selectedDate, onDateChange = _a.onDateChange;
    var _c = React.useState(true), focused = _c[0], setFocused = _c[1];
    return (_jsx("div", __assign({ className: "DatePickerContainer ".concat(className) }, { children: _jsx(SingleDatePicker, { id: "single_date_picker", date: selectedDate, onDateChange: onDateChange, focused: focused, displayFormat: 'DD', orientation: 'vertical', onFocusChange: function () { return setFocused(true); }, isOutsideRange: function (day) {
                return isInclusivelyAfterDay(day, moment().add(2, 'week')) || // non puoi selezionare un giorno successivo a 2 settimane da oggi
                    isInclusivelyBeforeDay(day, moment().subtract(1, 'day'));
            } // non puoi selezionare un giorno precedente ad oggi
            , numberOfMonths: 2, inputIconPosition: "before", noBorder: true, openDirection: 'down', keepOpenOnDateSelect: true, hideKeyboardShortcutsPanel: true, small: true, navPrev: _jsx(_Fragment, {}), navNext: _jsx(_Fragment, {}) }) })));
};
export default PlSingleDatePicker;
