import * as React from 'react';
import { useLanguage } from '../../locale';
import { PreparationTime } from '../../models';
import { useSearchFilters } from './useSearchFilters';
export var usePreparationTimeSectionFacade = function () {
    var t = useLanguage()[0];
    var _a = useSearchFilters(), setPreparationTimeToFilter = _a.setPreparationTimeToFilter, selectedPreparationTimeFilter = _a.selectedPreparationTimeFilter;
    var _b = React.useState(null), preparationTimeOption = _b[0], setPreparationTimeOption = _b[1];
    var preparationTime = preparationTimeOption === null || preparationTimeOption === void 0 ? void 0 : preparationTimeOption.value;
    var options = React.useMemo(function () {
        return [
            {
                type: 'default',
                label: "".concat(t('filters.preparationTime.short'), " ").concat(t('filters.preparationTime.shortTime')),
                value: PreparationTime.Small,
            },
            {
                type: 'default',
                label: "".concat(t('filters.preparationTime.medium'), " ").concat(t('filters.preparationTime.mediumTime')),
                value: PreparationTime.Medium,
            },
            {
                type: 'default',
                label: "".concat(t('filters.preparationTime.long'), " ").concat(t('filters.preparationTime.longTime')),
                value: PreparationTime.Long,
            },
        ];
    }, [t]);
    React.useEffect(function () {
        if (selectedPreparationTimeFilter) {
            var prepTimeOptionToSet = options.find(function (o) { return o.value === selectedPreparationTimeFilter; });
            setPreparationTimeOption(prepTimeOptionToSet !== null && prepTimeOptionToSet !== void 0 ? prepTimeOptionToSet : null);
        }
    }, [options, selectedPreparationTimeFilter]);
    var reset = React.useCallback(function () {
        setPreparationTimeOption(null);
    }, []);
    var applyFilter = React.useCallback(function () {
        var _a;
        setPreparationTimeToFilter((_a = preparationTimeOption === null || preparationTimeOption === void 0 ? void 0 : preparationTimeOption.value) !== null && _a !== void 0 ? _a : null);
    }, [preparationTimeOption === null || preparationTimeOption === void 0 ? void 0 : preparationTimeOption.value, setPreparationTimeToFilter]);
    return {
        options: options,
        preparationTimeOption: preparationTimeOption,
        preparationTime: preparationTime,
        applyFilter: applyFilter,
        setPreparationTimeOption: setPreparationTimeOption,
        reset: reset,
    };
};
