var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var CheckMemberIcon = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.color, color = _c === void 0 ? '#50AA75' : _c, size = _a.size, _d = _a.width, width = _d === void 0 ? 14 : _d, _e = _a.height, height = _e === void 0 ? 11 : _e;
    return (_jsx("svg", __assign({ width: size !== null && size !== void 0 ? size : width, height: size !== null && size !== void 0 ? size : width, className: "".concat(className), viewBox: "0 0 14 11", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.4982 11.0005C4.38853 11.0011 4.27981 10.9801 4.17828 10.9386C4.07675 10.8972 3.9844 10.836 3.90653 10.7588L0.573201 7.42548C0.416281 7.26856 0.328125 7.05573 0.328125 6.83381C0.328125 6.6119 0.416281 6.39907 0.573201 6.24215C0.730121 6.08523 0.94295 5.99707 1.16487 5.99707C1.38679 5.99707 1.59961 6.08523 1.75653 6.24215L4.4982 8.99215L12.2399 1.24215C12.3968 1.08523 12.6096 0.99707 12.8315 0.99707C13.0535 0.99707 13.2663 1.08523 13.4232 1.24215C13.5801 1.39907 13.6683 1.6119 13.6683 1.83381C13.6683 2.05573 13.5801 2.26856 13.4232 2.42548L5.08987 10.7588C5.012 10.836 4.91965 10.8972 4.81812 10.9386C4.71659 10.9801 4.60787 11.0011 4.4982 11.0005Z", fill: color }) })));
};
export default CheckMemberIcon;
