var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var AddIcon = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? '#000000' : _b, size = _a.size, _c = _a.width, width = _c === void 0 ? 13 : _c, _d = _a.height, height = _d === void 0 ? 12 : _d;
    return (_jsx("svg", __assign({ viewBox: "0 0 13 12", width: size !== null && size !== void 0 ? size : width, height: size !== null && size !== void 0 ? size : height, fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: _jsx("path", { d: "M11.5 6.834H7.332V11a.836.836 0 0 1-.834.834.836.836 0 0 1-.833-.834V6.834H1.499A.836.836 0 0 1 .666 6c0-.458.375-.833.833-.833h4.167V1c0-.458.375-.833.833-.833.459 0 .834.375.834.833v4.167h4.166c.459 0 .834.375.834.833a.836.836 0 0 1-.834.834Z", fill: color }) })));
};
export default AddIcon;
