import { NutrientTypeName } from '../models';
import { convertNutrientToType } from './convertNutrientToType';
export var nutrientsToNutrientsType = function (recipe) {
    var nutrientNames = [
        'proteinFoods',
        'cereals',
        'goodFats',
        'vegetables',
        'fruit',
    ];
    var getNutrientTypeName = function (name) {
        if (!(recipe === null || recipe === void 0 ? void 0 : recipe[name])) {
            return;
        }
        return convertNutrientToType(name);
    };
    var nutrients = nutrientNames.map(getNutrientTypeName);
    var sortedNutrients = [
        NutrientTypeName.cereal,
        NutrientTypeName.protein,
        NutrientTypeName.vegetable,
        NutrientTypeName.fruit,
        NutrientTypeName.fats,
    ].map(function (nutrientTypeName) {
        return nutrients.find(function (nutrient) { return nutrient === nutrientTypeName; });
    });
    return sortedNutrients.filter(function (nutrientType) { return nutrientType !== undefined; });
};
