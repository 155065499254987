var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useCallback, useEffect, useRef, useState, } from 'react';
import { useStorage } from '../StorageContext/useStorage';
import { NotImplementedReminderNotifications, StorageKeys, } from '../../services';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ReminderId, } from './reminderContext.types';
import { useSelector } from 'react-redux';
import { isAuthorizedSelector } from '../../redux';
import { useLanguage } from '../../locale';
dayjs.extend(customParseFormat);
export var DEFAULT_REMINDER_SETTINGS = (_a = {},
    _a[ReminderId.vitaminB12] = {
        isEnabled: true,
        time: dayjs('08:00', 'HH:mm'),
        days: [0, 1, 2, 3, 4, 5, 6],
    },
    _a[ReminderId.omega3] = {
        isEnabled: true,
        time: dayjs('08:00', 'HH:mm'),
        days: [],
    },
    _a);
export var ReminderContext = createContext({
    setups: DEFAULT_REMINDER_SETTINGS,
    setReminderSetup: function (_id, _settings) { },
    reminderNotifications: new NotImplementedReminderNotifications(),
    resetReminder: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
});
export var ReminderProvider = function (_a) {
    var isAbleToShowNotification = _a.isAbleToShowNotification, children = _a.children, ReminderImplementation = _a.ReminderImplementation, Component = _a.Component;
    var t = useLanguage()[0];
    var isAuthenticated = useSelector(isAuthorizedSelector);
    var _b = useStorage(), getItem = _b.getItem, setItem = _b.setItem, removeItem = _b.removeItem;
    var _c = useState(DEFAULT_REMINDER_SETTINGS), setups = _c[0], setSetups = _c[1];
    var _d = useState([]), notificationQueue = _d[0], setNotificationQueue = _d[1];
    // ** USE REF ** //
    var reminderNotifications = useRef(new ReminderImplementation(t)).current;
    // ** USE CALLBACKS ** //
    var resetReminder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, removeItem(StorageKeys.REMINDERS_SETTINGS)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, reminderNotifications.cancelTriggerNotifications([
                            ReminderId.omega3,
                            ReminderId.vitaminB12,
                        ])];
                case 2:
                    _a.sent();
                    setSetups(DEFAULT_REMINDER_SETTINGS);
                    return [2 /*return*/];
            }
        });
    }); }, [reminderNotifications, removeItem]);
    var getRemindersSetups = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var storageSetups;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getItem(StorageKeys.REMINDERS_SETTINGS)];
                case 1:
                    storageSetups = _a.sent();
                    if (!storageSetups) {
                        return [2 /*return*/];
                    }
                    setSetups(JSON.parse(storageSetups));
                    return [2 /*return*/];
            }
        });
    }); }, [getItem]);
    var setReminderSetup = useCallback(function (id, settings) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedSetups;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    updatedSetups = __assign(__assign({}, setups), (_a = {}, _a[id] = settings, _a));
                    return [4 /*yield*/, setItem(StorageKeys.REMINDERS_SETTINGS, JSON.stringify(updatedSetups))];
                case 1:
                    _b.sent();
                    setSetups(updatedSetups);
                    return [2 /*return*/];
            }
        });
    }); }, [setItem, setups]);
    var onDismissPress = useCallback(function () {
        try {
            setNotificationQueue(function (prevQueue) { return prevQueue.slice(1); });
            reminderNotifications
                .cancelDisplayedNotification(notificationQueue[0])
                .then();
        }
        catch (e) {
            console.error('error during dismiss notification: ', e);
        }
    }, [notificationQueue, reminderNotifications]);
    var addNotificationToQueue = useCallback(function (notificationId) {
        setNotificationQueue(function (prevQueue) { return __spreadArray(__spreadArray([], prevQueue, true), [notificationId], false); });
    }, []);
    var onDisableNotificationPress = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var dismissedNotification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!notificationQueue.length) {
                        return [2 /*return*/];
                    }
                    dismissedNotification = notificationQueue[0];
                    onDismissPress();
                    if (!dismissedNotification) return [3 /*break*/, 2];
                    return [4 /*yield*/, setReminderSetup(dismissedNotification, __assign(__assign({}, setups[dismissedNotification]), { isEnabled: false }))];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [notificationQueue, onDismissPress, setReminderSetup, setups]);
    // ** USE EFFECTS ** //
    useEffect(function () {
        getRemindersSetups()
            .then()
            .catch(function (error) { return console.error(error); });
    }, [getRemindersSetups]);
    useEffect(function () {
        if (!isAuthenticated || !isAbleToShowNotification) {
            return;
        }
        reminderNotifications
            .cancelTriggerNotifications([ReminderId.omega3, ReminderId.vitaminB12])
            .then(function () { return reminderNotifications.createTriggerNotification(setups); });
    }, [
        isAbleToShowNotification,
        isAuthenticated,
        reminderNotifications,
        setups,
    ]);
    var addNotificationToQueueIfPresent = useCallback(function (id) {
        if (!id || !Object.values(ReminderId).includes(id)) {
            return;
        }
        addNotificationToQueue(id);
    }, [addNotificationToQueue]);
    useEffect(function () {
        reminderNotifications.getInitialNotification().then(function (id) {
            addNotificationToQueueIfPresent(id);
        });
    }, [addNotificationToQueueIfPresent, reminderNotifications]);
    useEffect(function () {
        reminderNotifications.onBackgroundEvent(function (id) {
            addNotificationToQueueIfPresent(id);
        });
    }, [addNotificationToQueueIfPresent, reminderNotifications]);
    useEffect(function () {
        reminderNotifications.onForegroundEvent(function (id) {
            addNotificationToQueueIfPresent(id);
        });
    }, [addNotificationToQueueIfPresent, reminderNotifications]);
    return (_jsxs(ReminderContext.Provider, __assign({ value: {
            setups: setups,
            setReminderSetup: setReminderSetup,
            reminderNotifications: reminderNotifications,
            resetReminder: resetReminder,
        } }, { children: [children, notificationQueue.length > 0 && (_jsx(Component, { id: notificationQueue[0], onDisableNotificationPress: onDisableNotificationPress, onDismissPress: onDismissPress }))] })));
};
