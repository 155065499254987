var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useGenerateAlternativePlanMeal, useGetCustomerApi, useGetPersonApi, } from '../../hooks';
import { useCallback, useMemo, useState } from 'react';
import { useSaveAlternativeMealFacade } from './useSaveAlternativeMeal.facade';
import { convertNutrientToType } from '../../utils';
export var useChangePlanWithSuggestedMealFacade = function (_a) {
    var mealType = _a.mealType, selectedMembersDailyPlan = _a.selectedMembersDailyPlan, guests = _a.guests, loaderType = _a.loaderType;
    var customer = useGetCustomerApi(true).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _b = useGenerateAlternativePlanMeal({
        familyId: person === null || person === void 0 ? void 0 : person.familyId,
        mealType: mealType,
        personToChange: selectedMembersDailyPlan,
        loader: loaderType,
    }), generatedAlternativeMeals = _b.data, isLoading = _b.isLoading;
    var _c = useState(-1), recipeIndex = _c[0], setRecipeIndex = _c[1];
    var isCreationWithSuggestedMeal = recipeIndex > -1;
    var onChooseIndex = useCallback(function (id) {
        setRecipeIndex(function (prevIndex) { return (prevIndex === id ? -1 : id); });
    }, []);
    var personsAlternativeMeal = useMemo(function () {
        var _a, _b;
        if (!(generatedAlternativeMeals === null || generatedAlternativeMeals === void 0 ? void 0 : generatedAlternativeMeals.alternative) || recipeIndex === -1) {
            return;
        }
        var newRecipeIds = (_b = (_a = generatedAlternativeMeals === null || generatedAlternativeMeals === void 0 ? void 0 : generatedAlternativeMeals.alternative) === null || _a === void 0 ? void 0 : _a[recipeIndex]) === null || _b === void 0 ? void 0 : _b.map(function (meal) { return meal.id; });
        var members = selectedMembersDailyPlan.map(function (member) { return ({
            dailyPlanId: member.dailyPlan,
            mealType: mealType,
            newRecipeIds: newRecipeIds,
        }); });
        return { persons: members, quantity: guests };
    }, [
        generatedAlternativeMeals === null || generatedAlternativeMeals === void 0 ? void 0 : generatedAlternativeMeals.alternative,
        guests,
        mealType,
        recipeIndex,
        selectedMembersDailyPlan,
    ]);
    var _d = useSaveAlternativeMealFacade({
        personsAlternativeMeal: personsAlternativeMeal,
    }), saveAlternativeMeal = _d.saveAlternativeMeal, isSaveLoading = _d.isSaveLoading;
    var onSavePress = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!personsAlternativeMeal) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, saveAlternativeMeal()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [personsAlternativeMeal, saveAlternativeMeal]);
    var requiredNutrients = useMemo(function () {
        var alternativeMeals = generatedAlternativeMeals === null || generatedAlternativeMeals === void 0 ? void 0 : generatedAlternativeMeals.alternative;
        if (!(alternativeMeals === null || alternativeMeals === void 0 ? void 0 : alternativeMeals.length) && !(alternativeMeals === null || alternativeMeals === void 0 ? void 0 : alternativeMeals[0].length)) {
            return [];
        }
        var nutrients = [
            'cereals',
            'proteinFoods',
            'vegetables',
            'fruit',
            'goodFats',
        ];
        return nutrients
            .filter(function (nutrient) {
            return alternativeMeals[0].some(function (recipe) { return (recipe === null || recipe === void 0 ? void 0 : recipe[nutrient]) > 0; });
        })
            .map(convertNutrientToType)
            .filter(function (nutrientType) { return nutrientType !== null; });
    }, [generatedAlternativeMeals === null || generatedAlternativeMeals === void 0 ? void 0 : generatedAlternativeMeals.alternative]);
    return {
        alternativeMeals: generatedAlternativeMeals === null || generatedAlternativeMeals === void 0 ? void 0 : generatedAlternativeMeals.alternative,
        onChooseIndex: onChooseIndex,
        recipeIndex: recipeIndex,
        isCreationWithSuggestedMeal: isCreationWithSuggestedMeal,
        isLoading: isLoading,
        isSaveLoading: isSaveLoading,
        onSavePress: onSavePress,
        requiredNutrients: requiredNutrients,
    };
};
