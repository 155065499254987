var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var CheckCircleIcon = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.color, color = _c === void 0 ? '#000000' : _c, _d = _a.size, size = _d === void 0 ? 2 : _d, width = _a.width, height = _a.height;
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 18 18", strokeWidth: 0, stroke: "currentColor", className: "w-".concat(width !== null && width !== void 0 ? width : size, " h-").concat(height !== null && height !== void 0 ? height : size, " ").concat(className) }, { children: _jsx("path", { d: "M9.001.667a8.333 8.333 0 1 0 0 16.667 8.333 8.333 0 0 0 0-16.667Zm3.925 7.258L8.76 12.092a.833.833 0 0 1-1.184 0l-2.5-2.5A.837.837 0 1 1 6.26 8.409l1.908 1.916 3.575-3.583a.837.837 0 1 1 1.183 1.183Z", fill: color }) })));
};
export default CheckCircleIcon;
