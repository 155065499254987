var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { RecipeDetailsType } from '@planter/common';
import * as React from 'react';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var PlanPage = React.lazy(function () { return import('../pages/plan/plan.page'); });
var RecipePage = React.lazy(function () { return import('../pages/recipeDetails/recipe.page'); });
var planRedirectPages = [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.NoCountryUser,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.PartialUser,
        page: AppRoutes.JoinFamily,
    },
    {
        userRole: UserRole.PlanPendingUser,
        page: AppRoutes.OnboardingWelcomePage,
    },
    {
        userRole: UserRole.UserPaymentNeeded,
        page: AppRoutes.SubscriptionPage,
    },
];
var planRoutes = [
    {
        path: AppRoutes.Plan,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: planRedirectPages }, { children: _jsx(PlanPage, {}) }))),
    },
    {
        path: AppRoutes.PlanRecipe,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: planRedirectPages }, { children: _jsx(RecipePage, { type: RecipeDetailsType.Plan }) }))),
    },
];
export default planRoutes;
