var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useGenerateRandomProfileColor, useUserProfileDetailFacade, } from '@planter/common';
import * as React from 'react';
import PlanterUserIcon from './icons/PlanterUserIcon';
var PlUserIcon = function (_a) {
    var personId = _a.personId, _b = _a.className, className = _b === void 0 ? '' : _b, iconSize = _a.iconSize, _c = _a.margin, margin = _c === void 0 ? '0px' : _c, _d = _a.marginTop, marginTop = _d === void 0 ? '0px' : _d, _e = _a.hasPrefix, hasPrefix = _e === void 0 ? false : _e;
    var profileImageUrl = useUserProfileDetailFacade(personId).profileImageUrl;
    var getPlColorHsl = useGenerateRandomProfileColor(personId).getPlColorHsl;
    var urlPrefix = hasPrefix ? process.env.REACT_APP_BASE_URL_CLOUDFRONT : '';
    return (_jsx("div", __assign({ className: "".concat(className) }, { children: profileImageUrl ? (_jsx("div", { className: "rounded-full", style: {
                backgroundImage: "url(".concat(urlPrefix).concat(profileImageUrl, ")"),
                backgroundPosition: 'right',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: iconSize ? iconSize + 'px' : '17.5px',
                width: iconSize ? iconSize + 'px' : '17.5px',
                margin: margin,
                marginTop: marginTop,
            } })) : (_jsx(PlanterUserIcon, { color: getPlColorHsl(), size: iconSize || '20' })) })));
};
export default PlUserIcon;
