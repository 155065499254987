export var filterCategoriesSelector = function (state) {
    return state.filters.categories;
};
export var filterFromWorldSelector = function (state) {
    return state.filters.fromWorld;
};
export var filterIngredientsSelector = function (state) {
    return state.filters.ingredients;
};
export var filterIsSeasonalSelector = function (state) {
    return state.filters.isSeasonal;
};
export var filterPreparationTimeSelector = function (state) {
    return state.filters.preparationTime;
};
export var selectedFiltersSelector = function (state) {
    return state.filters.selectedFilters;
};
export var filterIsFavouritesSelector = function (state) {
    return state.filters.isFavourites;
};
export var filterMacroNutrientsSelector = function (state) {
    return state.filters.macroNutrients;
};
