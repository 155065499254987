var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useMemo } from 'react';
import { NotImplementedStorage } from '../../services';
export var StorageContext = createContext({
    storage: new NotImplementedStorage(),
});
export var StorageProvider = function (_a) {
    var children = _a.children, StorageImplementation = _a.StorageImplementation, storageInstance = _a.storageInstance;
    var storage = useMemo(function () {
        if (storageInstance) {
            return storageInstance;
        }
        if (StorageImplementation) {
            return new StorageImplementation();
        }
        throw new Error('No instance found, should exist at least one between StorageImplementation or storageInstance');
    }, [StorageImplementation, storageInstance]);
    return (_jsx(StorageContext.Provider, __assign({ value: { storage: storage } }, { children: children })));
};
