var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
var GlutenFreeIcon = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? '#EEB436' : _b, size = _a.size, _c = _a.width, width = _c === void 0 ? 32 : _c, _d = _a.height, height = _d === void 0 ? 32 : _d;
    return (_jsxs("svg", __assign({ viewBox: "0 0 32 32", width: size !== null && size !== void 0 ? size : width, height: size !== null && size !== void 0 ? size : height, fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: [_jsxs("g", __assign({ clipPath: "url(#a)", stroke: color, strokeWidth: 1.6, strokeLinecap: "round", strokeLinejoin: "round" }, { children: [_jsx("path", { d: "M29.174 16.32c0 7.093-5.76 12.854-12.854 12.854-7.093 0-12.853-5.76-12.853-12.854 0-7.093 5.76-12.853 12.853-12.853s12.854 5.76 12.854 12.853Z", fill: "#fff" }), _jsx("path", { d: "M14.094 10.533c0 2.133 2.226 3.866 2.226 3.866s2.227-1.733 2.227-3.866c0-2.134-2.227-3.867-2.227-3.867s-2.226 1.733-2.226 3.867Z", fill: "#fff" }), _jsx("path", { d: "M17.534 13.16c-1.506 1.506-1.16 4.306-1.16 4.306s2.8.347 4.307-1.16c1.507-1.507 1.16-4.307 1.16-4.307s-2.8-.346-4.307 1.16Z", fill: "#fff" }), _jsx("path", { d: "M15.107 13.16c1.506 1.506 1.16 4.306 1.16 4.306s-2.8.347-4.307-1.16c-1.507-1.507-1.16-4.307-1.16-4.307s2.8-.346 4.307 1.16ZM17.534 18.228c-1.506 1.506-1.16 4.306-1.16 4.306s2.8.347 4.307-1.16c1.507-1.506 1.16-4.306 1.16-4.306s-2.8-.347-4.307 1.16Z", fill: "#fff" }), _jsx("path", { d: "M15.107 18.228c1.506 1.506 1.16 4.306 1.16 4.306s-2.8.347-4.307-1.16c-1.507-1.506-1.16-4.306-1.16-4.306s2.8-.347 4.307 1.16Z", fill: "#fff" }), _jsx("path", { d: "M16.32 14.414v11.56M11.987 11.987 7.24 7.24M25.414 25.414l-4.4-4.4M16.346 16.346l-.026-.026-.027-.027" })] })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "a" }, { children: _jsx("path", { fill: "#fff", transform: "translate(2.666 2.666)", d: "M0 0h27.307v27.307H0z" }) })) })] })));
};
export default GlutenFreeIcon;
