var CountriesRepository = /** @class */ (function () {
    function CountriesRepository(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        this.getCountries = function () {
            return _this.httpClient.get("/countries", {
                isAuthorized: true,
            });
        };
    }
    return CountriesRepository;
}());
export { CountriesRepository };
