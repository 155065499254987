export var CognitoError;
(function (CognitoError) {
    CognitoError["UsernameExists"] = "UsernameExistsException";
})(CognitoError || (CognitoError = {}));
export var PlanterError;
(function (PlanterError) {
    PlanterError[PlanterError["CodeNotFound"] = 1] = "CodeNotFound";
    PlanterError[PlanterError["CodeNotExist"] = 2] = "CodeNotExist";
    PlanterError[PlanterError["CodeAlreadyUsed"] = 3] = "CodeAlreadyUsed";
    PlanterError[PlanterError["UserNotFound"] = 4] = "UserNotFound";
    PlanterError[PlanterError["NotConfirmedEmail"] = 5] = "NotConfirmedEmail";
    PlanterError[PlanterError["ExceededMembers"] = 6] = "ExceededMembers";
    PlanterError[PlanterError["ChildIsTooYoung"] = 7] = "ChildIsTooYoung";
    PlanterError[PlanterError["ExceededAllergies"] = 8] = "ExceededAllergies";
    PlanterError[PlanterError["ExceededIngredients"] = 9] = "ExceededIngredients";
    PlanterError[PlanterError["TooYoung"] = 10] = "TooYoung";
    PlanterError[PlanterError["WeeklyRecipeNotFound"] = 11] = "WeeklyRecipeNotFound";
    PlanterError[PlanterError["CodeGenerationNotFamilyLeader"] = 12] = "CodeGenerationNotFamilyLeader";
    PlanterError[PlanterError["CodeGenerationImpossibleForFamilyLeader"] = 13] = "CodeGenerationImpossibleForFamilyLeader";
    PlanterError[PlanterError["CodeGenerationImpossibleForDifferentFamily"] = 14] = "CodeGenerationImpossibleForDifferentFamily";
    PlanterError[PlanterError["PlanInCreation"] = 18] = "PlanInCreation";
    PlanterError[PlanterError["EmailAlreadyExist"] = 19] = "EmailAlreadyExist";
    PlanterError[PlanterError["PlanNotDeleted"] = 20] = "PlanNotDeleted";
    PlanterError[PlanterError["BirthdayShouldBeGreaterThanThePreviousOne"] = 21] = "BirthdayShouldBeGreaterThanThePreviousOne";
})(PlanterError || (PlanterError = {}));
