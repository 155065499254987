import { RecipeDetailsType } from '@planter/common';
import { IsPlanSearchParamKey, MealTypeParamKey, RecipeDateParamKey, } from '../pages/recipeDetails/recipe.page';
export var RecipeChildren;
(function (RecipeChildren) {
})(RecipeChildren || (RecipeChildren = {}));
export var AppRoutes;
(function (AppRoutes) {
    AppRoutes["Base"] = "/";
    AppRoutes["Intro"] = "/intro";
    AppRoutes["Plan"] = "/plan";
    AppRoutes["NotFound"] = "/not-found";
    AppRoutes["JoinFamily"] = "/join-family";
    AppRoutes["Explore"] = "/explore";
    AppRoutes["List"] = "/list";
    AppRoutes["Ask"] = "/ask";
    AppRoutes["Account"] = "/account";
    AppRoutes["ProfilePage"] = "/profile";
    AppRoutes["Recipe"] = "/recipe/:recipeId";
    AppRoutes["RecipePreview"] = "/recipe/:recipeId/preview";
    AppRoutes["SearchResult"] = "/search-result";
    AppRoutes["PlanRecipe"] = "/plan/recipe/:recipeId";
    // ProfilePage
    AppRoutes["ProfileManagement"] = "/profile/management";
    AppRoutes["SubscriptionDetails"] = "/profile/subscription-details";
    AppRoutes["SubscriptionAnonymousDetails"] = "/anonymous/subscription-details";
    AppRoutes["FamilyMembers"] = "/profile/family-members";
    AppRoutes["FamilyMembersProfilePage"] = "/profile/family-members";
    AppRoutes["NotificationHistoryPage"] = "/profile/notification-history";
    AppRoutes["PlanInformation"] = "/profile/plan-information";
    AppRoutes["LanguageAndUnitOfMeasure"] = "/profile/language-and-unit-of-measure";
    // Favourites
    AppRoutes["FavouritesPage"] = "/favourites";
    // Maintenance
    AppRoutes["MaintenancePage"] = "/maintenance";
    // Onboarding
    AppRoutes["Onboarding"] = "/onbording";
    AppRoutes["OnboardingName"] = "/onboarding/name";
    AppRoutes["OnboardingBirthday"] = "/onboarding/birthday";
    AppRoutes["OnboardingGender"] = "/onboarding/gender";
    AppRoutes["OnboardingHeight"] = "/onboarding/height";
    AppRoutes["OnboardingWorkout"] = "/onboarding/workout";
    AppRoutes["OnboardingAllergies"] = "/onboarding/allergies";
    AppRoutes["OnboardingDislikedIngredientsPage"] = "/onboarding/dislikedingredients";
    AppRoutes["OnboardingWelcomePage"] = "/onboarding/welcome";
    AppRoutes["OnboardingProfilePage"] = "/onboarding/profile";
    // Subscription
    AppRoutes["SubscriptionPage"] = "/subscription";
    AppRoutes["CheckoutPage"] = "/checkout";
    // Onboarding Family Member
    AppRoutes["FamilyMemberName"] = "/family-member/name";
    AppRoutes["FamilyMemberBirthday"] = "/family-member/birthday";
    AppRoutes["FamilyMemberGender"] = "/family-member/gender";
    AppRoutes["FamilyMemberHeight"] = "/family-member/height";
    AppRoutes["FamilyMemberWorkout"] = "/family-member/workout";
    AppRoutes["FamilyMemberAllergies"] = "/family-member/allergies";
    AppRoutes["FamilyMemberDislikedIngredientsPage"] = "/family-member/dislikedingredients";
    AppRoutes["FamilyMemberGrowthSpeed"] = "/family-member/growth-speed";
    // Redeem coupon
    AppRoutes["RedeemCoupon"] = "/redeem-coupon";
    AppRoutes["CouponRedeemed"] = "/redeemed-coupon";
    AppRoutes["RedeemCouponForbidden"] = "/redeem-coupon-forbidden";
    AppRoutes["IntroRedeemCoupon"] = "/intro?code=%20";
})(AppRoutes || (AppRoutes = {}));
export var createRecipeRoute = function (recipeId, type, date, mealType, isPlanSearch) {
    if (type === void 0) { type = RecipeDetailsType.Explore; }
    if (type === RecipeDetailsType.Plan) {
        return "".concat(AppRoutes.PlanRecipe.replace(':recipeId', recipeId), "?").concat(RecipeDateParamKey, "=").concat(date, "&").concat(MealTypeParamKey, "=").concat(mealType);
    }
    else
        return "".concat(AppRoutes.Recipe.replace(':recipeId', recipeId)).concat(isPlanSearch ? "?".concat(IsPlanSearchParamKey, "=").concat(isPlanSearch) : '');
};
