export function getCurrencySymbol(currencyName) {
    switch (currencyName) {
        case 'eur':
            return '€';
        case 'usd':
            return '$';
        default:
            return '€';
    }
}
