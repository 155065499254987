var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { StorageKeys, } from '../../services';
import { isTokensResponse, } from '../../models';
import { randomPassword } from '../../utils';
var AuthRepository = /** @class */ (function () {
    function AuthRepository(httpClient, storage, userPoolWebClientId) {
        var _this = this;
        this.httpClient = httpClient;
        this.storage = storage;
        this.userPoolWebClientId = userPoolWebClientId;
        this.authConsumers = [];
        this.signIn = function (signInBody) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.post('/', {
                            body: {
                                AuthFlow: 'CUSTOM_AUTH',
                                ClientId: this.userPoolWebClientId,
                                AuthParameters: {
                                    USERNAME: signInBody.email,
                                },
                            },
                            headers: {
                                'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
                                'Content-Type': 'application/x-amz-json-1.1',
                            },
                            isAuthorized: false,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        this.signUp = function (signUpBody) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.post('/', {
                            body: {
                                ClientId: this.userPoolWebClientId,
                                Username: signUpBody.email,
                                Password: randomPassword(32),
                                UserAttributes: [
                                    { Name: 'preferred_username', Value: signUpBody.username },
                                ],
                                ValidationData: [],
                            },
                            headers: {
                                'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp',
                                'Content-Type': 'application/x-amz-json-1.1',
                            },
                            isAuthorized: false,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        this.verifyCode = function (verifyCodeBody) { return __awaiter(_this, void 0, void 0, function () {
            var verifyCodeResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.post('/', {
                            body: {
                                ClientId: this.userPoolWebClientId,
                                ChallengeName: 'CUSTOM_CHALLENGE',
                                Session: verifyCodeBody.Session,
                                ChallengeResponses: {
                                    USERNAME: verifyCodeBody.ChallengeResponses.USERNAME,
                                    ANSWER: verifyCodeBody.ChallengeResponses.ANSWER,
                                },
                            },
                            headers: {
                                'X-Amz-Target': 'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
                                'Content-Type': 'application/x-amz-json-1.1',
                            },
                        })];
                    case 1:
                        verifyCodeResponse = _a.sent();
                        if (!isTokensResponse(verifyCodeResponse)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.saveTokens(verifyCodeResponse)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, verifyCodeResponse];
                }
            });
        }); };
        this.refreshAccessToken = function (refreshToken) { return __awaiter(_this, void 0, Promise, function () {
            var refreshResponse, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 5]);
                        return [4 /*yield*/, this.httpClient.post('', {
                                body: {
                                    AuthParameters: { REFRESH_TOKEN: refreshToken },
                                    AuthFlow: 'REFRESH_TOKEN_AUTH',
                                    ClientId: this.userPoolWebClientId,
                                },
                                headers: {
                                    'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
                                    'Content-Type': 'application/x-amz-json-1.1',
                                },
                            })];
                    case 1:
                        refreshResponse = _a.sent();
                        return [4 /*yield*/, this.saveTokens(refreshResponse)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, refreshResponse];
                    case 3:
                        e_1 = _a.sent();
                        return [4 /*yield*/, this.revokeToken()];
                    case 4:
                        _a.sent();
                        throw e_1;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.addConsumer = function (authConsumer) {
            _this.authConsumers.push(authConsumer);
        };
        this.revokeToken = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.authConsumers.forEach(function (authConsumer) {
                            authConsumer.removeAuth();
                        }, []);
                        return [4 /*yield*/, this.storage.removeItem(StorageKeys.ACCESS_TOKEN_KEY)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.storage.removeItem(StorageKeys.REFRESH_TOKEN_KEY)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.saveTokens = function (refreshResponse) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((_a = refreshResponse.AuthenticationResult) === null || _a === void 0 ? void 0 : _a.IdToken)) {
                            return [2 /*return*/];
                        }
                        this.authConsumers.forEach(function (authConsumer) {
                            authConsumer.setupAuth(refreshResponse.AuthenticationResult.IdToken, refreshResponse.AuthenticationResult.RefreshToken);
                        }, []);
                        return [4 /*yield*/, this.storage.setItem(StorageKeys.ACCESS_TOKEN_KEY, refreshResponse.AuthenticationResult.IdToken)];
                    case 1:
                        _b.sent();
                        if (!refreshResponse.AuthenticationResult.RefreshToken) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.storage.setItem(StorageKeys.REFRESH_TOKEN_KEY, refreshResponse.AuthenticationResult.RefreshToken)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
    }
    return AuthRepository;
}());
export { AuthRepository };
