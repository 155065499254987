var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { DailyPlanRecipeType, DATE_PICKER_FORMAT, FamilyRole, } from '@planter/common';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export var usePlanMealsBuilderFacade = function (_a) {
    var user = _a.user, family = _a.family, familyPlan = _a.familyPlan, selectedDate = _a.selectedDate;
    var admin = useMemo(function () {
        return family === null || family === void 0 ? void 0 : family.Person.find(function (person) { return person.familyRole === FamilyRole.FAMILY_LEADER; });
    }, [family === null || family === void 0 ? void 0 : family.Person]);
    var getPersonRecipes = useCallback(function (people) {
        if (!people.length) {
            return [];
        }
        var getSelectedDatePlan = function (plan) {
            return plan.days.find(function (day) {
                var comparableDate = dayjs(day.date)
                    .utc()
                    .format(DATE_PICKER_FORMAT);
                return (comparableDate === dayjs(selectedDate).format(DATE_PICKER_FORMAT));
            });
        };
        return people.reduce(function (accPersonRecipes, currentPerson) {
            if (!currentPerson.Plan) {
                return accPersonRecipes;
            }
            var plan = currentPerson.Plan;
            var selectedDatePlan = getSelectedDatePlan(plan);
            if (!selectedDatePlan) {
                return accPersonRecipes;
            }
            var isAdmin = (admin === null || admin === void 0 ? void 0 : admin.id) === currentPerson.id;
            var hasGuest = currentPerson.familyRole === FamilyRole.FAMILY_GUEST;
            var personRecipes = selectedDatePlan.planRecipe.map(function (planRecipe) { return (__assign(__assign({}, planRecipe.recipe), { personId: currentPerson.id, type: planRecipe.type, isActive: planRecipe.isActive, hasAdmin: isAdmin, hasGuest: hasGuest })); });
            return __spreadArray(__spreadArray([], accPersonRecipes, true), personRecipes, true);
        }, []);
    }, [admin === null || admin === void 0 ? void 0 : admin.id, selectedDate]);
    var getFilteredAddedSharedRecipes = useCallback(function (recipes) {
        var isAdmin = (user === null || user === void 0 ? void 0 : user.familyRole) === FamilyRole.FAMILY_LEADER;
        var byMember = function (recipe) {
            return recipe.enabledPeopleIds.some(function (id) { return (user === null || user === void 0 ? void 0 : user.id) === id; });
        };
        return recipes.filter(function (recipe) {
            var isAddedType = recipe.type === DailyPlanRecipeType.ADDED;
            if (!isAddedType) {
                return recipe;
            }
            return isAdmin ? recipe : byMember(recipe);
        });
    }, [user === null || user === void 0 ? void 0 : user.familyRole, user === null || user === void 0 ? void 0 : user.id]);
    var getSharedPeopleRecipes = useCallback(function (personRecipes) {
        var peopleRecipes = personRecipes.reduce(function (accPeopleRecipes, currentPersonRecipe) {
            var accPeopleRecipe = accPeopleRecipes.find(function (peopleRecipe) {
                return peopleRecipe.id === currentPersonRecipe.id &&
                    peopleRecipe.type === currentPersonRecipe.type;
            });
            if (!accPeopleRecipe) {
                var isActive = currentPersonRecipe.isActive, hasGuest = currentPersonRecipe.hasGuest, otherCurrentPersonRecipeProperties = __rest(currentPersonRecipe, ["isActive", "hasGuest"]);
                var peopleRecipe = __assign(__assign({}, otherCurrentPersonRecipeProperties), { enabledPeopleIds: currentPersonRecipe.isActive && !hasGuest
                        ? [currentPersonRecipe.personId]
                        : [], disabledPeopleIds: !currentPersonRecipe.isActive && !hasGuest
                        ? [currentPersonRecipe.personId]
                        : [], guestsPeopleIds: hasGuest && currentPersonRecipe.isActive
                        ? [currentPersonRecipe.personId]
                        : [] });
                return __spreadArray(__spreadArray([], accPeopleRecipes, true), [peopleRecipe], false);
            }
            var otherAccPeopleRecipes = accPeopleRecipes.filter(function (peopleRecipe) {
                return peopleRecipe.id !== currentPersonRecipe.id ||
                    (peopleRecipe.id === currentPersonRecipe.id &&
                        peopleRecipe.type !== currentPersonRecipe.type);
            });
            var updatedAccPeopleRecipe = __assign(__assign({}, accPeopleRecipe), { hasAdmin: currentPersonRecipe.hasAdmin || accPeopleRecipe.hasAdmin, enabledPeopleIds: currentPersonRecipe.isActive && !currentPersonRecipe.hasGuest
                    ? __spreadArray(__spreadArray([], accPeopleRecipe.enabledPeopleIds, true), [
                        currentPersonRecipe.personId,
                    ], false) : accPeopleRecipe.enabledPeopleIds, disabledPeopleIds: !currentPersonRecipe.isActive && !currentPersonRecipe.hasGuest
                    ? __spreadArray(__spreadArray([], accPeopleRecipe.disabledPeopleIds, true), [
                        currentPersonRecipe.personId,
                    ], false) : accPeopleRecipe.disabledPeopleIds, guestsPeopleIds: currentPersonRecipe.hasGuest && currentPersonRecipe.isActive
                    ? __spreadArray(__spreadArray([], accPeopleRecipe.guestsPeopleIds, true), [
                        currentPersonRecipe.personId,
                    ], false) : [] });
            return __spreadArray(__spreadArray([], otherAccPeopleRecipes, true), [updatedAccPeopleRecipe], false);
        }, []);
        return getFilteredAddedSharedRecipes(peopleRecipes);
    }, [getFilteredAddedSharedRecipes]);
    var removeAddedMealWithDisabledCourses = useCallback(function (planRecipes) {
        return planRecipes.filter(function (planRecipe) {
            if (planRecipe.type !== DailyPlanRecipeType.ADDED) {
                return planRecipe;
            }
            var hasAllCoursesWithDisabledRecipes = planRecipe.courses.every(function (course) {
                return course.recipes.every(function (recipe) { return recipe.enabledPeopleIds.length === 0; });
            });
            return !hasAllCoursesWithDisabledRecipes;
        });
    }, []);
    var orderedCategoryIds = useMemo(function () { return [
        'CAT_A_BASE_DI_LATTE_VEGETALE',
        'CAT_BEVANDE',
        'CAT_BISCOTTI',
        'CAT_PORRIDGE_E_OVERNIGHT_OATMEAL',
        'CAT_MERENDE_E_COLAZIONI_SALATE',
        'CAT_MERENDE_E_COLAZIONI_DOLCI',
        'CAT_A_BASE_DI_YOGURT_VEGETALE',
        'CAT_PRIMI_PIATTI',
        'CAT_PASTA',
        'CAT_PASTA_AL_FORNO',
        'CAT_GNOCCHI',
        'CAT_RISOTTI',
        'CAT_BOWL',
        'CAT_ZUPPE_E_VELLUTATE',
        'CAT_A_BASE_DI_RISO_E_ALTRI_CEREALI',
        'CAT_INSALATE_DI_CEREALI',
        'CAT_INSALATE_DI_LEGUMI',
        'CAT_INSALATE_E_VERDURE_CRUDE',
        'CAT_PIATTI_A_BASE_DI_LEGUMI',
        'CAT_SECONDI_PIATTI',
        'CAT_SECONDI_A_BASE_DI_TEMPEH',
        'CAT_SECONDI_A_BASE_DI_TOFU',
        'CAT_SECONDI_A_BASE_DI_SEITAN',
        'CAT_SECONDI_A_BASE_DI_SOIA',
        'CAT_POLPETTE_E_BURGER',
        'CAT_COTOLETTE',
        'CAT_TORTE_SALATE',
        'CAT_FARINATE_E_FRITTATE',
        'CAT_PIADINE_E_WRAPS',
        'CAT_CONTORNI',
        'CAT_PANINI_E_BRUSCHETTE',
        'CAT_ENERGY_BALLS_E_BARRETTE',
        'CAT_VERDURE_IN_PADELLA',
        'CAT_VERDURE_BOLLITE_E_A_VAPORE',
        'CAT_PIZZE_E_FOCACCE',
        'CAT_VERDURE_AL_FORNO',
        'CAT_PATATE_E_PURÈ',
        'CAT_HUMMUS_E_PATÉ',
        'CAT_A_BASE_DI_PANE',
        'CAT_POLENTA',
        'CAT_PRODOTTI_DA_FORNO',
        'CAT_A_BASE_DI_PANE_E_FETTE_BISCOTTATE',
        'CAT_CRÊPES,_PIADINE_E_PANCAKE',
        'CAT_TORTE_E_ALTRI_DOLCI_DA_FORNO',
        'CAT_FRUTTA_SECCA',
        'CAT_FRUTTA_FRESCA_E_MACEDONIE',
        'CAT_GELATI',
        'CAT_FRULLATI',
    ]; }, []);
    var mappedCategoryIds = useMemo(function () {
        return new Map(orderedCategoryIds.map(function (categoryId, index) { return [categoryId, index]; }));
    }, [orderedCategoryIds]);
    var sortRecipesByCategory = useCallback(function (recipe1, recipe2) {
        var _a, _b;
        var index1 = mappedCategoryIds.get(((_a = recipe1 === null || recipe1 === void 0 ? void 0 : recipe1.category) === null || _a === void 0 ? void 0 : _a.id) || ''); // a
        var index2 = mappedCategoryIds.get(((_b = recipe2 === null || recipe2 === void 0 ? void 0 : recipe2.category) === null || _b === void 0 ? void 0 : _b.id) || ''); // b
        if (index1 !== undefined && index2 !== undefined) {
            return index1 - index2;
        }
        if (index1 !== undefined) {
            return -1;
        }
        if (index2 !== undefined) {
            return 1;
        }
        return 0;
    }, [mappedCategoryIds]);
    var sortCoursesByCategory = useCallback(function (course1, course2) {
        var _a, _b;
        var recipe1 = (_a = course1.recipes) === null || _a === void 0 ? void 0 : _a[0];
        var recipe2 = (_b = course2.recipes) === null || _b === void 0 ? void 0 : _b[0];
        return sortRecipesByCategory(recipe1, recipe2);
    }, [sortRecipesByCategory]);
    var getOrderedMeals = useCallback(function (meals) {
        var orderedMealTypes = [
            DailyPlanRecipeType.BREAKFAST,
            DailyPlanRecipeType.MORNING_SNACK,
            DailyPlanRecipeType.LUNCH,
            DailyPlanRecipeType.SNACK,
            DailyPlanRecipeType.DINNER,
            DailyPlanRecipeType.ADDED,
        ]
            .map(function (mealType) { return meals.find(function (meal) { return meal.type === mealType; }); })
            .filter(function (meal) { return !!meal; });
        for (var _i = 0, orderedMealTypes_1 = orderedMealTypes; _i < orderedMealTypes_1.length; _i++) {
            var item = orderedMealTypes_1[_i];
            for (var _a = 0, _b = item.courses; _a < _b.length; _a++) {
                var course = _b[_a];
                course.recipes.sort(sortRecipesByCategory);
            }
            item === null || item === void 0 ? void 0 : item.courses.sort(sortCoursesByCategory);
        }
        return orderedMealTypes;
    }, [sortCoursesByCategory, sortRecipesByCategory]);
    var meals = useMemo(function () {
        if (!user || !familyPlan) {
            return [];
        }
        var personRecipes = getPersonRecipes(familyPlan.Person);
        var sharedPeopleRecipes = getSharedPeopleRecipes(personRecipes);
        var planRecipes = sharedPeopleRecipes.reduce(function (accMeals, currentSharedRecipe) {
            var accMeal = accMeals.find(function (meal) { return meal.type === currentSharedRecipe.type; });
            /**
             * If the @accMeal doesn't exist yet, create it with the first course matching the @currentSharedRecipe details
             * and store it.
             */
            if (!accMeal) {
                var course = {
                    peopleIds: __spreadArray(__spreadArray([], currentSharedRecipe.enabledPeopleIds, true), currentSharedRecipe.disabledPeopleIds, true),
                    guestsPeopleIds: __spreadArray([], currentSharedRecipe.guestsPeopleIds, true),
                    recipes: [currentSharedRecipe],
                };
                var meal = {
                    type: currentSharedRecipe.type,
                    courses: [course],
                };
                return __spreadArray(__spreadArray([], accMeals, true), [meal], false);
            }
            var otherAccMeals = accMeals.filter(function (meal) { return meal.type !== accMeal.type; });
            /**
             * If the @currentSharedRecipe is eaten by different people than the other courses, then create a new course and
             * store it in the same meal.
             */
            var sharedCourse = accMeal.courses.find(function (course) {
                /*
                   Check if all people eating this course are the same of the current shared recipe.
                */
                var hasSameEnabledAndDisabledPeople = course.peopleIds.toString() ===
                    __spreadArray(__spreadArray([], currentSharedRecipe.enabledPeopleIds, true), currentSharedRecipe.disabledPeopleIds, true).toString();
                /*
                    This current shared recipe isn't eaten by any guests, so we check whether the
                    current shared recipe same enabledPeople or disabledPeople are included
                    in the course people.
                   */
                if (!currentSharedRecipe.guestsPeopleIds.length) {
                    return hasSameEnabledAndDisabledPeople;
                }
                /*
                    This current shared recipe has guests eating it, so we check if the course
                    and the current shared recipe have the same guests eating it.
                   */
                return (hasSameEnabledAndDisabledPeople &&
                    course.guestsPeopleIds.toString() ===
                        currentSharedRecipe.guestsPeopleIds.toString());
            });
            if (!sharedCourse) {
                var course = {
                    peopleIds: __spreadArray(__spreadArray([], currentSharedRecipe.enabledPeopleIds, true), currentSharedRecipe.disabledPeopleIds, true),
                    guestsPeopleIds: __spreadArray([], currentSharedRecipe.guestsPeopleIds, true),
                    recipes: [currentSharedRecipe],
                };
                var updatedAccMeal = __assign(__assign({}, accMeal), { courses: __spreadArray(__spreadArray([], accMeal.courses, true), [course], false) });
                return __spreadArray(__spreadArray([], otherAccMeals, true), [updatedAccMeal], false);
            }
            /**
             * The @currentSharedRecipe is eaten by the same people in a course of the current @accMeal, so update
             * the @sharedCourse with this @currentSharedRecipe details and store it.
             */
            var accMealCourse = __assign(__assign({}, sharedCourse), { recipes: __spreadArray(__spreadArray([], sharedCourse.recipes, true), [currentSharedRecipe], false) });
            var otherAccMealCourses = accMeal.courses.filter(function (course) {
                return course.peopleIds.toString() !==
                    __spreadArray(__spreadArray([], currentSharedRecipe.enabledPeopleIds, true), currentSharedRecipe.disabledPeopleIds, true).toString();
            });
            var updatedSharedAccMeal = __assign(__assign({}, accMeal), { courses: __spreadArray(__spreadArray([], otherAccMealCourses, true), [accMealCourse], false) });
            return __spreadArray(__spreadArray([], otherAccMeals, true), [updatedSharedAccMeal], false);
        }, []);
        var filteredMeals = removeAddedMealWithDisabledCourses(planRecipes);
        return getOrderedMeals(filteredMeals);
    }, [
        user,
        familyPlan,
        getPersonRecipes,
        getSharedPeopleRecipes,
        removeAddedMealWithDisabledCourses,
        getOrderedMeals,
    ]);
    var areAllPeopleDisabledAndEnabled = useCallback(function (mealType, checkEnabled) {
        var mealItems = meals.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.type) === mealType; });
        var arePeopleDisabledOrEnabled = function (recipe) {
            return checkEnabled
                ? recipe.disabledPeopleIds.length === 0
                : recipe.enabledPeopleIds.length === 0;
        };
        return mealItems.every(function (item) {
            return item === null || item === void 0 ? void 0 : item.courses.every(function (course) {
                return course.recipes.some(arePeopleDisabledOrEnabled);
            });
        });
    }, [meals]);
    return {
        meals: meals,
        areAllPeopleDisabledAndEnabled: areAllPeopleDisabledAndEnabled,
    };
};
