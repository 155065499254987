var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
export var SidebarPosition;
(function (SidebarPosition) {
    SidebarPosition["Right"] = "right";
    SidebarPosition["Left"] = "left";
})(SidebarPosition || (SidebarPosition = {}));
var PlBaseSidebar = function (_a) {
    var isOpen = _a.isOpen, children = _a.children, onCloseMenu = _a.onCloseMenu, _b = _a.sidebarPosition, sidebarPosition = _b === void 0 ? SidebarPosition.Right : _b;
    var _c = React.useState(false), isOverlayVisible = _c[0], setIsOverlayVisible = _c[1];
    var sidebarPositionClass = React.useMemo(function () {
        if (sidebarPosition === SidebarPosition.Right)
            return 'right-0';
        return 'left-0';
    }, [sidebarPosition]);
    React.useEffect(function () {
        var timeoutRef = undefined;
        if (!isOpen) {
            timeoutRef = setTimeout(function () {
                setIsOverlayVisible(false);
            }, 300);
        }
        else {
            if (timeoutRef)
                clearTimeout(timeoutRef);
            setIsOverlayVisible(true);
        }
        return function () { return clearTimeout(timeoutRef); };
    }, [isOpen]);
    var menuClasses = isOpen
        ? 'md:max-w-[33%] max-w-full md:min-w-[525px] min-w-full'
        : 'max-w-0 min-w-0';
    var overlayOpacityClass = isOpen ? 'opacity-50' : 'opacity-0';
    var overlayVisibleClass = isOverlayVisible ? 'visible' : 'hidden';
    return (_jsxs(_Fragment, { children: [_jsx("div", { onClick: onCloseMenu, className: "fixed z-[55] bg-black inset-0 transition-opacity duration-300 ".concat(overlayOpacityClass, " ").concat(overlayVisibleClass) }), _jsx("div", __assign({ className: "fixed w-full z-[60] transition-[max-width,min-width] duration-300 top-0 bottom-0 overflow-x-hidden bg-white ".concat(sidebarPositionClass, " ").concat(menuClasses) }, { children: _jsx("div", __assign({ className: "w-screen" }, { children: _jsx("div", __assign({ className: "md:max-w-[33%] max-w-full md:min-w-[525px] min-w-full" }, { children: children })) })) }))] }));
};
export default PlBaseSidebar;
