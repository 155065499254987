import { useSearchFilters } from './useSearchFilters';
import { useLocalCategoriesSelection } from './useLocalCategoriesSelection';
export var useFiltersFromWorldSectionFacade = function () {
    var _a = useSearchFilters(), selectedFromWorldFilter = _a.selectedFromWorldFilter, fromWorldFilters = _a.fromWorldFilters, setFilterFromWorld = _a.setFilterFromWorld;
    var results = useLocalCategoriesSelection({
        allCategories: fromWorldFilters,
        selectedCategoriesFilter: selectedFromWorldFilter,
        setFilterCategories: setFilterFromWorld,
    });
    return results;
};
