export var UserGenders;
(function (UserGenders) {
    UserGenders["M"] = "M";
    UserGenders["F"] = "F";
    UserGenders["MtoF"] = "M_TO_F";
    UserGenders["FtoM"] = "F_TO_M";
    UserGenders["Unknown"] = "NONE";
})(UserGenders || (UserGenders = {}));
export var CommonAllergy;
(function (CommonAllergy) {
    CommonAllergy["Gluten"] = "GLUTEN";
    CommonAllergy["Soy"] = "SOY";
    CommonAllergy["DriedFruit"] = "DRIED_FRUIT";
})(CommonAllergy || (CommonAllergy = {}));
export var GrowthSpeed;
(function (GrowthSpeed) {
    GrowthSpeed["Under"] = "UNDER";
    GrowthSpeed["Regular"] = "REGULAR";
    GrowthSpeed["Over"] = "OVER";
})(GrowthSpeed || (GrowthSpeed = {}));
