var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { useLanguage } from '../../locale';
import { UserGenders } from '../../models';
import { useGetPersonApi } from '../../hooks';
import { useGetCustomerApi } from '../../hooks';
import { useFamilyMemberComposer } from './useFamilyMemberComposer.facade';
import { useSelector } from 'react-redux';
import { isFamilyMemberRegisterRequested } from '../../redux';
import { useUpdateProfileHelper } from './helpers/useUpdateProfileHelper';
export var useInputGenderFormFacade = function (_a) {
    var onOpenExpandedOptionsPress = _a.onOpenExpandedOptionsPress, onNext = _a.onNext, personId = _a.personId, _b = _a.isFamilyMemberUpdate, isFamilyMemberUpdate = _b === void 0 ? false : _b;
    //** REDUX **//
    var isNewFamilyMember = useSelector(isFamilyMemberRegisterRequested);
    var isFamilyMember = isNewFamilyMember || isFamilyMemberUpdate;
    var t = useLanguage()[0];
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState([]), selectedOptions = _d[0], setSelectedOptions = _d[1];
    //** API **//
    var customer = useGetCustomerApi(!isFamilyMember).data;
    var person = useGetPersonApi(personId || (customer === null || customer === void 0 ? void 0 : customer.personId)).data;
    var updateProfile = useUpdateProfileHelper({
        isFamilyMember: isNewFamilyMember,
        person: person,
        customer: customer,
    }).updateProfile;
    var profile = useFamilyMemberComposer().profile;
    var genderLabel = selectedOptions.map(function (gender) { return gender.value; }).toString();
    //** DATA **//
    var expandedOptions = useMemo(function () { return [
        {
            type: 'default',
            label: t('memberRegistry.inputGenderExpandableFirstOptionLabel'),
            value: UserGenders.FtoM,
            linkedTo: 'other',
        },
        {
            type: 'default',
            label: t('memberRegistry.inputGenderExpandableSecondOptionLabel'),
            value: UserGenders.MtoF,
            linkedTo: 'other',
        },
        {
            type: 'default',
            label: !isFamilyMember
                ? t('memberRegistry.inputGenderExpandableThirdOptionLabel')
                : t('memberRegistry.inputGenderExpandableThirdOptionLabelFamilyMember'),
            value: UserGenders.Unknown,
            linkedTo: 'other',
        },
    ]; }, [isFamilyMember, t]);
    var options = useMemo(function () {
        return [
            {
                type: 'default',
                label: t('memberRegistry.inputGenderFirstOptionLabel'),
                value: UserGenders.M,
            },
            {
                type: 'default',
                label: t('memberRegistry.inputGenderSecondOptionLabel'),
                value: UserGenders.F,
            },
            {
                type: 'default',
                label: t('memberRegistry.inputGenderThirdOptionLabel'),
                value: 'other',
                linked: true,
            },
        ];
    }, [t]);
    //** CALLBACK **//
    var onExpandableOptionPress = useCallback(function () {
        return onOpenExpandedOptionsPress &&
            onOpenExpandedOptionsPress({
                options: expandedOptions,
                selectedOptions: selectedOptions,
                onSelectedOptionsChange: setSelectedOptions,
            });
    }, [expandedOptions, onOpenExpandedOptionsPress, selectedOptions]);
    var onSelectedOptionsChange = useCallback(function (options) {
        if (errorMessage) {
            setErrorMessage(undefined);
        }
        setSelectedOptions(options);
    }, [errorMessage]);
    var handleSubmit = useCallback(function (onSubmit) {
        if (!selectedOptions.length) {
            return function () {
                return setErrorMessage(t('memberRegistry.inputGenderRequiredError'));
            };
        }
        if (errorMessage)
            setErrorMessage(undefined);
        return onSubmit;
    }, [selectedOptions.length, t, errorMessage]);
    var updatePersonGender = useMemo(function () {
        return handleSubmit(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateProfile({ gender: genderLabel })];
                    case 1:
                        _a.sent();
                        onNext === null || onNext === void 0 ? void 0 : onNext();
                        return [2 /*return*/];
                }
            });
        }); });
    }, [handleSubmit, updateProfile, genderLabel, onNext]);
    var defaultValue = useMemo(function () {
        if (isNewFamilyMember) {
            return profile.gender;
        }
        return person === null || person === void 0 ? void 0 : person.gender;
    }, [isNewFamilyMember, person === null || person === void 0 ? void 0 : person.gender, profile.gender]);
    useEffect(function () {
        if (!defaultValue) {
            return;
        }
        setSelectedOptions(function (current) {
            var selectedOption = options.find(function (option) { return option.value === defaultValue; });
            if (selectedOption) {
                return [selectedOption];
            }
            var selectedExpandedOption = expandedOptions.find(function (option) { return option.value === defaultValue; });
            if (selectedExpandedOption) {
                return [selectedExpandedOption];
            }
            return current;
        });
    }, [defaultValue, expandedOptions, options]);
    return {
        selectedOptions: selectedOptions,
        setSelectedOptions: onSelectedOptionsChange,
        options: options,
        errorMessage: errorMessage,
        onExpandableOptionPress: onExpandableOptionPress,
        updatePersonGender: updatePersonGender,
        isFamilyMember: isFamilyMember,
    };
};
