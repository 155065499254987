var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var IdeaIcon = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? '#FF9500' : _b, height = _a.height, size = _a.size, width = _a.width;
    return (_jsx("svg", __assign({ viewBox: "0 0 24 24", width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: _jsx("path", { d: "M16.41 3.57a7 7 0 0 0-9.95 9.7A2.94 2.94 0 0 1 7 15.05V17a1 1 0 0 0 1 1v2a1.87 1.87 0 0 0 1.75 2h4.5A1.87 1.87 0 0 0 16 20v-2a1 1 0 0 0 1-1v-2a2.85 2.85 0 0 1 .54-1.75 7 7 0 0 0-1.13-9.71v.03ZM14 20h-4v-2h4v2Zm1.92-7.95c-.666.857-.938 1.925-.92 3.01v1h-2V11a1 1 0 0 0-2 0v5H9v-.95a4.85 4.85 0 0 0-1-3 5 5 0 1 1 7.92 0Z", fill: color }) })));
};
export default IdeaIcon;
