var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var familyMemberRedirectPages = [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.NoCountryUser,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.UserPaymentNeeded,
        page: AppRoutes.SubscriptionPage,
    },
    {
        userRole: UserRole.CompleteUser,
        page: AppRoutes.Plan,
    },
];
var NamePage = React.lazy(function () { return import('../pages/onboarding/name.page'); });
var BirthdayPage = React.lazy(function () { return import('../pages/onboarding/birthday.page'); });
var GenderPage = React.lazy(function () { return import('../pages/onboarding/gender.page'); });
var HeightPage = React.lazy(function () { return import('../pages/onboarding/height.page'); });
var WorkoutPage = React.lazy(function () { return import('../pages/onboarding/workout.page'); });
var AllergiesPage = React.lazy(function () { return import('../pages/onboarding/allergies.page'); });
var GrowthSpeedPage = React.lazy(function () { return import('../pages/onboarding/growthSpeed.page'); });
var DislikedIngredientsPage = React.lazy(function () { return import('../pages/onboarding/dislikedIngredients.page'); });
var familyMemberRoutes = [
    {
        path: AppRoutes.Base,
        element: _jsx(Navigate, { to: AppRoutes.Intro, replace: true }),
    },
    {
        path: AppRoutes.FamilyMemberName,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(NamePage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberBirthday,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(BirthdayPage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberGender,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(GenderPage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberHeight,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(HeightPage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberWorkout,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(WorkoutPage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberAllergies,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(AllergiesPage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberDislikedIngredientsPage,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(DislikedIngredientsPage, {}) }))),
    },
    {
        path: AppRoutes.FamilyMemberGrowthSpeed,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PlanPendingUser], redirectPages: familyMemberRedirectPages }, { children: _jsx(GrowthSpeedPage, {}) }))),
    },
];
export default familyMemberRoutes;
