import { useGetFeaturedContentsApi, useGetSeasonalRecipesApi, useGetSundayRecipesApi, useGetWeeklyRecipeApi, } from '../../hooks';
import { LoaderType } from "../../hooks/api/utils";
import { useMemo } from "react";
export var useExploreFacade = function (loaderType) {
    if (loaderType === void 0) { loaderType = LoaderType.FullPage; }
    //** API **//
    var _a = useGetFeaturedContentsApi(loaderType), featuredContents = _a.data, isLoadingFeatured = _a.isLoading;
    var _b = useGetSeasonalRecipesApi(loaderType), seasonalRecipes = _b.data, isLoadingSeasonal = _b.isLoading;
    var _c = useGetWeeklyRecipeApi(loaderType), weeklyRecipe = _c.data, isLoadingWeekly = _c.isLoading;
    var _d = useGetSundayRecipesApi(loaderType), sundayRecipes = _d.data, isLoadingSunday = _d.isLoading;
    var featuredFulfill = useMemo(function () {
        return featuredContents === null || featuredContents === void 0 ? void 0 : featuredContents.filter(function (content) { return content.title.length > 0; });
    }, [featuredContents]);
    var seasonalFulfill = useMemo(function () {
        return seasonalRecipes === null || seasonalRecipes === void 0 ? void 0 : seasonalRecipes.filter(function (seasonal) { return seasonal.name.length > 0; });
    }, [seasonalRecipes]);
    var sundayFulfill = useMemo(function () {
        return sundayRecipes === null || sundayRecipes === void 0 ? void 0 : sundayRecipes.filter(function (recipe) { return recipe.name.length > 0; });
    }, [sundayRecipes]);
    var isFeaturedContentsNotPresent = !featuredContents && !isLoadingFeatured;
    var isSeasonalRecipesNotPresent = !seasonalRecipes && !isLoadingSeasonal;
    var isWeeklyRecipeNotPresent = !weeklyRecipe && !isLoadingWeekly;
    var isSundayRecipesNotPresent = !sundayRecipes && !isLoadingSunday;
    return {
        featuredContents: featuredContents,
        seasonalRecipes: seasonalRecipes,
        weeklyRecipe: weeklyRecipe,
        sundayRecipes: sundayRecipes,
        isLoadingFeatured: isLoadingFeatured,
        isLoadingSeasonal: isLoadingSeasonal,
        isLoadingWeekly: isLoadingWeekly,
        isLoadingSunday: isLoadingSunday,
        isSeasonalRecipesNotPresent: isSeasonalRecipesNotPresent,
        isFeaturedContentsNotPresent: isFeaturedContentsNotPresent,
        isWeeklyRecipeNotPresent: isWeeklyRecipeNotPresent,
        isSundayRecipesNotPresent: isSundayRecipesNotPresent,
        featuredFulfill: featuredFulfill,
        seasonalFulfill: seasonalFulfill,
        sundayFulfill: sundayFulfill,
    };
};
