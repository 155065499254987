import { createSlice } from '@reduxjs/toolkit';
import { addFullPageLoaderHandler, addLoaderHandler, removeLoaderHandler, removeFullPageLoaderHandler, setIsAuthorizedHandler, setIsInitializedHandler, setIsInErrorHandler, resetGeneralToDefaultHandler, setIsWalkThroughCompletedHandler, setIsCountryCompletedHandler, setIsRedeemCouponHandler, setIsMemberRegistryCompletedHandler, setIsFamilyMemberRegisterRequestedHandler, setIsFamilyMemberMinorHandler, setIsPlanCreated, setMembersColors, setIsFirstSignUpHandler, } from './general.reducer';
export var generalInitialState = {
    fullPageLoaders: [],
    isInError: false,
    loaders: [],
    isInitialized: false,
    isAuthorized: undefined,
    isCountryCompleted: undefined,
    redeemCoupon: undefined,
    isMemberRegistryCompleted: undefined,
    isWalkThroughCompleted: undefined,
    isFamilyMemberRegisterRequested: false,
    isFamilyMemberMinor: false,
    isPlanCreated: false,
    membersColors: {},
    isFirstSignUp: false,
};
var GENERAL_SLICE = 'generalSlice';
var generalSlice = createSlice({
    name: GENERAL_SLICE,
    initialState: generalInitialState,
    reducers: {
        addFullPageLoader: addFullPageLoaderHandler,
        removeFullPageLoader: removeFullPageLoaderHandler,
        addLoader: addLoaderHandler,
        removeLoader: removeLoaderHandler,
        setIsAuthorized: setIsAuthorizedHandler,
        setIsInitialized: setIsInitializedHandler,
        setIsInError: setIsInErrorHandler,
        resetGeneralToDefault: resetGeneralToDefaultHandler,
        setIsWalkThroughCompleted: setIsWalkThroughCompletedHandler,
        setIsCountryCompleted: setIsCountryCompletedHandler,
        setIsRedeemCoupon: setIsRedeemCouponHandler,
        setIsMemberRegistryCompleted: setIsMemberRegistryCompletedHandler,
        setIsFamilyMemberRegisterRequested: setIsFamilyMemberRegisterRequestedHandler,
        setIsFamilyMemberMinor: setIsFamilyMemberMinorHandler,
        setIsPlanCreated: setIsPlanCreated,
        setMemberColors: setMembersColors,
        setIsFirstSignUp: setIsFirstSignUpHandler,
    },
});
export var GeneralActions = generalSlice.actions;
export var generalReducer = generalSlice.reducer;
