var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var MaintenancePage = React.lazy(function () { return import('../pages/maintenance/maintenance.page'); });
var maintenanceRedirectPages = [];
var userRolesArray = Object.values(UserRole);
var maintenanceRoutes = [
    {
        path: AppRoutes.MaintenancePage,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: userRolesArray, redirectPages: maintenanceRedirectPages }, { children: _jsx(MaintenancePage, {}) }))),
    },
];
export default maintenanceRoutes;
