import { useSearchFilters } from './useSearchFilters';
import { useLocalCategoriesSelection } from './useLocalCategoriesSelection';
import * as React from 'react';
import { useLanguage } from '../../locale';
export var useMacroNutrientsSectionFacade = function () {
    var t = useLanguage()[0];
    var _a = useSearchFilters(), selectedMacroNutrientsFilter = _a.selectedMacroNutrientsFilter, setFilterMacroNutrients = _a.setFilterMacroNutrients;
    var macroNutrients = React.useMemo(function () {
        return [
            {
                id: 'cereals',
                parentId: null,
                name: t('recipeDetail.cereals'),
                child: [],
            },
            {
                id: 'proteinFoods',
                parentId: null,
                name: t('recipeDetail.proteicFood'),
                child: [],
            },
            {
                id: 'vegetables',
                parentId: null,
                name: t('recipeDetail.vegetables'),
                child: [],
            },
            {
                id: 'fruit',
                parentId: null,
                name: t('recipeDetail.fruits'),
                child: [],
            },
            {
                id: 'goodFats',
                parentId: null,
                name: t('recipeDetail.vegetableFats'),
                child: [],
            },
        ];
    }, [t]);
    var results = useLocalCategoriesSelection({
        allCategories: macroNutrients,
        selectedCategoriesFilter: selectedMacroNutrientsFilter,
        setFilterCategories: setFilterMacroNutrients,
    });
    return results;
};
