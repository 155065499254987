import { useCallback, useMemo, useState } from 'react';
import { useGetCountriesApi } from '../../hooks/api/countries/useGetCountries.api';
export var useScrollListCountryFacade = function (_a) {
    var navigateBack = _a.navigateBack, selectedCountry = _a.selectedCountry, onConfirmSelection = _a.onConfirmSelection;
    var _b = useState(selectedCountry), selectedItems = _b[0], setSelectedItems = _b[1];
    var _c = useState(), isSelected = _c[0], setIsSelected = _c[1];
    var _d = useState(''), searchText = _d[0], setSearchText = _d[1];
    //** API **//
    var countries = useGetCountriesApi().data;
    var filteredCountries = useMemo(function () {
        if (!searchText) {
            return countries;
        }
        return countries === null || countries === void 0 ? void 0 : countries.filter(function (country) { return country.name.startsWith(searchText); });
    }, [countries, searchText]);
    var resetSearchText = useCallback(function () {
        setSearchText('');
    }, []);
    // ** CALLBACKS ** //
    var onConfirmPress = useCallback(function () {
        if (!selectedItems) {
            return;
        }
        onConfirmSelection === null || onConfirmSelection === void 0 ? void 0 : onConfirmSelection(selectedItems);
        navigateBack === null || navigateBack === void 0 ? void 0 : navigateBack();
    }, [navigateBack, onConfirmSelection, selectedItems]);
    var onItemPress = useCallback(function (item) {
        var valueToSet = countries === null || countries === void 0 ? void 0 : countries.find(function (country) { return country.name === item; });
        setSelectedItems(valueToSet);
        setIsSelected(item);
    }, [countries]);
    return {
        selectedItems: selectedItems,
        setSelectedItems: setSelectedItems,
        countries: countries,
        onConfirmPress: onConfirmPress,
        onItemPress: onItemPress,
        isSelected: isSelected,
        filteredCountries: filteredCountries,
        setSearchText: setSearchText,
        searchText: searchText,
        resetSearchText: resetSearchText,
    };
};
