var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var ExclamationMarkIcon = function (_a) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? '#fff' : _b, size = _a.size, _c = _a.width, width = _c === void 0 ? 18 : _c, _d = _a.height, height = _d === void 0 ? 18 : _d;
    return (_jsx("svg", __assign({ viewBox: "0 0 18 18", width: size !== null && size !== void 0 ? size : width, height: size !== null && size !== void 0 ? size : height, fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: _jsx("path", { d: "M9.001.666a8.333 8.333 0 1 0 0 16.667 8.333 8.333 0 0 0 0-16.667Zm-.833 3.333a.833.833 0 0 1 1.667 0v5a.833.833 0 0 1-1.667 0V4Zm.833 10a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z", fill: color }) })));
};
export default ExclamationMarkIcon;
