var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import PlanterBrandLogo from '../icons/PlanterBrandLogo';
import PlLayoutAction from './PlLayoutAction';
var SmallLayout = function (_a) {
    var children = _a.children, header = _a.header, leftAction = _a.leftAction, rightAction = _a.rightAction, _b = _a.brandLogo, brandLogo = _b === void 0 ? true : _b, _c = _a.className, className = _c === void 0 ? '' : _c;
    return (_jsxs("div", __assign({ className: "max-w-screen-lg px-5 mx-auto ".concat(className) }, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx("div", __assign({ className: "flex-1 md:hidden block" }, { children: leftAction && _jsx(PlLayoutAction, __assign({}, leftAction)) })), _jsx("div", __assign({ className: "py-6" }, { children: brandLogo && _jsx(PlanterBrandLogo, {}) })), _jsx("div", __assign({ className: "flex-1 justify-end md:hidden flex" }, { children: rightAction && _jsx(PlLayoutAction, __assign({}, rightAction)) }))] })), header, _jsxs("div", __assign({ className: "flex w-full" }, { children: [_jsx("div", __assign({ className: "flex-1 md:block hidden" }, { children: leftAction && _jsx(PlLayoutAction, __assign({}, leftAction)) })), _jsx("div", __assign({ className: "flex-[2]" }, { children: children })), _jsx("div", __assign({ className: "flex-1 md:block hidden" }, { children: rightAction && _jsx(PlLayoutAction, __assign({}, rightAction)) }))] }))] })));
};
export default SmallLayout;
