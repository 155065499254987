var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useCallback, useState } from 'react';
import { isSomethingLoading } from '../../redux/general/general.selectors';
import { useSelector } from 'react-redux';
export var LoaderContext = createContext({
    open: function () {
        throw new Error('No open method assigned to the provider');
    },
    close: function () {
        throw new Error('No close method assigned to the provider');
    },
});
export var LoaderProvider = function (_a) {
    var children = _a.children, Loader = _a.Loader;
    var isFullPageLoaderLoading = useSelector(isSomethingLoading);
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var open = useCallback(function () {
        setIsLoading(true);
    }, []);
    var close = useCallback(function () {
        setIsLoading(false);
    }, []);
    return (_jsxs(LoaderContext.Provider, __assign({ value: {
            open: open,
            close: close,
        } }, { children: [children, isLoading || isFullPageLoaderLoading ? _jsx(Loader, {}) : null] })));
};
