import * as React from 'react';
import { useLanguage } from '../../locale';
import { useSearchFilters } from './useSearchFilters';
export var useSeasonalFilterSectionFacade = function () {
    var t = useLanguage()[0];
    var _a = React.useState(), seasonalFilterOption = _a[0], setSeasonalFilterOption = _a[1];
    var _b = useSearchFilters(), setIsSeasonalToFilter = _b.setIsSeasonalToFilter, selectedIsSeasonalFilter = _b.selectedIsSeasonalFilter;
    var options = React.useMemo(function () {
        return [
            {
                type: 'default',
                label: "".concat(t('yes')),
                value: true,
            },
            {
                type: 'default',
                label: "".concat(t('filters.indifferent')),
                value: false,
            },
        ];
    }, [t]);
    React.useEffect(function () {
        if (selectedIsSeasonalFilter !== null) {
            var prepTimeOptionToSet = options.find(function (o) { return o.value === selectedIsSeasonalFilter; });
            setSeasonalFilterOption(prepTimeOptionToSet !== null && prepTimeOptionToSet !== void 0 ? prepTimeOptionToSet : null);
        }
    }, [options, selectedIsSeasonalFilter]);
    var reset = React.useCallback(function () {
        setSeasonalFilterOption(null);
    }, []);
    var applyFilter = React.useCallback(function () {
        var _a;
        setIsSeasonalToFilter((_a = seasonalFilterOption === null || seasonalFilterOption === void 0 ? void 0 : seasonalFilterOption.value) !== null && _a !== void 0 ? _a : null);
    }, [seasonalFilterOption, setIsSeasonalToFilter]);
    return {
        options: options,
        seasonalFilterOption: seasonalFilterOption,
        setSeasonalFilterOption: setSeasonalFilterOption,
        reset: reset,
        applyFilter: applyFilter,
    };
};
