import { useGeneratePlanApi, useGetCodeApi, useGetCustomerApi, useGetFamilyApi, useGetPersonApi, } from '../../hooks';
import { useCallback, useMemo } from 'react';
import { FamilyRole } from '../../models';
import { LoaderType } from '../../hooks/api/utils';
import { isMinor } from '../../utils';
var FAMILY_COMPONENTS = 6;
export var useAddComponentsFacade = function (loaderType) {
    var _a;
    if (loaderType === void 0) { loaderType = LoaderType.FullPage; }
    var customer = useGetCustomerApi(true, loaderType).data;
    var personLeader = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId, loaderType).data;
    var _b = useGetFamilyApi(personLeader === null || personLeader === void 0 ? void 0 : personLeader.familyId, !!personLeader, loaderType), family = _b.data, refetchFamily = _b.refetch, isLoading = _b.isLoading;
    var mutateGeneratePlan = useGeneratePlanApi().mutate;
    //** DATA **//
    var generatePlan = useCallback(function () { return mutateGeneratePlan(family === null || family === void 0 ? void 0 : family.id); }, [family === null || family === void 0 ? void 0 : family.id, mutateGeneratePlan]);
    var membersByUser = useMemo(function () {
        var user = family === null || family === void 0 ? void 0 : family.Person.find(function (person) { return person.id === (personLeader === null || personLeader === void 0 ? void 0 : personLeader.id); });
        var members = family === null || family === void 0 ? void 0 : family.Person.filter(function (person) { return person.id !== (personLeader === null || personLeader === void 0 ? void 0 : personLeader.id); });
        return {
            user: user,
            members: members,
        };
    }, [family === null || family === void 0 ? void 0 : family.Person, personLeader === null || personLeader === void 0 ? void 0 : personLeader.id]);
    var othersMembers = useMemo(function () {
        if (!family) {
            return;
        }
        return family.Person.filter(function (item) { return item.id && item.familyRole === FamilyRole.FAMILY_MEMBER; });
    }, [family]);
    var leaderMember = useMemo(function () {
        if (!family) {
            return;
        }
        return family.Person.filter(function (item) { return item.id && item.familyRole === FamilyRole.FAMILY_LEADER; });
    }, [family]);
    var personFamilyNumber = useMemo(function () {
        if (!family) {
            return 0;
        }
        return family.Person.length;
    }, [family]);
    var fullPersonFamily = personFamilyNumber === FAMILY_COMPONENTS;
    var lastPersonMember = useMemo(function () {
        return othersMembers === null || othersMembers === void 0 ? void 0 : othersMembers.slice(-1);
    }, [othersMembers]);
    var lastMemberName = useMemo(function () {
        if (!lastPersonMember) {
            return;
        }
        return lastPersonMember.map(function (item) { return item.name; }).toString();
    }, [lastPersonMember]);
    var isMemberMinor = useMemo(function () { var _a; return isMinor((_a = lastPersonMember === null || lastPersonMember === void 0 ? void 0 : lastPersonMember[0]) === null || _a === void 0 ? void 0 : _a.birthDate); }, [lastPersonMember]);
    var code = useGetCodeApi((_a = lastPersonMember === null || lastPersonMember === void 0 ? void 0 : lastPersonMember[0]) === null || _a === void 0 ? void 0 : _a.id, personLeader === null || personLeader === void 0 ? void 0 : personLeader.familyRole, isMemberMinor).getCode;
    var codeValue = useMemo(function () {
        var _a;
        if (!code) {
            return;
        }
        return (_a = code === null || code === void 0 ? void 0 : code.data) === null || _a === void 0 ? void 0 : _a.code;
    }, [code]);
    var codeMessage = useMemo(function () {
        var _a;
        if (!code) {
            return;
        }
        return (_a = code === null || code === void 0 ? void 0 : code.data) === null || _a === void 0 ? void 0 : _a.message;
    }, [code]);
    var isUserFamilyLeader = (personLeader === null || personLeader === void 0 ? void 0 : personLeader.familyRole) === FamilyRole.FAMILY_LEADER;
    return {
        othersMembers: othersMembers,
        leaderMember: leaderMember,
        codeValue: codeValue,
        codeMessage: codeMessage,
        lastPersonMember: lastPersonMember,
        lastMemberName: lastMemberName,
        refetchFamily: refetchFamily,
        fullPersonFamily: fullPersonFamily,
        membersByUser: membersByUser,
        isLoading: isLoading,
        generatePlan: generatePlan,
        isMemberMinor: isMemberMinor,
        isUserFamilyLeader: isUserFamilyLeader,
    };
};
