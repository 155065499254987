var RedeemCouponRepository = /** @class */ (function () {
    function RedeemCouponRepository(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        this.redeemCoupon = function (body) {
            return _this.httpClient.post("/gift/redeem", {
                body: body,
                isAuthorized: true,
            });
        };
    }
    return RedeemCouponRepository;
}());
export { RedeemCouponRepository };
