var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
export var SmallSectionType;
(function (SmallSectionType) {
    SmallSectionType[SmallSectionType["Right"] = 0] = "Right";
    SmallSectionType[SmallSectionType["Left"] = 1] = "Left";
})(SmallSectionType || (SmallSectionType = {}));
var PlHalfPageLayout = function (_a) {
    var leftSideChildren = _a.leftSideChildren, rightSideChildren = _a.rightSideChildren, smallSection = _a.smallSection;
    var smallSectionClassName = 'w-0 sm:w-1/6 md:w-1/6 lg:w-1/3 xl:w-1/2';
    var bigSectionClassName = 'w-4/4 sm:w-5/6 md:w-5/6 lg:w-2/3 xl:w-1/2';
    var leftSideChildrenClassName = "".concat(smallSection === undefined
        ? 'w-1/2'
        : smallSection === SmallSectionType.Left
            ? smallSectionClassName
            : bigSectionClassName);
    var rightSideChildrenClassName = "".concat(smallSection === undefined
        ? 'w-1/2'
        : smallSection === SmallSectionType.Right
            ? smallSectionClassName
            : bigSectionClassName);
    return (_jsxs("div", __assign({ className: "w-full h-[100vh] flex" }, { children: [_jsx("div", __assign({ className: "".concat(leftSideChildrenClassName, " overflow-auto") }, { children: leftSideChildren })), _jsx("div", __assign({ className: "".concat(rightSideChildrenClassName, " overflow-auto") }, { children: rightSideChildren }))] })));
};
export default PlHalfPageLayout;
