import { useState, useEffect } from 'react';
export var useScreenWidth = function () {
    var _a = useState(0), screenWidth = _a[0], setScreenWidth = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setScreenWidth(window.innerWidth);
        };
        setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return screenWidth;
};
