var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useGetCustomerApi, useGetFamilyApi, useGetFamilyPlanApi, useGetPersonApi, } from '../../hooks';
import { LoaderType } from '../../hooks/api/utils';
import { useCallback, useMemo } from 'react';
import { FamilyRole, SelectMembersEntity, } from '../../models';
import { useFamilyAndGuestsManagerFacade } from '../familyAndGuestsManager/useFamilyAndGuestsManagerFacade';
export var useChangePlanMembersEntityFacade = function (_a) {
    var minDate = _a.minDate, maxDate = _a.maxDate, mealType = _a.mealType, _b = _a.entity, entity = _b === void 0 ? SelectMembersEntity.CHANGE_MEAL : _b, recipeId = _a.recipeId;
    var customer = useGetCustomerApi(true).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var family = useGetFamilyApi(person === null || person === void 0 ? void 0 : person.familyId, !!(person === null || person === void 0 ? void 0 : person.familyId)).data;
    var familyPlan = useGetFamilyPlanApi(person === null || person === void 0 ? void 0 : person.familyId, minDate, maxDate, LoaderType.FullPage, !!(person === null || person === void 0 ? void 0 : person.familyId)).data;
    var isPresentEntity = useCallback(function (person) {
        if (entity === SelectMembersEntity.CHANGE_RECIPE) {
            return person.Plan.days.find(function (day) {
                return day.planRecipe.find(function (planRecipe) { return planRecipe.recipe.id === recipeId; });
            });
        }
        return (person.Plan.days.find(function (day) {
            return day.planRecipe.find(function (planRecipe) { return planRecipe.type === mealType; }) !==
                undefined;
        }) !== undefined);
    }, [entity, mealType, recipeId]);
    var membersOnEntity = useMemo(function () {
        var members = familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person.reduce(function (accumulator, person) {
            var isPresent = isPresentEntity(person);
            if (!isPresent) {
                return accumulator;
            }
            var member = family === null || family === void 0 ? void 0 : family.Person.find(function (familyPerson) { return familyPerson.id === person.id; });
            if (!member) {
                return accumulator;
            }
            return __spreadArray(__spreadArray([], accumulator, true), [member], false);
        }, []);
        return members === null || members === void 0 ? void 0 : members.sort(function (a) {
            return a.familyRole === FamilyRole.FAMILY_LEADER ? -1 : 1;
        });
    }, [family === null || family === void 0 ? void 0 : family.Person, familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person, isPresentEntity]);
    var allMembersDailyPlanId = useMemo(function () {
        if (!(familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person)) {
            return;
        }
        return familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person.map(function (person) {
            var _a;
            var dailyPlan = (_a = person.Plan.days.find(function (day) { return day.id; })) === null || _a === void 0 ? void 0 : _a.id;
            return {
                personId: person.id,
                dailyPlan: dailyPlan !== null && dailyPlan !== void 0 ? dailyPlan : '',
            };
        });
    }, [familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person]);
    var _c = useFamilyAndGuestsManagerFacade(membersOnEntity), guests = _c.guests, selectedMembers = _c.selectedMembers, isConfirmDisabled = _c.isConfirmDisabled, getMembersAddedLabel = _c.getMembersAddedLabel, onMemberPress = _c.onMemberPress, onChangeGuest = _c.onChangeGuest, isMemberSelected = _c.isMemberSelected, onAllMembersPress = _c.onAllMembersPress;
    var selectedMealMembersDailyPlan = useMemo(function () {
        return allMembersDailyPlanId === null || allMembersDailyPlanId === void 0 ? void 0 : allMembersDailyPlanId.filter(function (person) {
            return selectedMembers.includes(person.personId);
        });
    }, [allMembersDailyPlanId, selectedMembers]);
    var selectedRecipeMembersDailyPlan = useMemo(function () {
        return familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person.reduce(function (acc, person) {
            var _a;
            var dailyPlanId = (_a = person.Plan.days.find(function (day) { return day.id; })) === null || _a === void 0 ? void 0 : _a.id;
            if (selectedMembers.includes(person.id) && dailyPlanId) {
                acc.push({ dailyPlanId: dailyPlanId });
            }
            return acc;
        }, []);
    }, [familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.Person, selectedMembers]);
    var personToChange = useMemo(function () {
        return entity === SelectMembersEntity.CHANGE_MEAL
            ? selectedMealMembersDailyPlan
            : selectedRecipeMembersDailyPlan;
    }, [entity, selectedMealMembersDailyPlan, selectedRecipeMembersDailyPlan]);
    return {
        membersOnEntity: membersOnEntity,
        guests: guests,
        selectedMembers: selectedMembers,
        isConfirmDisabled: isConfirmDisabled,
        getMembersAddedLabel: getMembersAddedLabel,
        onMemberPress: onMemberPress,
        onChangeGuest: onChangeGuest,
        isMemberSelected: isMemberSelected,
        onAllMembersPress: onAllMembersPress,
        familyPlan: familyPlan,
        personToChange: personToChange,
    };
};
