import { createNotImplementedMethod } from '../utils';
var NotImplementedAuthRepository = /** @class */ (function () {
    function NotImplementedAuthRepository() {
        this.signIn = createNotImplementedMethod();
        this.signUp = createNotImplementedMethod();
        this.verifyCode = createNotImplementedMethod();
        this.refreshAccessToken = createNotImplementedMethod();
        this.addConsumer = createNotImplementedMethod();
        this.revokeToken = createNotImplementedMethod();
    }
    return NotImplementedAuthRepository;
}());
export { NotImplementedAuthRepository };
