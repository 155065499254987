var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var BackIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.color, color = _d === void 0 ? '#1C1C1E' : _d;
    return (_jsx("svg", __assign({ viewBox: "0 0 6 10", width: size, height: size, fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: _jsx("path", { d: "M5.259.591a.83.83 0 0 0-1.175 0L.259 4.416a.83.83 0 0 0 0 1.175l3.825 3.825a.83.83 0 1 0 1.175-1.175L2.025 5 5.26 1.766a.838.838 0 0 0 0-1.175Z", fill: color }) })));
};
export default BackIcon;
