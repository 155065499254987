import * as React from 'react';
export var useWindowSize = function () {
    var _a = React.useState([0, 0]), size = _a[0], setSize = _a[1];
    React.useLayoutEffect(function () {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return function () { return window.removeEventListener('resize', updateSize); };
    }, []);
    return size;
};
