import * as React from 'react';
import { useSearchFilters } from './useSearchFilters';
import { transformIngredientInSelectOption, transformSelectOptionInIngredient, } from '../../utils';
export var useIngredientsFilterSectionFacade = function () {
    var _a = useSearchFilters(), selectedIngredientFilter = _a.selectedIngredientFilter, setFilterIngredient = _a.setFilterIngredient;
    var selectedIngredientsOptions = React.useMemo(function () {
        return selectedIngredientFilter === null || selectedIngredientFilter === void 0 ? void 0 : selectedIngredientFilter.map(transformIngredientInSelectOption);
    }, [selectedIngredientFilter]);
    var applyFilter = React.useCallback(function (ingredientsOptions) {
        var ingredientsToApply = ingredientsOptions === null || ingredientsOptions === void 0 ? void 0 : ingredientsOptions.map(transformSelectOptionInIngredient);
        setFilterIngredient(ingredientsToApply !== null && ingredientsToApply !== void 0 ? ingredientsToApply : []);
    }, [setFilterIngredient]);
    return {
        selectedIngredientFilter: selectedIngredientFilter,
        selectedIngredientsOptions: selectedIngredientsOptions,
        applyFilter: applyFilter,
    };
};
