var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useMemo } from 'react';
import { NotImplementedErrorHandler } from '../../services';
import { NotificationType } from '../NotificationsMessageContext/NotificationsMessageContext';
import { useNotificationsMessage } from '../NotificationsMessageContext/useNotificationsMessage';
import { useLogout } from '../../hooks';
export var ErrorHandlerContext = createContext({
    errorHandler: new NotImplementedErrorHandler(),
});
export var ErrorHandlerProvider = function (_a) {
    var children = _a.children, ErrorHandlerImplementation = _a.ErrorHandlerImplementation, errorHandlerInstance = _a.errorHandlerInstance;
    var showNotifications = useNotificationsMessage().showNotifications;
    var logout = useLogout();
    var nonBlockingInteractionError = useMemo(function () {
        return function (message) {
            return showNotifications({
                type: NotificationType.error,
                text: message,
                isError: true,
            });
        };
    }, [showNotifications]);
    var sendLogout = useCallback(function () {
        try {
            logout();
        }
        catch (error) {
            console.log(error);
        }
    }, [logout]);
    var errorHandler = useMemo(function () {
        if (errorHandlerInstance) {
            return errorHandlerInstance;
        }
        if (ErrorHandlerImplementation) {
            return new ErrorHandlerImplementation(nonBlockingInteractionError, sendLogout);
        }
        throw new Error('No instance found, should exist at least one between ErrorHandlerImplementation or errorHandlerInstance');
    }, [
        ErrorHandlerImplementation,
        errorHandlerInstance,
        nonBlockingInteractionError,
        sendLogout,
    ]);
    return (_jsx(ErrorHandlerContext.Provider, __assign({ value: { errorHandler: errorHandler } }, { children: children })));
};
