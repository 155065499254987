export var addFullPageLoaderHandler = function (state, action) {
    state.fullPageLoaders.push(action.payload);
};
export var removeFullPageLoaderHandler = function (state, action) {
    state.fullPageLoaders = state.fullPageLoaders.filter(function (l) { return l !== action.payload; });
};
export var addLoaderHandler = function (state, action) {
    state.loaders.push(action.payload);
};
export var removeLoaderHandler = function (state, action) {
    state.loaders = state.loaders.filter(function (l) { return l !== action.payload; });
};
export var setIsAuthorizedHandler = function (state, action) {
    state.isAuthorized = action.payload;
};
export var setIsInitializedHandler = function (state, action) {
    state.isInitialized = action.payload;
};
export var setIsInErrorHandler = function (state, action) {
    state.isInError = action.payload;
};
export var resetGeneralToDefaultHandler = function (state) {
    state.isInError = false;
    state.isAuthorized = false;
    state.loaders = [];
    state.isInitialized = true;
    state.fullPageLoaders = [];
    state.isFamilyMemberRegisterRequested = false;
    console.log('Handler: Reset dello store concluso');
};
export var setIsWalkThroughCompletedHandler = function (state, action) {
    state.isWalkThroughCompleted = action.payload;
};
export var setIsCountryCompletedHandler = function (state, action) {
    state.isCountryCompleted = action.payload;
};
export var setIsRedeemCouponHandler = function (state, action) {
    state.redeemCoupon = action.payload;
};
export var setIsMemberRegistryCompletedHandler = function (state, action) {
    state.isMemberRegistryCompleted = action.payload;
};
export var setIsFamilyMemberRegisterRequestedHandler = function (state, action) {
    state.isFamilyMemberRegisterRequested = action.payload;
};
export var setIsFamilyMemberMinorHandler = function (state, action) {
    state.isFamilyMemberMinor = action.payload;
};
export var setIsPlanCreated = function (state, action) {
    state.isPlanCreated = action.payload;
};
export var setMembersColors = function (state, action) {
    state.membersColors = action.payload;
};
export var setIsFirstSignUpHandler = function (state, action) {
    state.isFirstSignUp = action.payload;
};
