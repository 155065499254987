var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
var PlSinglePageLayout = function (_a) {
    var header = _a.header, children = _a.children;
    return (_jsxs("div", __assign({ className: "w-full mx-auto max-w-screen-lg" }, { children: [header, children] })));
};
export default PlSinglePageLayout;
