import { createNotImplementedMethod } from '../utils';
var NotImplementedShoppingListRepository = /** @class */ (function () {
    function NotImplementedShoppingListRepository() {
        this.getShoppingList = createNotImplementedMethod();
        this.getFoodStorage = createNotImplementedMethod();
        this.getAllFoodStorages = createNotImplementedMethod();
        this.createFoodStorage = createNotImplementedMethod();
        this.deleteFoodStorageById = createNotImplementedMethod();
        this.setFoodStorageIngredient = createNotImplementedMethod();
    }
    return NotImplementedShoppingListRepository;
}());
export { NotImplementedShoppingListRepository };
