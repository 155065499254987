import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../routes/routes';
export var useBack = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var goBack = useCallback(function () {
        if (!location.key || location.key === 'default') {
            navigate(AppRoutes.Plan);
        }
        else {
            navigate(-1);
        }
    }, [location.key, navigate]);
    return goBack;
};
