import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/it';
// ** PLUGINS ** //
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.updateLocale('en', {
    weekStart: 1,
});
// ** UTILS ** //
export var DEFAULT_DATE_FORMAT = 'DD / MM / YYYY';
export var READABLE_DATE_FORMAT = 'DD/MM/YYYY';
export var AMERICAN_READABLE_DATE_FORMAT = 'MM/DD/YYYY';
export var DATE_PICKER_FORMAT = 'YYYY-MM-DD';
export var RECIPE_DATE_FORMAT = 'ddd DD MMM';
export var parseToDayJS = function (_a) {
    var date = _a.date, _b = _a.format, format = _b === void 0 ? DEFAULT_DATE_FORMAT : _b, _c = _a.locale, locale = _c === void 0 ? 'it' : _c;
    if (!date) {
        return dayjs(date);
    }
    return dayjs(date, format, locale);
};
export var formatDateTo = function (_a) {
    var date = _a.date, _b = _a.format, format = _b === void 0 ? DEFAULT_DATE_FORMAT : _b;
    return dayjs(date).format(format);
};
export var differenceBetweenDates = function (_a) {
    var firstDate = _a.firstDate, secondDate = _a.secondDate, firstDateFormat = _a.firstDateFormat, secondDateFormat = _a.secondDateFormat, _b = _a.unit, unit = _b === void 0 ? 'd' : _b;
    var firstDayJS = dayjs(firstDate, firstDateFormat);
    var lastDayJS = dayjs(secondDate, secondDateFormat);
    if (!firstDayJS.isValid() || !lastDayJS.isValid()) {
        return -1;
    }
    return firstDayJS.diff(lastDayJS, unit);
};
export var getWeek = function (date, _a) {
    var _b = _a.inputFormat, inputFormat = _b === void 0 ? DEFAULT_DATE_FORMAT : _b, _c = _a.outputFormat, outputFormat = _c === void 0 ? DEFAULT_DATE_FORMAT : _c, _d = _a.isNext, isNext = _d === void 0 ? false : _d;
    var weeks = !isNext ? [0, 1, 2, 3, 4, 5, 6] : [7, 8, 9, 10, 11, 12, 13];
    return weeks.map(function (day) {
        return dayjs(date, inputFormat).weekday(day).format(outputFormat);
    });
};
export var isBetween = function (date) { return function (startDate, endDate) {
    return !date.isBefore(startDate) && !date.isAfter(endDate);
}; };
