import { createNotImplementedMethod } from '../utils';
var NotImplementedPlanRepository = /** @class */ (function () {
    function NotImplementedPlanRepository() {
        this.getPersonPlan = createNotImplementedMethod();
        this.getFamilyPlan = createNotImplementedMethod();
        this.handleRecipe = createNotImplementedMethod();
        this.handleMeal = createNotImplementedMethod();
        this.generatePlan = createNotImplementedMethod();
        this.generateAlternativePlanMeal = createNotImplementedMethod();
        this.saveAlternativePlanMeal = createNotImplementedMethod();
        this.saveSubstituteRecipe = createNotImplementedMethod();
        this.getCustomMealRecipe = createNotImplementedMethod();
        this.findPlanRecipeAlternative = createNotImplementedMethod();
        this.getCheckNutrients = createNotImplementedMethod();
        this.manageGuests = createNotImplementedMethod();
        this.generatePlanDays = createNotImplementedMethod();
    }
    return NotImplementedPlanRepository;
}());
export { NotImplementedPlanRepository };
