var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FamilyRole, StorageKeys, useGetCustomerApi, useGetPersonApi, useIsCountryCompleted, useIsMemberRegistryCompleted, useIsPlanCreated, useIsUserAuthenticated, useIsWalkThroughCompleted, } from '@planter/common';
import Qonversion from '@qonversion/web-sdk/sdk/build/Qonversion';
import * as React from 'react';
import Loader from '../../components/Loader';
import { useInAppPurchase } from '../../hooks/useInAppPurchase';
import { UserRole } from '../../models';
import { EntitlementState } from '../../services/InAppPurchase/types';
export var UserRoleContext = React.createContext({
    userRole: UserRole.Anonymous,
    setUserRole: function () {
        throw new Error('setUserRole is not yet implemented');
    },
});
export var UserRoleProvider = function (_a) {
    var _b, _c, _d;
    var children = _a.children;
    var _e = React.useState(), userRole = _e[0], setUserRole = _e[1];
    var coupon = localStorage.getItem(StorageKeys.COUPON_CODE_KEY);
    var isWalkThroughCompleted = useIsWalkThroughCompleted().isWalkThroughCompleted;
    var isAuthenticated = useIsUserAuthenticated().isAuthenticated;
    var isCountryCompleted = useIsCountryCompleted().isCountryCompleted;
    var isMemberRegistryCompleted = useIsMemberRegistryCompleted().isMemberRegistryCompleted;
    var isPlanCreated = useIsPlanCreated().isPlanCreated;
    var customer = useGetCustomerApi(Boolean(isAuthenticated)).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _f = React.useState(false), userPaymentNeeded = _f[0], setUserPaymentNeeded = _f[1];
    var familyId = (_b = customer === null || customer === void 0 ? void 0 : customer.Person) === null || _b === void 0 ? void 0 : _b.familyId;
    var isAppReady = isWalkThroughCompleted !== undefined &&
        isAuthenticated !== undefined &&
        isCountryCompleted !== undefined &&
        isMemberRegistryCompleted !== undefined;
    var entitlementStateChange = React.useCallback(function (entitlementState) {
        setEntitlementState(entitlementState);
    }, []);
    var _g = React.useState(EntitlementState.none), entitlementState = _g[0], setEntitlementState = _g[1];
    var _h = useInAppPurchase({
        onEntitlementChange: entitlementStateChange,
    }), initSdk = _h.initSdk, checkEntitlements = _h.checkEntitlements;
    React.useEffect(function () {
        var _a, _b;
        if (!(person === null || person === void 0 ? void 0 : person.familyId)) {
            return;
        }
        initSdk(person === null || person === void 0 ? void 0 : person.familyId, (_a = person === null || person === void 0 ? void 0 : person.Customer) === null || _a === void 0 ? void 0 : _a.email, (_b = person === null || person === void 0 ? void 0 : person.Customer) === null || _b === void 0 ? void 0 : _b.id).then(function () {
            checkEntitlements().then(setEntitlementState);
        });
    }, [
        checkEntitlements,
        initSdk,
        (_c = person === null || person === void 0 ? void 0 : person.Customer) === null || _c === void 0 ? void 0 : _c.email,
        (_d = person === null || person === void 0 ? void 0 : person.Customer) === null || _d === void 0 ? void 0 : _d.id,
        person === null || person === void 0 ? void 0 : person.familyId,
    ]);
    React.useEffect(function () {
        var checkUserHasQonversionEntitlements = function () { return __awaiter(void 0, void 0, void 0, function () {
            var familyLeaderEntitlement, qonversionInstance, entitlements, paidOnQonversion, paidOnDb, e_1;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!familyId)
                            return [2 /*return*/, false];
                        familyLeaderEntitlement = (_b = (_a = customer.Person.Family.Person.find(function (person) { return person.familyRole === FamilyRole.FAMILY_LEADER; })) === null || _a === void 0 ? void 0 : _a.Customer.Subscription) === null || _b === void 0 ? void 0 : _b.status;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 6]);
                        qonversionInstance = Qonversion.getSharedInstance();
                        return [4 /*yield*/, qonversionInstance.logout()];
                    case 2:
                        _d.sent();
                        return [4 /*yield*/, qonversionInstance.identify(familyId)];
                    case 3:
                        _d.sent();
                        return [4 /*yield*/, qonversionInstance.getEntitlements()];
                    case 4:
                        entitlements = _d.sent();
                        paidOnQonversion = (_c = entitlements === null || entitlements === void 0 ? void 0 : entitlements[0]) === null || _c === void 0 ? void 0 : _c.active;
                        paidOnDb = familyLeaderEntitlement === 'AUTHORIZED';
                        if (!paidOnQonversion && !paidOnDb) {
                            if (process.env.REACT_APP_DEV_ENV === 'YES') {
                                setUserPaymentNeeded(false);
                                return [2 /*return*/];
                            }
                            setUserPaymentNeeded(true);
                            return [2 /*return*/];
                        }
                        if (familyLeaderEntitlement === 'AUTHORIZED') {
                            setUserPaymentNeeded(false);
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _d.sent();
                        console.log('ERROR: ', e_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        checkUserHasQonversionEntitlements();
    }, [entitlementState, familyId, customer, isAppReady]);
    React.useEffect(function () {
        if (!isAppReady)
            return;
        if (!isAuthenticated) {
            setUserRole(UserRole.Anonymous);
            return;
        }
        if (coupon && !person) {
            setUserRole(UserRole.AnonymousCouponRedeemUser);
            return;
        }
        if (!isCountryCompleted) {
            setUserRole(UserRole.NoCountryUser);
            return;
        }
        if (!isMemberRegistryCompleted) {
            setUserRole(UserRole.PartialUser);
            return;
        }
        if (coupon) {
            setUserRole(UserRole.RegisteredCouponRedeemUser);
            return;
        }
        if (userPaymentNeeded) {
            setUserRole(UserRole.UserPaymentNeeded);
            return;
        }
        setUserRole(isPlanCreated ? UserRole.CompleteUser : UserRole.PlanPendingUser);
    }, [
        isAppReady,
        isAuthenticated,
        isCountryCompleted,
        isMemberRegistryCompleted,
        isPlanCreated,
        userPaymentNeeded,
        coupon,
        person,
    ]);
    return (_jsx(UserRoleContext.Provider, __assign({ value: { userRole: userRole || UserRole.Anonymous, setUserRole: setUserRole } }, { children: userRole ? children : _jsx(Loader, {}) })));
};
