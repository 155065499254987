var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguage } from '@planter/common';
import * as React from 'react';
import PacifierIcon from '../../../components/icons/PacifierIcon';
import PlSinglePageLayoutBoxedSection from '../../../components/layouts/PlSinglePageLayoutBoxedSection';
import PlText from '../../../components/PlText';
import TipPopover from './TipPopover';
var PreparationStepsSection = function (_a) {
    var preparationSteps = _a.preparationSteps, isWeaning = _a.isWeaning;
    var t = useLanguage()[0];
    var totalSteps = (preparationSteps === null || preparationSteps === void 0 ? void 0 : preparationSteps.length) || 1;
    var _b = React.useState([]), popoversState = _b[0], setPopoverStates = _b[1];
    var togglePopoverAtIndex = React.useCallback(function (index) {
        setPopoverStates(function (pv) {
            var tmpStates = __spreadArray([], pv, true);
            tmpStates[index] = !tmpStates[index];
            return tmpStates;
        });
    }, []);
    var closePopoverAtIndex = React.useCallback(function (index) {
        setPopoverStates(function (pv) {
            var tmpStates = __spreadArray([], pv, true);
            tmpStates[index] = false;
            return tmpStates;
        });
    }, []);
    var isAtLeastOnePopoverOpen = React.useMemo(function () {
        var openedPopoverIndex = popoversState.findIndex(function (p) { return p; });
        return openedPopoverIndex !== -1;
    }, [popoversState]);
    return (_jsxs(PlSinglePageLayoutBoxedSection, { children: [_jsx("div", __assign({ className: "mb-8" }, { children: preparationSteps === null || preparationSteps === void 0 ? void 0 : preparationSteps.map(function (ps, i) {
                    var closePopover = function () { return closePopoverAtIndex(i); };
                    var togglePopover = function () { return togglePopoverAtIndex(i); };
                    return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "bg-orange-light flex justify-between px-4 items-center mt-6 rounded-2xl ".concat(ps.tipDescription ? 'py-2' : 'py-4') }, { children: [_jsxs(PlText, { children: [ps.stepNumber + 1, "/", totalSteps, ' ', _jsx("span", __assign({ className: "font-semibold" }, { children: ps.title }))] }), ps.tipDescription && (_jsx(TipPopover, { closePopover: closePopover, isPopoverOpen: popoversState[i], tipDescription: ps.tipDescription, togglePopover: togglePopover }))] })), _jsx(PlText, { className: "mt-4", html: ps.description }), isWeaning && ps.weaningNote && (_jsxs("div", __assign({ className: "bg-grey-extra-light p-4 mt-6 rounded-2xl" }, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(PacifierIcon, {}), _jsx(PlText, __assign({ className: "ml-2 font-medium" }, { children: t('recipeDetail.weaningAlertTitle') }))] })), _jsx(PlText, { html: ps.weaningNote, className: "mt-3" })] })))] }, ps.id));
                }) })), isAtLeastOnePopoverOpen && (_jsx("div", { className: "inset-0 fixed bg-zinc-700/30" }))] }));
};
export default PreparationStepsSection;
