var ShoppingListRepository = /** @class */ (function () {
    function ShoppingListRepository(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        this.getShoppingList = function (params) {
            return _this.httpClient.get("/shoppingList", {
                params: params,
                isAuthorized: true,
            });
        };
        this.getFoodStorage = function (params) {
            return _this.httpClient.get("/foodStorage", {
                params: params,
                isAuthorized: true,
            });
        };
        this.getAllFoodStorages = function () {
            return _this.httpClient.get("/foodStorage/all", {
                isAuthorized: true,
            });
        };
        this.createFoodStorage = function (params) {
            return _this.httpClient.post("/foodStorage", {
                body: params,
                isAuthorized: true,
            });
        };
        this.deleteFoodStorageById = function (id) {
            return _this.httpClient.delete("/foodStorage/".concat(id), {
                isAuthorized: true,
            });
        };
        this.setFoodStorageIngredient = function (params) {
            return _this.httpClient.post("/foodStorage/set", {
                body: params,
                isAuthorized: true,
            });
        };
    }
    return ShoppingListRepository;
}());
export { ShoppingListRepository };
