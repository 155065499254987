var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { applyFiltersReducer, clearSelectedFiltersReducer, setCategoriesReducer, setFromWorldFiltersReducer, setIngredientsReducer, setIsFavouritesReducer, setIsSeasonalReducer, setMacroNutrientsReducer, setPreparationTimeReducer, toggleCategoryReducer, toggleFromWorldReducer, toggleIngredientReducer, toggleMacroNutrientsReducer, } from './filters.reducer';
export var filtersInitialState = {
    preparationTime: null,
    ingredients: null,
    categories: null,
    fromWorld: null,
    isSeasonal: null,
    selectedFilters: null,
    macroNutrients: null,
    isFavourites: null,
};
var FILTERS_SLICE = 'filtersSlice';
var filtersSlice = createSlice({
    name: FILTERS_SLICE,
    initialState: __assign({}, filtersInitialState),
    reducers: {
        setPreparationTime: setPreparationTimeReducer,
        setIngredients: setIngredientsReducer,
        toggleIngredient: toggleIngredientReducer,
        setCategories: setCategoriesReducer,
        toggleCategory: toggleCategoryReducer,
        setFromWorldFilters: setFromWorldFiltersReducer,
        toggleFromWorld: toggleFromWorldReducer,
        setIsSeasonal: setIsSeasonalReducer,
        setMacroNutrients: setMacroNutrientsReducer,
        toggleMacroNutrients: toggleMacroNutrientsReducer,
        setIsFavourites: setIsFavouritesReducer,
        clearFilters: clearSelectedFiltersReducer,
        applyFilters: applyFiltersReducer,
    },
});
export var FiltersActions = filtersSlice.actions;
export var filtersReducer = filtersSlice.reducer;
