var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useMemo } from 'react';
import { useAuthenticationForm } from '../../validations';
import { useSignUpMutation } from '../../hooks/api/authentication/useSignUp.api';
import { useSignInMutation } from '../../hooks/api/authentication/useSignIn.api';
import { CognitoError } from '../../models';
import { useErrorHandler } from '../../contexts';
export var useEmailInputFacade = function (onNext) {
    var _a;
    var blockInteraction = useErrorHandler().blockInteraction;
    var _b = useAuthenticationForm(), handleSubmit = _b.handleSubmit, emailValue = _b.emailValue, onEmailChange = _b.onEmailChange, errors = _b.errors;
    // ** API ** //
    var signUpApi = useSignUpMutation().mutateAsync;
    var signInApi = useSignInMutation().mutateAsync;
    // ** CALLBACKS ** //
    var signUp = useCallback(function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var username;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    username = email;
                    return [4 /*yield*/, signUpApi({ email: email, username: username })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, [signUpApi]);
    var signIn = useCallback(function (email) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signInApi({ email: email })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, [signInApi]);
    var onPressIcon = useCallback(function () {
        onEmailChange('');
    }, [onEmailChange]);
    // ** DATA ** //
    var signUpAndIn = useMemo(function () {
        return handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var isUserRegistered, error_1, res, navigationParams, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        isUserRegistered = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, signUp(data.email)];
                    case 2:
                        _b.sent();
                        isUserRegistered = true;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        if (((_a = error_1.data) === null || _a === void 0 ? void 0 : _a.__type) !== CognitoError.UsernameExists) {
                            blockInteraction('Something gone wrong while signing up');
                        }
                        else {
                            isUserRegistered = true;
                        }
                        return [3 /*break*/, 4];
                    case 4:
                        if (!isUserRegistered)
                            return [2 /*return*/, undefined];
                        _b.label = 5;
                    case 5:
                        _b.trys.push([5, 8, , 9]);
                        return [4 /*yield*/, signIn(data.email)];
                    case 6:
                        res = _b.sent();
                        navigationParams = { email: data.email, session: res.Session };
                        return [4 /*yield*/, onNext(navigationParams)];
                    case 7:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _b.sent();
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        }); });
    }, [blockInteraction, handleSubmit, onNext, signIn, signUp]);
    return {
        value: emailValue,
        onChangeText: onEmailChange,
        onPressIcon: onPressIcon,
        error: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message,
        signUpAndIn: signUpAndIn,
    };
};
