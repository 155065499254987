var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var PlTabNavigation = function (_a) {
    var elements = _a.elements, selectedValue = _a.selectedValue, onChangeElement = _a.onChangeElement;
    return (_jsx("div", __assign({ className: "flex items-center gap-2" }, { children: elements.map(function (e) {
            var isSelected = e.value === selectedValue;
            var onElementClick = function () { return onChangeElement(e.value); };
            return (_jsx("button", __assign({ className: "py-2 px-3 rounded-full text-[14px] ".concat(isSelected ? 'bg-orange-dark font-medium text-white' : 'text-grey'), onClick: onElementClick }, { children: e.label }), e.value));
        }) })));
};
export default PlTabNavigation;
