var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var onboardingRedirectPages = [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.NoCountryUser,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.CompleteUser,
        page: AppRoutes.Plan,
    },
    {
        userRole: UserRole.UserPaymentNeeded,
        page: AppRoutes.SubscriptionPage,
    },
    {
        userRole: UserRole.PlanPendingUser,
        page: AppRoutes.Plan,
    },
];
var NamePage = React.lazy(function () { return import('../pages/onboarding/name.page'); });
var BirthdayPage = React.lazy(function () { return import('../pages/onboarding/birthday.page'); });
var GenderPage = React.lazy(function () { return import('../pages/onboarding/gender.page'); });
var HeightPage = React.lazy(function () { return import('../pages/onboarding/height.page'); });
var WorkoutPage = React.lazy(function () { return import('../pages/onboarding/workout.page'); });
var AllergiesPage = React.lazy(function () { return import('../pages/onboarding/allergies.page'); });
var JoinFamilyPage = React.lazy(function () { return import('../pages/onboarding/familyCode.page'); });
var DislikedIngredientsPage = React.lazy(function () { return import('../pages/onboarding/dislikedIngredients.page'); });
var WelcomePage = React.lazy(function () { return import('../pages/onboarding/welcome/welcome.page'); });
var ProfileDetailPage = React.lazy(function () { return import('../pages/onboarding/welcome/profileDetail.page'); });
var onboardingRoutes = [
    {
        path: AppRoutes.Base,
        element: _jsx(Navigate, { to: AppRoutes.Intro, replace: true }),
    },
    {
        path: AppRoutes.OnboardingName,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(NamePage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingBirthday,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(BirthdayPage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingGender,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(GenderPage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingHeight,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(HeightPage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingWorkout,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(WorkoutPage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingAllergies,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(AllergiesPage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingDislikedIngredientsPage,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser, UserRole.AnonymousCouponRedeemUser], redirectPages: onboardingRedirectPages }, { children: _jsx(DislikedIngredientsPage, {}) }))),
    },
    {
        path: AppRoutes.OnboardingWelcomePage,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [
                UserRole.PlanPendingUser,
                UserRole.RegisteredCouponRedeemUser,
            ], redirectPages: onboardingRedirectPages }, { children: _jsx(WelcomePage, {}) }))),
    },
    {
        path: AppRoutes.JoinFamily,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.PartialUser], redirectPages: onboardingRedirectPages }, { children: _jsx(JoinFamilyPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.OnboardingProfilePage, "/:id"),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [
                UserRole.PlanPendingUser,
                UserRole.AnonymousCouponRedeemUser,
            ], redirectPages: onboardingRedirectPages }, { children: _jsx(ProfileDetailPage, {}) }))),
    },
];
export default onboardingRoutes;
