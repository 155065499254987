var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var NotAuthorizatedExternalLink = AppRoutes.RecipePreview;
var RecipePage = React.lazy(function () { return import('../pages/recipeDetails/recipe.page'); });
var FavouritesPage = React.lazy(function () { return import('../pages/favourites/favourites.page'); });
var RecipePreviewPage = React.lazy(function () { return import('../pages/recipeDetails/recipePreview.page'); });
var recipeBasicRedirectPages = [
    {
        userRole: UserRole.NoCountryUser,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.PartialUser,
        page: AppRoutes.JoinFamily,
    },
    {
        userRole: UserRole.PlanPendingUser,
        page: AppRoutes.OnboardingWelcomePage,
    },
    {
        userRole: UserRole.UserPaymentNeeded,
        page: AppRoutes.SubscriptionPage,
    },
];
var recipeRedirectPages = __spreadArray(__spreadArray([], recipeBasicRedirectPages, true), [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.Intro,
    },
], false);
var recipePreviewRedirectPages = __spreadArray(__spreadArray([], recipeBasicRedirectPages, true), [
    {
        userRole: UserRole.CompleteUser,
        page: AppRoutes.Recipe,
    },
], false);
var recipeRoutes = [
    {
        path: AppRoutes.Recipe,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: recipeRedirectPages, externalRedirectPage: NotAuthorizatedExternalLink }, { children: _jsx(RecipePage, {}) }))),
    },
    {
        path: AppRoutes.RecipePreview,
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.Anonymous], redirectPages: recipePreviewRedirectPages }, { children: _jsx(RecipePreviewPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.FavouritesPage),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: recipeRedirectPages }, { children: _jsx(FavouritesPage, {}) }))),
    },
];
export default recipeRoutes;
