import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { AppRoutes } from './routes';
var NotFoundPage = React.lazy(function () { return import('../pages/notFound.page'); });
var generalRoutes = [
    {
        path: AppRoutes.NotFound,
        element: _jsx(NotFoundPage, {}),
    },
];
export default generalRoutes;
