var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useLanguage } from '@planter/common';
import PlTitle, { TitleSizeType } from './PlTitle';
import PlAddedFamilyMemberBtn from './PlAddedFamilyMemberBtn';
import CheckMemberIcon from './icons/CheckMemberIcon';
import PlText from './PlText';
import AddIcon from './icons/AddIcon';
import SubtractIcon from './icons/SubtractIcon';
import colors from '../styles/colors.module.scss';
import AllComponentsIcon from './icons/AllComponentsIcon';
import PlUserIcon from './PlUserIcon';
import PlDescription from './PlDescription';
var GUEST_LIMIT = 8;
var PlMembersSelect = function (_a) {
    var title = _a.title, description = _a.description, members = _a.members, onMemberCardClick = _a.onMemberCardClick, selectedMembers = _a.selectedMembers, _b = _a.guests, guests = _b === void 0 ? 0 : _b, onGuestsChange = _a.onGuestsChange, _c = _a.guestsLimit, guestsLimit = _c === void 0 ? GUEST_LIMIT : _c, showSelectAll = _a.showSelectAll, onSelectAll = _a.onSelectAll, _d = _a.hasGuestsPicker, hasGuestsPicker = _d === void 0 ? true : _d;
    var t = useLanguage()[0];
    var onAllMembersClick = React.useCallback(function () {
        return onSelectAll === null || onSelectAll === void 0 ? void 0 : onSelectAll((selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.length) === (members === null || members === void 0 ? void 0 : members.length)
            ? []
            : (members === null || members === void 0 ? void 0 : members.map(function (m) { return m.id; })) || []);
    }, [members, onSelectAll, selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.length]);
    return (_jsxs("div", __assign({ className: "pt-10 px-16" }, { children: [_jsx(PlTitle, { label: title || '', size: TitleSizeType.VeryLarge, className: "font-semibold pb-5 text-center" }), _jsx(PlDescription, { label: description || '', className: "font-medium p-3.5 text-grey" }), showSelectAll && (_jsx("div", __assign({ className: "my-2", onClick: onAllMembersClick }, { children: _jsx(PlAddedFamilyMemberBtn, { label: t('planPage.allComponents'), leftIcon: _jsx(AllComponentsIcon, {}), rightIcon: (selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.length) === (members === null || members === void 0 ? void 0 : members.length) && _jsx(CheckMemberIcon, {}), className: "cursor-pointer ".concat((selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.length) === (members === null || members === void 0 ? void 0 : members.length) &&
                        'border border-orange-dark') }) }))), members &&
                members.map(function (member) {
                    var isSelectedMember = selectedMembers === null || selectedMembers === void 0 ? void 0 : selectedMembers.find(function (m) { return m === member.id; });
                    return (_jsx("div", __assign({ className: "my-2", onClick: function () { return onMemberCardClick && onMemberCardClick(member.id); } }, { children: _jsx(PlAddedFamilyMemberBtn, { label: member.name, leftIcon: _jsx(PlUserIcon, { personId: member === null || member === void 0 ? void 0 : member.id, iconSize: "46", margin: "3px", hasPrefix: true }), rightIcon: isSelectedMember && _jsx(CheckMemberIcon, {}), className: "cursor-pointer ".concat(isSelectedMember && 'border border-orange-dark') }) }), member.id));
                }), hasGuestsPicker && (_jsxs("div", __assign({ className: "border-y border-grey-light flex justify-between mt-7" }, { children: [_jsx(PlText, __assign({ className: "py-5 font-medium" }, { children: t('recipeDetail.guests') })), _jsxs("div", __assign({ className: "flex justify-center items-center" }, { children: [_jsx("div", __assign({ className: "rounded-full border p-3 ".concat(guests > 0
                                    ? 'cursor-pointer border-black'
                                    : 'cursor-not-allowed border-grey-light'), onClick: function () {
                                    return guests > 0 && onGuestsChange && onGuestsChange((guests -= 1));
                                } }, { children: _jsx(SubtractIcon, { color: guests === 0 ? colors['grey-light'] : colors.black, height: "12" }) })), _jsx("div", __assign({ className: "px-3" }, { children: guests })), _jsx("div", __assign({ className: "rounded-full border p-3 ".concat(guests < guestsLimit
                                    ? 'cursor-pointer border-black'
                                    : 'cursor-not-allowed border-grey-light'), onClick: function () {
                                    return guests < guestsLimit &&
                                        onGuestsChange &&
                                        onGuestsChange((guests += 1));
                                } }, { children: _jsx(AddIcon, { color: guests === guestsLimit ? colors['grey-light'] : colors.black }) }))] }))] })))] })));
};
export default PlMembersSelect;
