var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useGetCustomerApi, useGetFamilyPlanApi, useGetPersonApi, } from '../../hooks';
import { useGetRecipeApi } from '../../hooks/api/recipe/useGetRecipe.api';
import { useGetRecipePreferencesApi } from '../../hooks';
import { LoaderType } from '../../hooks/api/utils';
import { RecipeDetailsType, } from '../../models';
import { DATE_PICKER_FORMAT, datesManager, RECIPE_DATE_FORMAT, useLanguage, } from '../../locale';
import dayjs from 'dayjs';
export var useRecipeDetailsFacade = function (_a) {
    var recipeId = _a.recipeId, _b = _a.type, type = _b === void 0 ? RecipeDetailsType.Explore : _b, date = _a.date, mealType = _a.mealType, _c = _a.loader, loader = _c === void 0 ? LoaderType.FullPage : _c;
    var t = useLanguage()[0];
    var _d = React.useState(), recipeDetail = _d[0], setRecipeDetail = _d[1];
    var _e = React.useState(), personsPlanDetail = _e[0], setPersonsPlanDetail = _e[1];
    var getFamilyPlanDateParam = useMemo(function () { return new Date(datesManager().toString(DATE_PICKER_FORMAT)).toISOString(); }, []);
    var customer = useGetCustomerApi().data;
    var personLeader = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId, LoaderType.None).data;
    var familyPlan = useGetFamilyPlanApi(personLeader === null || personLeader === void 0 ? void 0 : personLeader.familyId, getFamilyPlanDateParam, getFamilyPlanDateParam, LoaderType.None, true).data;
    var _f = useGetRecipeApi(recipeId, type, date, mealType, loader), recipeDetailResponse = _f.data, isRecipeLoading = _f.isLoading;
    var _g = useGetRecipePreferencesApi(recipeId, loader), recipePreferences = _g.data, isRecipePreferencesLoading = _g.isLoading;
    var recipeResponse = useMemo(function () {
        return __assign(__assign({}, recipePreferences), recipeDetailResponse);
    }, [recipeDetailResponse, recipePreferences]);
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (type === RecipeDetailsType.Plan) {
            var planRecipeInfo = (_a = recipeResponse === null || recipeResponse === void 0 ? void 0 : recipeResponse.Person) === null || _a === void 0 ? void 0 : _a.filter(function (personRecipe) { var _a, _b, _c; return ((_c = (_b = (_a = personRecipe === null || personRecipe === void 0 ? void 0 : personRecipe.Plan) === null || _a === void 0 ? void 0 : _a.days[0]) === null || _b === void 0 ? void 0 : _b.planRecipe) === null || _c === void 0 ? void 0 : _c.length) > 0; });
            var recipeFound = (_e = (_d = (_c = (_b = planRecipeInfo === null || planRecipeInfo === void 0 ? void 0 : planRecipeInfo[0]) === null || _b === void 0 ? void 0 : _b.Plan) === null || _c === void 0 ? void 0 : _c.days[0]) === null || _d === void 0 ? void 0 : _d.planRecipe[0]) === null || _e === void 0 ? void 0 : _e.recipe;
            setRecipeDetail(__assign(__assign({}, recipePreferences), recipeFound));
            var personsRecipeInfo = planRecipeInfo === null || planRecipeInfo === void 0 ? void 0 : planRecipeInfo.map(function (person) {
                var _a, _b, _c, _d;
                var ingredients = (_d = (_c = (_b = (_a = person === null || person === void 0 ? void 0 : person.Plan) === null || _a === void 0 ? void 0 : _a.days[0]) === null || _b === void 0 ? void 0 : _b.planRecipe[0]) === null || _c === void 0 ? void 0 : _c.recipe) === null || _d === void 0 ? void 0 : _d.ingredients.filter(function (ingredient, i, self) {
                    return i === self.findIndex(function (el) { return el.id === ingredient.id; });
                });
                return {
                    id: person.id,
                    name: person.name,
                    profileImageUrl: person.profileImageUrl,
                    familyRole: person.familyRole,
                    ingredients: ingredients,
                };
            });
            setPersonsPlanDetail(personsRecipeInfo);
        }
        else
            setRecipeDetail(recipeResponse);
    }, [recipePreferences, recipeResponse, type]);
    var hasProteinFood = !!recipeDetail && recipeDetail.proteinFoods > 0;
    var hasFruit = !!recipeDetail && recipeDetail.fruit > 0;
    var hasGoodFats = !!recipeDetail && recipeDetail.goodFats > 0;
    var hasVegetables = !!recipeDetail && recipeDetail.vegetables > 0;
    var hasCereals = !!recipeDetail && recipeDetail.cereals > 0;
    var ingredients = recipeDetail === null || recipeDetail === void 0 ? void 0 : recipeDetail.ingredients;
    var recipeInfo = useMemo(function () {
        return {
            profiles: personsPlanDetail,
            date: dayjs(date)
                .format(RECIPE_DATE_FORMAT)
                .replace(/^\w|\s\w/g, function (l) { return l.toUpperCase(); }),
            mealTime: mealType && t("planPage.sectionTitles.".concat(mealType)).slice(0, -1),
        };
    }, [date, mealType, personsPlanDetail, t]);
    var maxSelectableDate = useMemo(function () {
        return datesManager(familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.maxDate.toString(), DATE_PICKER_FORMAT, true).toString(DATE_PICKER_FORMAT);
    }, [familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.maxDate]);
    var minSelectableDate = useMemo(function () {
        return datesManager(familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.minDate.toString(), DATE_PICKER_FORMAT, true).toString(DATE_PICKER_FORMAT);
    }, [familyPlan === null || familyPlan === void 0 ? void 0 : familyPlan.minDate]);
    return {
        recipeDetail: recipeDetail,
        ingredients: ingredients,
        personsPlanDetail: personsPlanDetail,
        isRecipeLoading: isRecipeLoading || isRecipePreferencesLoading,
        hasProteinFood: hasProteinFood,
        hasFruit: hasFruit,
        hasGoodFats: hasGoodFats,
        hasVegetables: hasVegetables,
        hasCereals: hasCereals,
        recipeInfo: recipeInfo,
        minSelectableDate: minSelectableDate,
        maxSelectableDate: maxSelectableDate,
    };
};
