var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { LayoutType } from '../components/layouts/PlLayout';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var ProfilePage = React.lazy(function () { return import('../pages/profile/profile.page'); });
var ProfileManagementPage = React.lazy(function () { return import('../pages/profile/profileManagement/profileManagement.page'); });
var FamilyMembersPage = React.lazy(function () {
    return import('../pages/profile/componentsAndPreferences/FamilyMembersPage.page');
});
var ProfileDetailPage = React.lazy(function () { return import('../pages/onboarding/welcome/profileDetail.page'); });
var NotificationHistoryPage = React.lazy(function () { return import('../pages/profile/notificationHistory/notificationHistory.page'); });
var PlanInformationPage = React.lazy(function () { return import('../pages/profile/planInformation/planInformation.page'); });
var SubscriptionDetailsPage = React.lazy(function () { return import('../pages/profile/subscriptionDetails/subscriptionDetails.page'); });
var SubscriptionDetailsAnonymousPage = React.lazy(function () {
    return import('../pages/profile/subscriptionDetails/subscriptionDetails.anonymous.page');
});
var LanguageAndUnitOfMeasurePage = React.lazy(function () {
    return import('../pages/profile/languageAndUnitOfMeasure/languageAndUnitOfMeasure.page');
});
var profileRedirectPages = [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.NoCountryUser,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.PartialUser,
        page: AppRoutes.JoinFamily,
    },
    {
        userRole: UserRole.PlanPendingUser,
        page: AppRoutes.OnboardingWelcomePage,
    },
    {
        userRole: UserRole.UserPaymentNeeded,
        page: AppRoutes.SubscriptionPage,
    },
];
var profileSubscriptionRedirectPages = [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.SubscriptionAnonymousDetails,
    },
    {
        userRole: UserRole.UserPaymentNeeded,
        page: AppRoutes.SubscriptionAnonymousDetails,
    },
    {
        userRole: UserRole.CompleteUser,
        page: AppRoutes.SubscriptionDetails,
    },
];
var profileRoutes = [
    {
        path: "".concat(AppRoutes.ProfilePage),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(ProfilePage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.ProfileManagement),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(ProfileManagementPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.FamilyMembers),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(FamilyMembersPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.FamilyMembersProfilePage, "/:id"),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(ProfileDetailPage, { layoutType: LayoutType.Default, smallCentralContent: true }) }))),
    },
    {
        path: "".concat(AppRoutes.NotificationHistoryPage),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(NotificationHistoryPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.SubscriptionDetails),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileSubscriptionRedirectPages }, { children: _jsx(SubscriptionDetailsPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.SubscriptionAnonymousDetails),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.Anonymous, UserRole.UserPaymentNeeded], redirectPages: profileSubscriptionRedirectPages }, { children: _jsx(SubscriptionDetailsAnonymousPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.PlanInformation),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(PlanInformationPage, {}) }))),
    },
    {
        path: "".concat(AppRoutes.LanguageAndUnitOfMeasure),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.CompleteUser], redirectPages: profileRedirectPages }, { children: _jsx(LanguageAndUnitOfMeasurePage, {}) }))),
    },
];
export default profileRoutes;
