var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useGetCustomerApi, useGetFamilyApi, useGetPersonApi, } from '../../hooks';
import { LoaderType } from '../../hooks/api/utils';
import { useAddRecipeToPlanApi } from '../../hooks/api/explore/useAddRecipeToPlan.api';
import { useFamilyAndGuestsManagerFacade } from '../familyAndGuestsManager/useFamilyAndGuestsManagerFacade';
export var useAddRecipeToMemberPlanFacade = function (_a) {
    var selectedDate = _a.selectedDate, recipeId = _a.recipeId;
    var customer = useGetCustomerApi(true, LoaderType.FullPage).data;
    var personLeader = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId, LoaderType.FullPage).data;
    var _b = useGetFamilyApi(personLeader === null || personLeader === void 0 ? void 0 : personLeader.familyId, !!personLeader, LoaderType.FullPage), family = _b.data, isLoading = _b.isLoading;
    var _c = useAddRecipeToPlanApi(), addRecipeToPlan = _c.mutateAsync, isLoadingAddRecipeToPlan = _c.isLoading;
    var _d = useFamilyAndGuestsManagerFacade(), guests = _d.guests, selectedMembers = _d.selectedMembers, isConfirmDisabled = _d.isConfirmDisabled, getMembersAddedLabel = _d.getMembersAddedLabel, onMemberPress = _d.onMemberPress, onChangeGuest = _d.onChangeGuest, isMemberSelected = _d.isMemberSelected, onAllMembersPress = _d.onAllMembersPress;
    var onConfirm = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!recipeId) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, addRecipeToPlan({
                            peopleId: selectedMembers,
                            recipeId: recipeId,
                            quantity: guests,
                            date: selectedDate,
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, [addRecipeToPlan, guests, recipeId, selectedDate, selectedMembers]);
    return {
        family: family,
        getMembersAddedLabel: getMembersAddedLabel,
        selectedMembers: selectedMembers,
        guests: guests,
        isConfirmDisabled: isConfirmDisabled,
        isLoading: isLoading,
        onMemberPress: onMemberPress,
        onChangeGuest: onChangeGuest,
        onConfirm: onConfirm,
        isLoadingAddRecipeToPlan: isLoadingAddRecipeToPlan,
        isMemberSelected: isMemberSelected,
        onAllMembersPress: onAllMembersPress,
    };
};
