import { GeneralActions, useReminder, useRepositories } from '@planter/common';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
export function useLogout() {
    var authRepo = useRepositories().authRepo;
    var dispatch = useDispatch();
    var queryClient = useQueryClient();
    var resetReminder = useReminder().resetReminder;
    var logout = useCallback(function () {
        resetReminder().then();
        void queryClient.resetQueries();
        void queryClient.cancelQueries();
        dispatch(GeneralActions.setIsPlanCreated(false));
        dispatch(GeneralActions.setIsCountryCompleted(false));
        dispatch(GeneralActions.setIsAuthorized(false));
        authRepo.revokeToken();
    }, [authRepo, dispatch, queryClient, resetReminder]);
    return logout;
}
