var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useController, useForm } from 'react-hook-form';
import { useLanguage } from '../../locale';
import { useMemo } from 'react';
import { useGetPersonApi } from '../../hooks';
import { useGetCustomerApi } from '../../hooks';
import { useFamilyMemberComposer } from './useFamilyMemberComposer.facade';
import { useSelector } from 'react-redux';
import { isFamilyMemberRegisterRequested } from '../../redux';
import { useUpdateProfileHelper } from './helpers/useUpdateProfileHelper';
import { heightValidation } from '../../utils/regex';
export var useInputHeightFormFacade = function (_a) {
    var _b, _c, _d;
    var onNext = _a.onNext, personId = _a.personId, _e = _a.isFamilyMemberUpdate, isFamilyMemberUpdate = _e === void 0 ? false : _e;
    var t = useLanguage()[0];
    //** REDUX **//
    var isNewFamilyMember = useSelector(isFamilyMemberRegisterRequested);
    var isFamilyMember = isNewFamilyMember || isFamilyMemberUpdate;
    //** API **//
    var customer = useGetCustomerApi(!isFamilyMember).data;
    var person = useGetPersonApi(personId || (customer === null || customer === void 0 ? void 0 : customer.personId)).data;
    var profile = useFamilyMemberComposer().profile;
    var updateProfile = useUpdateProfileHelper({
        isFamilyMember: isNewFamilyMember,
        person: person,
        customer: customer,
    }).updateProfile;
    var _f = useForm({
        mode: 'onSubmit',
    }), control = _f.control, handleSubmit = _f.handleSubmit, clearErrors = _f.clearErrors;
    var _g = useController({
        name: 'height',
        defaultValue: isNewFamilyMember
            ? (_b = profile.height) === null || _b === void 0 ? void 0 : _b.toString()
            : (_c = person === null || person === void 0 ? void 0 : person.height) === null || _c === void 0 ? void 0 : _c.toString(),
        control: control,
        rules: {
            required: {
                value: true,
                message: t('memberRegistry.inputHeightRequiredError'),
            },
            pattern: {
                value: heightValidation,
                message: t('memberRegistry.inputNameValidationError'),
            },
            min: {
                value: 50,
                message: t('memberRegistry.inputHeightMinError'),
            },
            max: {
                value: 220,
                message: t('memberRegistry.inputHeightMaxError'),
            },
        },
    }), field = _g.field, fieldState = _g.fieldState;
    var value = field.value, onChange = field.onChange;
    //** DATA **//
    var updatePersonHeight = useMemo(function () {
        return handleSubmit(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateProfile({ height: formData.height })];
                    case 1:
                        _a.sent();
                        onNext === null || onNext === void 0 ? void 0 : onNext();
                        return [2 /*return*/];
                }
            });
        }); });
    }, [handleSubmit, updateProfile, onNext]);
    return {
        value: value,
        setValue: onChange,
        errorMessage: (_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message,
        updatePersonHeight: updatePersonHeight,
        isFamilyMember: isFamilyMember,
        clearErrors: clearErrors,
    };
};
