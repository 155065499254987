import { getLowestFraction } from '@planter/common';
import { getMeasurementUnit } from './getMeasurementUnit';
export function getCorrectIngredientQuantityForEachMember(_a) {
    var ingredient = _a.ingredient, showPortion = _a.showPortion, formatNumber = _a.formatNumber;
    var portion = 'portion' in ingredient ? ingredient === null || ingredient === void 0 ? void 0 : ingredient.portion : ingredient.quantity;
    var unitOfMeasure = getMeasurementUnit(ingredient, showPortion ? portion : undefined);
    var integerQuantityPart = formatNumber
        ? Number(formatNumber(Math.floor(showPortion ? Number(portion) : ingredient.quantity)))
        : Math.floor(showPortion ? Number(portion) : ingredient.quantity | 0);
    var decimalQuantityPart = (!showPortion ? ingredient.quantity : Number(portion)) -
        integerQuantityPart;
    var fractionalValue = getLowestFraction(decimalQuantityPart);
    if (integerQuantityPart && decimalQuantityPart === 0) {
        return integerQuantityPart + ' ' + unitOfMeasure;
    }
    if (integerQuantityPart && decimalQuantityPart > 0) {
        return integerQuantityPart + ' ' + unitOfMeasure + ' e ' + fractionalValue;
    }
    if (!integerQuantityPart && decimalQuantityPart > 0) {
        return fractionalValue + ' ' + unitOfMeasure;
    }
    if (!integerQuantityPart && isNaN(decimalQuantityPart)) {
        return unitOfMeasure;
    }
}
