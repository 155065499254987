import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import { useUserRole } from '../../contexts/UserRoleContext/useUserRole';
import { AppRoutes } from '../routes';
var RouteAuthorizer = function (_a) {
    var userRoles = _a.userRoles, redirectPages = _a.redirectPages, children = _a.children, externalRedirectPage = _a.externalRedirectPage;
    var userRole = useUserRole();
    var params = useParams();
    var isAuthorized = userRoles.includes(userRole);
    var redirectPage = useMemo(function () {
        var _a;
        var redirectPageByUserRole = redirectPages.find(function (rp) { return rp.userRole === userRole; });
        return (_a = redirectPageByUserRole === null || redirectPageByUserRole === void 0 ? void 0 : redirectPageByUserRole.page) !== null && _a !== void 0 ? _a : AppRoutes.NotFound;
    }, [redirectPages, userRole]);
    var newPath = composePathWithParams(externalRedirectPage, params) || externalRedirectPage;
    if (!isAuthorized && newPath) {
        window.location.replace(newPath);
        return _jsx(_Fragment, {});
    }
    else if (!isAuthorized) {
        var to = composePathWithParams(redirectPage, params) || redirectPage;
        return _jsx(Navigate, { to: to + window.location.search, replace: true });
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
var composePathWithParams = function (path, params, fallback) {
    if (fallback === void 0) { fallback = undefined; }
    var newPath = undefined;
    if (path) {
        try {
            newPath = generatePath(path, params);
        }
        catch (e) {
            newPath = path;
        }
    }
    return newPath || fallback;
};
export default RouteAuthorizer;
