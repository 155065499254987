var FiltersRepository = /** @class */ (function () {
    function FiltersRepository(httpClient) {
        var _this = this;
        this.httpClient = httpClient;
        this.getCategories = function () {
            return _this.httpClient.get("/categories", {
                isAuthorized: true,
            });
        };
        this.getFromWorld = function () {
            return _this.httpClient.get("/cuisine", {
                isAuthorized: true,
            });
        };
    }
    return FiltersRepository;
}());
export { FiltersRepository };
