var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAddRecipeToMemberPlanFacade, useLanguage } from '@planter/common';
import moment from 'moment';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import PlSingleDatePicker from '../../../components/datePicker/PlSingleDatePicker';
import BackIcon from '../../../components/icons/BackIcon';
import CircleCloseIcon from '../../../components/icons/CircleCloseIcon';
import PlButton from '../../../components/PlButton';
import GenericDrawer from '../../../components/PlGenericDrawer';
import PlIconButtonContainer from '../../../components/PlIconButtonContainer';
import PlMembersSelect from '../../../components/PlMembersSelect';
import PlText from '../../../components/PlText';
import PlTitle, { TitleSizeType } from '../../../components/PlTitle';
var DATE_FORMAT = 'YYYY-MM-DD';
var AddRecipeToPlan = function (_a) {
    var showDrawer = _a.showDrawer, onCloseDrawer = _a.onCloseDrawer;
    var t = useLanguage()[0];
    var recipeId = useParams().recipeId;
    var _b = React.useState(true), showDateSection = _b[0], setShowDateSection = _b[1];
    var _c = React.useState(moment()), date = _c[0], setDate = _c[1];
    var _d = useAddRecipeToMemberPlanFacade({
        selectedDate: date.format(DATE_FORMAT),
        recipeId: recipeId,
    }), selectedMembers = _d.selectedMembers, family = _d.family, getMembersAddedLabel = _d.getMembersAddedLabel, guests = _d.guests, isConfirmDisabled = _d.isConfirmDisabled, onMemberPress = _d.onMemberPress, onAllMembersPress = _d.onAllMembersPress, onConfirm = _d.onConfirm, onChangeGuest = _d.onChangeGuest;
    var onAddRecipe = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onConfirm()];
                case 1:
                    _a.sent();
                    onCloseDrawer(true);
                    return [2 /*return*/];
            }
        });
    }); }, [onCloseDrawer, onConfirm]);
    var onConfirmPress = React.useCallback(function () {
        showDateSection ? setShowDateSection(false) : onAddRecipe();
    }, [onAddRecipe, showDateSection]);
    var onBackOrClose = React.useCallback(function () {
        showDateSection ? onCloseDrawer(false) : setShowDateSection(true);
    }, [onCloseDrawer, showDateSection]);
    return (_jsx(GenericDrawer, __assign({ title: t('recipeDetail.addToPlan'), onCloseDrawer: function () { return onCloseDrawer(false); }, showDrawer: showDrawer, leftHeaderComponent: _jsx(PlIconButtonContainer, __assign({ onClick: onBackOrClose }, { children: showDateSection ? _jsx(CircleCloseIcon, {}) : _jsx(BackIcon, { size: "10px" }) })), rightHeaderComponent: _jsx("button", __assign({ onClick: onConfirmPress, className: "p-0", disabled: isConfirmDisabled }, { children: _jsx(PlText, __assign({ className: "text-blue" }, { children: t('done') })) })), footerContent: _jsxs("div", __assign({ className: "px-8 py-3 flex border-t items-center  ".concat(showDateSection ? 'justify-end' : 'justify-between') }, { children: [!showDateSection && (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx(PlText, __assign({ className: "font-medium" }, { children: getMembersAddedLabel })), _jsx(PlText, __assign({ className: "text-grey text-xs" }, { children: t('recipeDetail.addedToPlan') }))] }))), _jsx(PlButton, { onClick: onConfirmPress, label: t('continue'), className: "w-36", isDisabled: !showDateSection && isConfirmDisabled })] })) }, { children: showDateSection ? (_jsxs("div", __assign({ className: "flex flex-wrap justify-center pt-5" }, { children: [_jsx(PlTitle, { label: t('recipeDetail.planDateSelectTitle'), size: TitleSizeType.VeryLarge, className: "font-semibold pb-5 px-5" }), _jsx(PlSingleDatePicker, { selectedDate: date, onDateChange: setDate })] }))) : (_jsx(PlMembersSelect, { members: family === null || family === void 0 ? void 0 : family.Person, title: t('recipeDetail.selectMembersTitle'), selectedMembers: selectedMembers, onMemberCardClick: onMemberPress, onGuestsChange: onChangeGuest, guests: guests, showSelectAll: true, onSelectAll: onAllMembersPress })) })));
};
export default AddRecipeToPlan;
