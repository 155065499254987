import { isFamilyMemberRegisterRequested } from '../../redux';
import { useMemo } from 'react';
import { useLanguage } from '../../locale';
import dayjs from 'dayjs';
import { useGetCustomerApi } from '@planter/common';
import { useSelector } from 'react-redux';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);
dayjs.updateLocale('it', {
    months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ],
});
export var UserInfoEnum;
(function (UserInfoEnum) {
    UserInfoEnum["NameUpdate"] = "nameUpdate";
    UserInfoEnum["BirthdayUpdate"] = "birthdayUpdate";
    UserInfoEnum["GenderUpdate"] = "genderUpdate";
    UserInfoEnum["GrowthSpeedUpdate"] = "growthSpeedUpdate";
    UserInfoEnum["HeightUpdate"] = "heightUpdate";
    UserInfoEnum["WorkoutUpdate"] = "workoutUpdate";
    UserInfoEnum["AllergiesUpdate"] = "allergiesUpdate";
    UserInfoEnum["DislikedIngredientsUpdate"] = "dislikedIngredientsUpdate";
    UserInfoEnum["RecommendedPortionUpdate"] = "recommendedPortionUpdate";
})(UserInfoEnum || (UserInfoEnum = {}));
export var useEditProfileInfoFacade = function (person) {
    //** REDUX **//
    var isNewFamilyMember = useSelector(isFamilyMemberRegisterRequested);
    var customer = useGetCustomerApi(!isNewFamilyMember).data;
    var _a = useLanguage(), t = _a[0], locale = _a[2];
    var name = useMemo(function () {
        if (!(person === null || person === void 0 ? void 0 : person.name)) {
            return t('memberProfile.emptyItem');
        }
        return person.name;
    }, [person === null || person === void 0 ? void 0 : person.name, t]);
    var birthDate = useMemo(function () {
        if (!(person === null || person === void 0 ? void 0 : person.birthDate)) {
            return t('memberProfile.emptyItem');
        }
        var formatDate = locale === 'us' ? 'MM-DD-YYYY' : 'DD MMMM YYYY';
        return dayjs(person === null || person === void 0 ? void 0 : person.birthDate, 'YYYY/MM/DD')
            .locale(locale)
            .format(formatDate);
    }, [locale, person === null || person === void 0 ? void 0 : person.birthDate, t]);
    var labelsConjugationCondition = (customer === null || customer === void 0 ? void 0 : customer.personId) === (person === null || person === void 0 ? void 0 : person.id);
    var gender = useMemo(function () {
        var _a;
        var personValue = (_a = person === null || person === void 0 ? void 0 : person.gender) === null || _a === void 0 ? void 0 : _a.match(/F_TO_M|M_TO_F|NONE|F|M/gi);
        return personValue === null || personValue === void 0 ? void 0 : personValue.map(function (item) {
            switch (item) {
                case 'F':
                    return t('memberRegistry.inputGenderSecondOptionLabel');
                case 'M':
                    return t('memberRegistry.inputGenderFirstOptionLabel');
                case 'F_TO_M':
                    return t('memberRegistry.inputGenderExpandableFirstOptionLabel');
                case 'M_TO_F':
                    return t('memberRegistry.inputGenderExpandableSecondOptionLabel');
                case 'NONE':
                    return labelsConjugationCondition
                        ? t('memberRegistry.inputGenderExpandableThirdOptionLabel')
                        : t('memberRegistry.inputGenderExpandableThirdOptionLabelFamilyMember');
                default:
                    return t('memberProfile.emptyItem');
            }
        });
    }, [labelsConjugationCondition, person === null || person === void 0 ? void 0 : person.gender, t]);
    var growthSpeed = useMemo(function () {
        var _a;
        var personValue = (_a = person === null || person === void 0 ? void 0 : person.growthSpeed) === null || _a === void 0 ? void 0 : _a.match(/UNDER|REGULAR|OVER/gi);
        return personValue === null || personValue === void 0 ? void 0 : personValue.map(function (item) {
            switch (item) {
                case 'UNDER':
                    return t('memberRegistry.inputFamilyGrowthSpeedFirstOptionLabel');
                case 'REGULAR':
                    return t('memberRegistry.inputFamilyGrowthSpeedSecondOptionLabel');
                case 'OVER':
                    return t('memberRegistry.inputFamilyGrowthSpeedThirdOptionLabel');
                default:
                    return t('memberProfile.emptyItem');
            }
        });
    }, [person === null || person === void 0 ? void 0 : person.growthSpeed, t]);
    var height = useMemo(function () {
        if (!(person === null || person === void 0 ? void 0 : person.height)) {
            return undefined;
        }
        return "".concat(person.height, " cm");
    }, [person === null || person === void 0 ? void 0 : person.height]);
    var activityId = useMemo(function () {
        var _a;
        var personValue = (_a = person === null || person === void 0 ? void 0 : person.activitiesId) === null || _a === void 0 ? void 0 : _a.match(/cl7uk2y2m00003b6ljxmhmmfr|cl7yy4lxa00023b6l1gzgp5m5|cl7yy4lxa00043b6l3919w3v7|cl7yy4lxa00063b6l06w5b937/gi);
        return personValue === null || personValue === void 0 ? void 0 : personValue.map(function (item) {
            switch (item) {
                case 'cl7uk2y2m00003b6ljxmhmmfr':
                    return labelsConjugationCondition
                        ? t('memberRegistry.inputPhysicalActivityFirstOptionLabel')
                        : t('memberRegistry.inputFamilyPhysicalActivityFirstOptionLabel');
                case 'cl7yy4lxa00023b6l1gzgp5m5':
                    return labelsConjugationCondition
                        ? t('memberRegistry.inputPhysicalActivitySecondOptionLabel')
                        : t('memberRegistry.inputFamilyPhysicalActivitySecondOptionLabel');
                case 'cl7yy4lxa00043b6l3919w3v7':
                    return labelsConjugationCondition
                        ? t('memberRegistry.inputPhysicalActivityThirdOptionLabel')
                        : t('memberRegistry.inputFamilyPhysicalActivityThirdOptionLabel');
                case 'cl7yy4lxa00063b6l06w5b937':
                    return labelsConjugationCondition
                        ? t('memberRegistry.inputPhysicalActivityFourthOptionLabel')
                        : t('memberRegistry.inputFamilyPhysicalActivityFourthOptionLabel');
                default:
                    return t('memberProfile.emptyItem');
            }
        });
    }, [labelsConjugationCondition, person === null || person === void 0 ? void 0 : person.activitiesId, t]);
    var commonAllergies = useMemo(function () {
        var _a;
        var personValue = (_a = person === null || person === void 0 ? void 0 : person.commonAllergies) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item; }).toString();
        var personLabel = personValue === null || personValue === void 0 ? void 0 : personValue.match(/GLUTEN|SOY|DRIED_FRUIT/gi);
        return personLabel === null || personLabel === void 0 ? void 0 : personLabel.map(function (item, index) {
            var spaceOrBlank = index === 0 ? '' : ' ';
            switch (item) {
                case 'GLUTEN':
                    return (spaceOrBlank + t('memberRegistry.inputAllergiesFirstOptionLabel'));
                case 'SOY':
                    return (spaceOrBlank + t('memberRegistry.inputAllergiesSecondOptionLabel'));
                case 'DRIED_FRUIT':
                    return (spaceOrBlank + t('memberRegistry.inputAllergiesThirdOptionLabel'));
                default:
                    return undefined;
            }
        });
    }, [person === null || person === void 0 ? void 0 : person.commonAllergies, t]);
    var allergies = useMemo(function () {
        var _a, _b;
        if (!(person === null || person === void 0 ? void 0 : person.allergies) || ((_a = person === null || person === void 0 ? void 0 : person.allergies) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return;
        }
        return (_b = person === null || person === void 0 ? void 0 : person.allergies) === null || _b === void 0 ? void 0 : _b.map(function (item, index) {
            var spaceOrBlank = index === 0 ? '' : ' \b';
            return spaceOrBlank + item.Ingredient.name.toString();
        });
    }, [person === null || person === void 0 ? void 0 : person.allergies]);
    var allergyShowItem = useMemo(function () {
        var allergiesShow = [''];
        if (!allergies && !commonAllergies) {
            return t('memberProfile.emptyAllergies');
        }
        if (allergies && commonAllergies) {
            allergiesShow.push(allergies.concat(' ').toString());
        }
        else if (allergies) {
            allergiesShow.push(allergies.concat().toString());
        }
        if (commonAllergies) {
            allergiesShow.push(commonAllergies.toString());
        }
        return allergiesShow;
    }, [allergies, commonAllergies, t]);
    var excludedIngredients = useMemo(function () {
        var _a;
        if (!(person === null || person === void 0 ? void 0 : person.excludedIngredients) ||
            person.excludedIngredients.length === 0) {
            return t('memberProfile.emptyItem');
        }
        return (_a = person === null || person === void 0 ? void 0 : person.excludedIngredients) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
            var spaceOrBlank = index === 0 ? '' : '\b';
            return spaceOrBlank + item.Ingredient.name.toString();
        });
    }, [person === null || person === void 0 ? void 0 : person.excludedIngredients, t]);
    var portion = useMemo(function () {
        var suggestedPortion = (person === null || person === void 0 ? void 0 : person.calorieRange) || 0;
        var customPortion = (person === null || person === void 0 ? void 0 : person.customCalorieRange) || 0;
        var difference = suggestedPortion - customPortion;
        switch (difference) {
            case -300:
                return t('memberRegistry.portionValues.portionEatMuchMore');
            case -200:
                return t('memberRegistry.portionValues.portionEatMore');
            case -100:
                return t('memberRegistry.portionValues.portionEatBitMore');
            case 0:
                return t('memberRegistry.portionValues.portionEatStandard');
            case 100:
                return t('memberRegistry.portionValues.portionEatBitLess');
            case 200:
                return t('memberRegistry.portionValues.portionEatLess');
            case 300:
                return t('memberRegistry.portionValues.portionEatMuchLess');
            default:
                return t('memberRegistry.portionValues.portionEatStandard');
        }
    }, [person === null || person === void 0 ? void 0 : person.calorieRange, person === null || person === void 0 ? void 0 : person.customCalorieRange, t]);
    return useMemo(function () {
        return [
            {
                id: UserInfoEnum.NameUpdate,
                title: t('memberRegistry.inputNamePlaceholder'),
                value: name,
            },
            {
                id: UserInfoEnum.BirthdayUpdate,
                title: t('memberProfile.titleBirthDate'),
                value: birthDate,
            },
            {
                id: UserInfoEnum.GenderUpdate,
                title: t('memberProfile.titleGender'),
                value: gender === null || gender === void 0 ? void 0 : gender.toString(),
            },
            {
                id: UserInfoEnum.GrowthSpeedUpdate,
                title: t('memberProfile.growthSpeed'),
                value: growthSpeed === null || growthSpeed === void 0 ? void 0 : growthSpeed.toString(),
            },
            {
                id: UserInfoEnum.HeightUpdate,
                title: t('memberProfile.height'),
                value: height,
            },
            {
                id: UserInfoEnum.WorkoutUpdate,
                title: t('memberProfile.titleActivity'),
                value: activityId === null || activityId === void 0 ? void 0 : activityId.toString(),
            },
            {
                id: UserInfoEnum.AllergiesUpdate,
                title: t('memberProfile.titleAllergies'),
                value: Array.isArray(allergyShowItem)
                    ? allergyShowItem.join('')
                    : allergyShowItem,
            },
            {
                id: UserInfoEnum.DislikedIngredientsUpdate,
                title: t('memberProfile.excludedIngredients'),
                value: Array.isArray(excludedIngredients)
                    ? excludedIngredients.join(', ')
                    : excludedIngredients,
            },
            {
                id: UserInfoEnum.RecommendedPortionUpdate,
                title: t('memberProfile.portion'),
                value: portion,
            },
        ];
    }, [
        activityId,
        allergyShowItem,
        birthDate,
        excludedIngredients,
        gender,
        growthSpeed,
        height,
        name,
        portion,
        t,
    ]);
};
