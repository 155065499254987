export var RecipeImageDevice;
(function (RecipeImageDevice) {
    RecipeImageDevice["DESKTOP"] = "DESKTOP";
    RecipeImageDevice["MOBILE"] = "MOBILE";
    RecipeImageDevice["THUMBNAIL"] = "THUMBNAIL";
})(RecipeImageDevice || (RecipeImageDevice = {}));
export var RecipeDetailsType;
(function (RecipeDetailsType) {
    RecipeDetailsType["Explore"] = "explore";
    RecipeDetailsType["Plan"] = "plan";
})(RecipeDetailsType || (RecipeDetailsType = {}));
export var RecipeMealType;
(function (RecipeMealType) {
    RecipeMealType[RecipeMealType["BREAKFAST"] = 0] = "BREAKFAST";
    RecipeMealType[RecipeMealType["MORNING_SNACK"] = 1] = "MORNING_SNACK";
    RecipeMealType[RecipeMealType["LUNCH"] = 2] = "LUNCH";
    RecipeMealType[RecipeMealType["SNACK"] = 3] = "SNACK";
    RecipeMealType[RecipeMealType["DINNER"] = 4] = "DINNER";
})(RecipeMealType || (RecipeMealType = {}));
export var ExploreCategoryType;
(function (ExploreCategoryType) {
    ExploreCategoryType["FEATURED_CONTENT"] = "featured-content";
    ExploreCategoryType["SEASONAL_RECIPES"] = "seasonal-recipes";
    ExploreCategoryType["SUNDAY_RECIPES"] = "sunday-recipes";
    ExploreCategoryType["FAVOURITE"] = "favourite";
})(ExploreCategoryType || (ExploreCategoryType = {}));
export var PaginatedRecipeSearchType;
(function (PaginatedRecipeSearchType) {
    PaginatedRecipeSearchType["SEARCH_PAGE"] = "search-page";
    PaginatedRecipeSearchType["PLAN_RECIPE"] = "plan-recipe";
    PaginatedRecipeSearchType["PLAN_MEAL"] = "plan-meal";
})(PaginatedRecipeSearchType || (PaginatedRecipeSearchType = {}));
export var NutrientTypeName;
(function (NutrientTypeName) {
    NutrientTypeName[NutrientTypeName["protein"] = 0] = "protein";
    NutrientTypeName[NutrientTypeName["fruit"] = 1] = "fruit";
    NutrientTypeName[NutrientTypeName["vegetable"] = 2] = "vegetable";
    NutrientTypeName[NutrientTypeName["cereal"] = 3] = "cereal";
    NutrientTypeName[NutrientTypeName["fats"] = 4] = "fats";
})(NutrientTypeName || (NutrientTypeName = {}));
