var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useMemo } from 'react';
import { FiltersSection, PreparationTime, } from '../../models';
import { useSearchFilters } from './useSearchFilters';
import { useLanguage } from '../../locale';
import { useFiltersSectionFacade } from './useFiltersSection.facade';
export var useFiltersResultFacade = function () {
    var t = useLanguage()[0];
    var _a = useSearchFilters(), selectedIsSeasonalFilter = _a.selectedIsSeasonalFilter, selectedCategoriesFilter = _a.selectedCategoriesFilter, selectedFromWorldFilter = _a.selectedFromWorldFilter, selectedIngredientFilter = _a.selectedIngredientFilter, selectedMacroNutrientsFilter = _a.selectedMacroNutrientsFilter, selectedIsFavouritesFilter = _a.selectedIsFavouritesFilter, setFilterIngredient = _a.setFilterIngredient, setPreparationTimeToFilter = _a.setPreparationTimeToFilter, setFilterCategories = _a.setFilterCategories, setFilterFromWorld = _a.setFilterFromWorld, setIsSeasonalToFilter = _a.setIsSeasonalToFilter, setFilterMacroNutrients = _a.setFilterMacroNutrients, setIsFavouritesToFilter = _a.setIsFavouritesToFilter, selectedPreparationTimeFilter = _a.selectedPreparationTimeFilter, apply = _a.apply;
    var _b = useFiltersSectionFacade(), isSeasonalFilterText = _b.isSeasonalFilterText, isFavouriteFilterText = _b.isFavouriteFilterText;
    var translateSelectedPreparationTime = useMemo(function () {
        switch (selectedPreparationTimeFilter) {
            case PreparationTime.Small:
                return t('filters.chips.preparationTime.short');
            case PreparationTime.Medium:
                return t('filters.chips.preparationTime.medium');
            case PreparationTime.Long:
                return t('filters.chips.preparationTime.long');
            default:
                return '';
        }
    }, [selectedPreparationTimeFilter, t]);
    var getCompleteSelectedFiltersCategories = useCallback(function (categories) {
        return categories.reduce(function (result, category) {
            if (category.child.length > 0) {
                return __spreadArray(__spreadArray([], result, true), getCompleteSelectedFiltersCategories(category.child), true);
            }
            return __spreadArray(__spreadArray([], result, true), [category], false);
        }, []);
    }, []);
    var completeSelectedFiltersCategories = getCompleteSelectedFiltersCategories(selectedCategoriesFilter || []);
    var onRemoveFilter = useCallback(function (filterSection, singleElement) {
        switch (filterSection) {
            case FiltersSection.PreparationTime:
                setPreparationTimeToFilter(null);
                break;
            case FiltersSection.Ingredients:
                if (singleElement)
                    setFilterIngredient((selectedIngredientFilter === null || selectedIngredientFilter === void 0 ? void 0 : selectedIngredientFilter.filter(function (selectedIngredient) {
                        return selectedIngredient.id !== singleElement.id;
                    })) || []);
                break;
            case FiltersSection.Categories:
                if (singleElement)
                    setFilterCategories((completeSelectedFiltersCategories === null || completeSelectedFiltersCategories === void 0 ? void 0 : completeSelectedFiltersCategories.filter(function (selectedCategory) { return selectedCategory.id !== singleElement.id; })) || []);
                break;
            case FiltersSection.FromWorld:
                if (singleElement)
                    setFilterFromWorld((selectedFromWorldFilter === null || selectedFromWorldFilter === void 0 ? void 0 : selectedFromWorldFilter.filter(function (selectedFromWorld) { return selectedFromWorld.id !== singleElement.id; })) || []);
                break;
            case FiltersSection.Seasonal:
                setIsSeasonalToFilter(null);
                break;
            case FiltersSection.MacroNutrients:
                if (singleElement)
                    setFilterMacroNutrients((selectedMacroNutrientsFilter === null || selectedMacroNutrientsFilter === void 0 ? void 0 : selectedMacroNutrientsFilter.filter(function (selectedMacroNutrient) {
                        return selectedMacroNutrient.id !== singleElement.id;
                    })) || []);
                break;
            case FiltersSection.Favourites:
                setIsFavouritesToFilter(null);
                break;
            default:
                break;
        }
        apply();
    }, [
        apply,
        completeSelectedFiltersCategories,
        selectedFromWorldFilter,
        selectedIngredientFilter,
        selectedMacroNutrientsFilter,
        setFilterCategories,
        setFilterFromWorld,
        setFilterIngredient,
        setFilterMacroNutrients,
        setIsFavouritesToFilter,
        setIsSeasonalToFilter,
        setPreparationTimeToFilter,
    ]);
    var allFilters = useMemo(function () { return [
        {
            filterType: selectedPreparationTimeFilter,
            onRemoveParams: FiltersSection.PreparationTime,
            label: translateSelectedPreparationTime,
        },
        {
            filterType: selectedIngredientFilter,
            onRemoveParams: FiltersSection.Ingredients,
        },
        {
            filterType: completeSelectedFiltersCategories,
            onRemoveParams: FiltersSection.Categories,
        },
        {
            filterType: selectedFromWorldFilter,
            onRemoveParams: FiltersSection.FromWorld,
        },
        {
            filterType: selectedIsSeasonalFilter,
            onRemoveParams: FiltersSection.Seasonal,
            label: "".concat(t('filters.general.seasonalRecipes') + ': ' + isSeasonalFilterText),
        },
        {
            filterType: selectedMacroNutrientsFilter,
            onRemoveParams: FiltersSection.MacroNutrients,
        },
        {
            filterType: selectedIsFavouritesFilter,
            onRemoveParams: FiltersSection.Favourites,
            label: "".concat(t('filters.general.preferred') + ': ' + isFavouriteFilterText),
        },
    ]; }, [
        selectedPreparationTimeFilter,
        translateSelectedPreparationTime,
        selectedIngredientFilter,
        completeSelectedFiltersCategories,
        selectedFromWorldFilter,
        selectedIsSeasonalFilter,
        t,
        isSeasonalFilterText,
        selectedMacroNutrientsFilter,
        selectedIsFavouritesFilter,
        isFavouriteFilterText,
    ]);
    var selectedFiltersChips = useMemo(function () {
        return allFilters
            .flat()
            .filter(function (existFilter) { return existFilter.filterType !== null; })
            .map(function (singleFilter) {
            var _a;
            if (Array.isArray(singleFilter.filterType)) {
                return singleFilter.filterType.map(function (item) {
                    return {
                        label: item.name,
                        onPress: function () { return onRemoveFilter(singleFilter.onRemoveParams, item); },
                    };
                });
            }
            return {
                label: (_a = singleFilter.label) !== null && _a !== void 0 ? _a : '',
                onPress: function () { return onRemoveFilter(singleFilter.onRemoveParams); },
            };
        })
            .flat();
    }, [allFilters, onRemoveFilter]);
    return {
        selectedFiltersChips: selectedFiltersChips,
    };
};
