var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
export var IconFavoriteType;
(function (IconFavoriteType) {
    IconFavoriteType[IconFavoriteType["EMPTY"] = 0] = "EMPTY";
    IconFavoriteType[IconFavoriteType["FILLED"] = 1] = "FILLED";
})(IconFavoriteType || (IconFavoriteType = {}));
var FavoriteIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 16 : _b, _c = _a.width, width = _c === void 0 ? 18 : _c, _d = _a.height, height = _d === void 0 ? 16 : _d, color = _a.color, _e = _a.type, type = _e === void 0 ? IconFavoriteType.EMPTY : _e;
    return (_jsx("svg", __assign({ viewBox: "0 0 18 16", width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: type === IconFavoriteType.EMPTY
                ? 'M12.7493 0.5C12.0138 0.500907 11.2892 0.678511 10.6366 1.01786C9.98404 1.35721 9.42252 1.84837 8.99935 2.45C8.57667 1.84787 8.01526 1.35633 7.36255 1.01692C6.70984 0.677516 5.98503 0.500214 5.24935 0.5C4.03377 0.5 2.86798 0.982886 2.00844 1.84243C1.1489 2.70197 0.666016 3.86776 0.666016 5.08333C0.668637 6.42413 1.16392 7.7172 2.05768 8.71667L7.60768 14.8833C7.78397 15.0773 7.9988 15.2323 8.23844 15.3385C8.47808 15.4447 8.73724 15.4997 8.99935 15.5C9.26146 15.4997 9.52062 15.4447 9.76026 15.3385C9.9999 15.2323 10.2147 15.0773 10.391 14.8833L15.941 8.71667C16.8348 7.7172 17.3301 6.42413 17.3327 5.08333C17.3327 4.48144 17.2141 3.88544 16.9838 3.32937C16.7535 2.77329 16.4159 2.26803 15.9903 1.84243C15.5647 1.41683 15.0594 1.07922 14.5033 0.848885C13.9472 0.618551 13.3512 0.5 12.7493 0.5ZM9.83268 5.08333C9.83268 4.30979 10.14 3.56792 10.687 3.02094C11.2339 2.47396 11.9758 2.16667 12.7493 2.16667C13.5229 2.16667 14.2648 2.47396 14.8117 3.02094C15.3587 3.56792 15.666 4.30979 15.666 5.08333C15.6636 6.00745 15.3192 6.89797 14.6993 7.58333L9.14935 13.75C9.13254 13.7746 9.11023 13.7949 9.0842 13.8094C9.05816 13.8239 9.02911 13.8321 8.99935 13.8333C8.97097 13.834 8.94279 13.8284 8.91685 13.8169C8.89091 13.8053 8.86785 13.7882 8.84935 13.7667L3.29935 7.6C2.67561 6.91037 2.33099 6.0132 2.33268 5.08333C2.33268 4.30979 2.63997 3.56792 3.18695 3.02094C3.73393 2.47396 4.4758 2.16667 5.24935 2.16667C6.0229 2.16667 6.76476 2.47396 7.31174 3.02094C7.85873 3.56792 8.16602 4.30979 8.16602 5.08333H9.83268Z'
                : 'M12.7493 0.5C12.0138 0.500907 11.2892 0.678511 10.6366 1.01786C9.98404 1.35721 9.42252 1.84837 8.99935 2.45C8.43939 1.65316 7.64016 1.0554 6.71756 0.743423C5.79497 0.431441 4.79698 0.421454 3.86833 0.714908C2.93967 1.00836 2.12864 1.59 1.55284 2.37547C0.977043 3.16095 0.666419 4.10942 0.666016 5.08333C0.668637 6.42413 1.16392 7.7172 2.05768 8.71667L7.60768 14.8833C7.78397 15.0773 7.9988 15.2323 8.23844 15.3385C8.47808 15.4447 8.73724 15.4997 8.99935 15.5C9.26146 15.4997 9.52062 15.4447 9.76026 15.3385C9.9999 15.2323 10.2147 15.0773 10.391 14.8833L15.941 8.71667C16.8348 7.7172 17.3301 6.42413 17.3327 5.08333C17.3327 3.86776 16.8498 2.70197 15.9903 1.84243C15.1307 0.982886 13.9649 0.5 12.7493 0.5Z', fill: (color !== null && color !== void 0 ? color : type === IconFavoriteType.EMPTY) ? '#1C1C1E' : '#FF9500' }) })));
};
export default FavoriteIcon;
