var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useMemo, useState } from 'react';
import { useLanguage } from '../../locale';
export var useFamilyAndGuestsManagerFacade = function (members, initialGuestsValue, isGuestManagement) {
    if (isGuestManagement === void 0) { isGuestManagement = false; }
    var t = useLanguage()[0];
    var _a = useState(initialGuestsValue || 0), guests = _a[0], setGuests = _a[1];
    var _b = useState([]), selectedMembers = _b[0], setSelectedMembers = _b[1];
    var isConfirmDisabled = useMemo(function () { return !selectedMembers.length; }, [selectedMembers.length]);
    var getMembersAddedLabel = useMemo(function () {
        var label;
        if (isGuestManagement) {
            var memberKey = guests === 1 ? 'plan.guest' : 'plan.guests';
            label = "".concat(guests, " ").concat(t(memberKey));
        }
        else {
            var totalMembers = selectedMembers.length + guests;
            var memberKey = totalMembers === 1
                ? 'addComponents.familyMember'
                : 'addComponents.familyMembers';
            label = "".concat(totalMembers, " ").concat(t(memberKey));
        }
        return label;
    }, [guests, isGuestManagement, selectedMembers.length, t]);
    var onMemberPress = useCallback(function (id) {
        return selectedMembers.includes(id)
            ? setSelectedMembers(selectedMembers.filter(function (x) { return x !== id; }))
            : setSelectedMembers(__spreadArray(__spreadArray([], selectedMembers, true), [id], false));
    }, [selectedMembers]);
    var onAllMembersPress = useCallback(function (members) { return setSelectedMembers(members); }, []);
    var isMemberSelected = useCallback(function (id) { return selectedMembers.includes(id); }, [selectedMembers]);
    var onChangeGuest = useCallback(function (val) { return setGuests(val); }, []);
    var onSelectAllMembers = useCallback(function () {
        var _a;
        setSelectedMembers((_a = members === null || members === void 0 ? void 0 : members.map(function (member) { return member.id; })) !== null && _a !== void 0 ? _a : []);
    }, [members]);
    return {
        guests: guests,
        selectedMembers: selectedMembers,
        isConfirmDisabled: isConfirmDisabled,
        getMembersAddedLabel: getMembersAddedLabel,
        isMemberSelected: isMemberSelected,
        onMemberPress: onMemberPress,
        onChangeGuest: onChangeGuest,
        onAllMembersPress: onAllMembersPress,
        onSelectAllMembers: onSelectAllMembers,
    };
};
