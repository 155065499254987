var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
var PlText = function (_a) {
    var label = _a.label, html = _a.html, _b = _a.className, className = _b === void 0 ? '' : _b, children = _a.children, onClick = _a.onClick, title = _a.title;
    return (_jsx("p", __assign({ dangerouslySetInnerHTML: html ? { __html: html } : undefined, className: "text-sm ".concat(className), onClick: onClick, title: title }, { children: !html ? label !== null && label !== void 0 ? label : children : null })));
};
export default PlText;
