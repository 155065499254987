var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NutrientTypeName, useLanguage } from '@planter/common';
import * as React from 'react';
import CerealsIcon from './icons/CerealsIcon';
import FruitIcon from './icons/FruitIcon';
import GoodFatsIcon from './icons/GoodFatsIcon';
import ProteinIcon from './icons/ProteinIcon';
import VegetablesIcon from './icons/VegetablesIcon';
var PlFoodGroup = function (_a) {
    var foodGroup = _a.foodGroup, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.textClassName, textClassName = _c === void 0 ? '' : _c, _d = _a.iconsClassName, iconsClassName = _d === void 0 ? '' : _d, _e = _a.showText, showText = _e === void 0 ? true : _e, backgroundColor = _a.backgroundColor, iconColor = _a.iconColor, iconSize = _a.iconSize, isCreateMeal = _a.isCreateMeal;
    var t = useLanguage()[0];
    return (_jsxs("div", __assign({ className: "flex ".concat(className) }, { children: [foodGroup === NutrientTypeName.protein && (_jsx("div", __assign({ className: "".concat(iconsClassName) }, { children: _jsx(ProteinIcon, { className: "mr-2", backgroundColor: backgroundColor, iconColor: iconColor, width: iconSize, height: iconSize }) }))), foodGroup === NutrientTypeName.fruit && (_jsx("div", __assign({ className: "".concat(iconsClassName) }, { children: _jsx(FruitIcon, { className: "mr-2", backgroundColor: backgroundColor, iconColor: iconColor, width: iconSize, height: iconSize }) }))), foodGroup === NutrientTypeName.fats && (_jsx("div", __assign({ className: "".concat(iconsClassName) }, { children: _jsx(GoodFatsIcon, { className: "mr-2", backgroundColor: backgroundColor, iconColor: iconColor, width: iconSize, height: iconSize }) }))), foodGroup === NutrientTypeName.vegetable && (_jsx("div", __assign({ className: "".concat(iconsClassName) }, { children: _jsx(VegetablesIcon, { className: "mr-2", backgroundColor: backgroundColor, iconColor: iconColor, width: iconSize, height: iconSize }) }))), foodGroup === NutrientTypeName.cereal && (_jsx("div", __assign({ className: "".concat(iconsClassName) }, { children: _jsx(CerealsIcon, { className: "mr-2", backgroundColor: backgroundColor, iconColor: iconColor, width: iconSize, height: iconSize }) }))), showText &&
                foodGroup === NutrientTypeName.protein &&
                (isCreateMeal ? (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.proteicFood').split(' ').join('\n') }))) : (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.proteicFood') })))), showText &&
                foodGroup === NutrientTypeName.fruit &&
                (isCreateMeal ? (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.fruits').split(' ').join('\n') }))) : (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.fruits') })))), showText &&
                foodGroup === NutrientTypeName.fats &&
                (isCreateMeal ? (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.vegetableFats').split(' ').join('\n') }))) : (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.vegetableFats') })))), showText &&
                foodGroup === NutrientTypeName.vegetable &&
                (isCreateMeal ? (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.vegetables').split(' ').join('\n') }))) : (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.vegetables') })))), showText &&
                foodGroup === NutrientTypeName.cereal &&
                (isCreateMeal ? (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.cereals').split(' ').join('\n') }))) : (_jsx("p", __assign({ className: "".concat(textClassName) }, { children: t('recipeDetail.cereals') }))))] })));
};
export default PlFoodGroup;
