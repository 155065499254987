var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import PlanterBrandLogoWhite from '../icons/PlanterBrandLogoWhite';
import PlLayoutAction from './PlLayoutAction';
var IntroLayout = function (_a) {
    var children = _a.children, header = _a.header, leftAction = _a.leftAction, rightAction = _a.rightAction, _b = _a.showLogo, showLogo = _b === void 0 ? true : _b;
    return (_jsxs("div", __assign({ className: "max-w-screen-lg px-5 mx-auto" }, { children: [showLogo && (_jsx("div", __assign({ className: "py-6" }, { children: _jsx(PlanterBrandLogoWhite, {}) }))), header, _jsxs("div", __assign({ className: "flex w-full" }, { children: [leftAction && (_jsx("div", __assign({ className: "flex-1" }, { children: _jsx(PlLayoutAction, __assign({}, leftAction)) }))), _jsx("div", __assign({ className: "flex-[2]" }, { children: children })), rightAction && (_jsx("div", __assign({ className: "flex flex-1 justify-end" }, { children: _jsx(PlLayoutAction, __assign({}, rightAction)) })))] }))] })));
};
export default IntroLayout;
