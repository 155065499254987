var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useMemo, useState } from 'react';
var familyMemberPlaceholder = {
    name: null,
    birthDate: null,
    gender: null,
    height: null,
    activity: null,
    commonAllergies: null,
    ingredients: null,
    growthSpeed: null,
    allergies: null,
};
var familyMember = __assign({}, familyMemberPlaceholder);
export var useFamilyMemberComposer = function () {
    var _a = useState(familyMember), localProfile = _a[0], setLocalProfile = _a[1];
    var profile = useMemo(function () {
        return localProfile;
    }, [localProfile]);
    var setProfile = useCallback(function (property, callback) {
        setLocalProfile(function (profile) {
            var newProfile = __assign(__assign({}, profile), property);
            familyMember = newProfile;
            if (callback) {
                callback(newProfile);
            }
            return newProfile;
        });
    }, []);
    var resetProfile = useCallback(function () {
        setLocalProfile(__assign({}, familyMemberPlaceholder));
        familyMember = __assign({}, familyMemberPlaceholder);
    }, []);
    return {
        profile: profile,
        setProfile: setProfile,
        resetProfile: resetProfile,
    };
};
