import { configureStore as configureReduxStore, } from '@reduxjs/toolkit';
import { filtersReducer } from './filters/filters.slice';
import { generalReducer } from './general/general.slice';
export var configureStore = function (_a) {
    var _b = _a.middlewares, middlewares = _b === void 0 ? [] : _b, enhancer = _a.enhancer, initialState = _a.initialState;
    var store = configureReduxStore({
        reducer: {
            general: generalReducer,
            filters: filtersReducer,
        },
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware().concat(middlewares);
        },
        enhancers: enhancer ? [enhancer] : [],
        preloadedState: initialState,
    });
    return store;
};
