var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { CognitoError } from '../../models';
var AvoidableErrors = [CognitoError.UsernameExists];
var CognitoHttpClient = /** @class */ (function () {
    function CognitoHttpClient(_a) {
        var axiosInstance = _a.axiosInstance, errorHandler = _a.errorHandler, cognitoRegion = _a.cognitoRegion, t = _a.t;
        var _this = this;
        this.get = function (url, configs) { return __awaiter(_this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axiosInstance.get(url, {
                            params: configs === null || configs === void 0 ? void 0 : configs.params,
                            headers: configs === null || configs === void 0 ? void 0 : configs.headers,
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data];
                }
            });
        }); };
        this.post = function (url, configs) { return __awaiter(_this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axiosInstance.post(url, configs === null || configs === void 0 ? void 0 : configs.body, {
                            headers: configs === null || configs === void 0 ? void 0 : configs.headers,
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data];
                }
            });
        }); };
        this.put = function (url, configs) { return __awaiter(_this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axiosInstance.put(url, configs === null || configs === void 0 ? void 0 : configs.body, {
                            headers: configs === null || configs === void 0 ? void 0 : configs.headers,
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data];
                }
            });
        }); };
        this.patch = function (url, configs) { return __awaiter(_this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axiosInstance.patch(url, configs === null || configs === void 0 ? void 0 : configs.body, {
                            headers: configs === null || configs === void 0 ? void 0 : configs.headers,
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data];
                }
            });
        }); };
        this.delete = function (url, configs) { return __awaiter(_this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axiosInstance.delete(url, {
                            params: configs === null || configs === void 0 ? void 0 : configs.params,
                            headers: configs === null || configs === void 0 ? void 0 : configs.headers,
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data];
                }
            });
        }); };
        this.setupErrorHandler = function (errorHandler) {
            _this.axiosInstance.interceptors.response.use(function (response) { return response; }, function (e) { return __awaiter(_this, void 0, void 0, function () {
                var errorType;
                var _a, _b, _c, _d, _e, _f;
                return __generator(this, function (_g) {
                    console.log(e);
                    errorType = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.__type;
                    // TODO: Show different errors based on the API error
                    if (errorType) {
                        if (!AvoidableErrors.find(function (err) { return errorType === err; })) {
                            errorHandler.nonBlockingInteractionError(e.response.data.message);
                        }
                    }
                    else {
                        errorHandler.nonBlockingInteractionError(this.t('notificationAlert.genericError'));
                    }
                    (_c = errorHandler.sendLog) === null || _c === void 0 ? void 0 : _c.call(errorHandler, {
                        url: (_d = e.config) === null || _d === void 0 ? void 0 : _d.url,
                        data: JSON.stringify((_e = e === null || e === void 0 ? void 0 : e.response) === null || _e === void 0 ? void 0 : _e.data),
                        arguments: (_f = e === null || e === void 0 ? void 0 : e.config) === null || _f === void 0 ? void 0 : _f.data,
                    });
                    throw e === null || e === void 0 ? void 0 : e.response;
                });
            }); });
        };
        this.t = t;
        this.axiosInstance = axiosInstance !== null && axiosInstance !== void 0 ? axiosInstance : axios.create();
        this.axiosInstance.defaults.baseURL = "https://cognito-idp.".concat(cognitoRegion, ".amazonaws.com/");
        this.axiosInstance.defaults.headers.common = {
            'Content-Type': 'application/x-amz-json-1.1',
        };
        this.axiosInstance.defaults.timeout = 30000;
        this.setupErrorHandler(errorHandler);
    }
    return CognitoHttpClient;
}());
export { CognitoHttpClient };
