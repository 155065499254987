var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import authenticationRoutes from './authentication.routes';
import generalRoutes from './general.routes';
import exploreRoutes from './explore.routes';
import profileRoutes from './profile.routes';
import onboardingRoutes from './onboarding.routes';
import planRoutes from './plan.routes';
import listRoutes from './list.routes';
import familyMemberRoutes from './familyMember.routes';
import recipeRoutes from './recipe.routes';
import searchResultRoutes from './searchResult.routes';
import askRoutes from './ask.routes';
import subscriptionRoutes from './subscription.routes';
import checkoutRoutes from './checkout.routes';
import maintenanceRoutes from './maintenance.routes';
import redeemCouponRoutes from './redeemCoupon.routes';
var MainRoutes = function () {
    var routes = useRoutes(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], authenticationRoutes, true), onboardingRoutes, true), familyMemberRoutes, true), generalRoutes, true), planRoutes, true), listRoutes, true), profileRoutes, true), exploreRoutes, true), recipeRoutes, true), searchResultRoutes, true), askRoutes, true), subscriptionRoutes, true), checkoutRoutes, true), maintenanceRoutes, true), redeemCouponRoutes, true), [
        {
            path: '*',
            element: _jsx(Navigate, { to: "/", replace: true }),
        }
    ], false));
    return routes;
};
export default MainRoutes;
