import onboardingWelcomePageImage from './onboarding/welcome-background.png';
import splashScreenImage from './splash-screen.png';
import dishes from './dishes.png';
import maintenanceDishes from './maintenance-dishes.png';
import screenTooSmall from './screen-too-small.png';
export var Images = {
    splashScreen: splashScreenImage,
    onboardingWelcomePage: onboardingWelcomePageImage,
    dishes: dishes,
    maintenanceDishes: maintenanceDishes,
    screenTooSmall: screenTooSmall,
};
