var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
export var TitleSizeType;
(function (TitleSizeType) {
    TitleSizeType["ExtraLarge"] = "extra large";
    TitleSizeType["VeryLarge"] = "very large";
    TitleSizeType["Large"] = "large";
    TitleSizeType["Regular"] = "regular";
    TitleSizeType["Small"] = "small";
    TitleSizeType["VerySmall"] = "very small";
})(TitleSizeType || (TitleSizeType = {}));
var PlTitle = function (_a) {
    var label = _a.label, children = _a.children, _b = _a.size, size = _b === void 0 ? 'regular' : _b, _c = _a.className, className = _c === void 0 ? '' : _c;
    var TitleTypeClass = React.useMemo(function () {
        var finalClasses = '';
        switch (size) {
            case TitleSizeType.ExtraLarge:
                finalClasses += 'text-4xl';
                break;
            case TitleSizeType.VeryLarge:
                finalClasses += 'text-3xl';
                break;
            case TitleSizeType.Large:
                finalClasses += 'text-lg';
                break;
            case TitleSizeType.Regular:
                finalClasses += 'text-base';
                break;
            case TitleSizeType.Small:
                finalClasses += 'text-sm';
                break;
            case TitleSizeType.VerySmall:
                finalClasses += 'text-xs';
                break;
        }
        return finalClasses;
    }, [size]);
    return (_jsx("p", __assign({ className: "".concat(TitleTypeClass, " font-semibold whitespace-pre-line ").concat(className) }, { children: label !== null && label !== void 0 ? label : children })));
};
export default PlTitle;
