var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NutrientTypeName, useLanguage } from '@planter/common';
import * as React from 'react';
import PlFoodGroup from '../../../components/PlFoodGroup';
import PlTitle, { TitleSizeType } from '../../../components/PlTitle';
var FoodGroups = React.memo(function (_a) {
    var hasCereals = _a.hasCereals, hasFruit = _a.hasFruit, hasGoodFats = _a.hasGoodFats, hasProteinFood = _a.hasProteinFood, hasVegetables = _a.hasVegetables;
    var t = useLanguage()[0];
    return (_jsxs(_Fragment, { children: [_jsx(PlTitle, { label: t('recipeDetail.foodGroups'), size: TitleSizeType.Regular }), _jsxs("div", __assign({ className: "flex items-center flex-wrap mt-4" }, { children: [hasCereals && (_jsx(PlFoodGroup, { className: "mr-4", foodGroup: NutrientTypeName.cereal })), hasProteinFood && (_jsx(PlFoodGroup, { className: "mr-4", foodGroup: NutrientTypeName.protein })), hasVegetables && (_jsx(PlFoodGroup, { className: "mr-4", foodGroup: NutrientTypeName.vegetable })), hasFruit && (_jsx(PlFoodGroup, { className: "mr-4", foodGroup: NutrientTypeName.fruit })), hasGoodFats && (_jsx(PlFoodGroup, { className: "mr-4", foodGroup: NutrientTypeName.fats }))] }))] }));
});
export default FoodGroups;
