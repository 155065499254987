var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
var PlanterUserIcon = function (_a) {
    var className = _a.className, color = _a.color, width = _a.width, height = _a.height, _b = _a.size, size = _b === void 0 ? 104 : _b;
    return (_jsxs("svg", __assign({ width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, viewBox: "0 0 104 104", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: [_jsxs("g", __assign({ clipPath: "url(#clip0_2017_174680)" }, { children: [_jsx("path", { d: "M52 97.5C77.129 97.5 97.5 77.129 97.5 52C97.5 26.871 77.129 6.5 52 6.5C26.871 6.5 6.5 26.871 6.5 52C6.5 77.129 26.871 97.5 52 97.5Z", fill: color }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M68.25 42.25C68.25 51.2246 60.9746 58.5 52 58.5C43.0254 58.5 35.75 51.2246 35.75 42.25C35.75 33.2754 43.0254 26 52 26C60.9746 26 68.25 33.2754 68.25 42.25ZM34.0095 69.7595C37.057 66.712 41.1902 65 45.5 65H58.5C60.634 65 62.7471 65.4203 64.7186 66.237C66.6901 67.0536 68.4815 68.2506 69.9905 69.7595C71.4994 71.2685 72.6964 73.0599 73.513 75.0314C74.3297 77.0029 74.75 79.116 74.75 81.25V83.6225C73.71 84.37 72.54 85.15 71.5 85.735C70.46 86.32 69.355 86.905 68.25 87.3925C63.162 89.7678 57.6151 90.9987 52 90.9987C46.3849 90.9987 40.838 89.7678 35.75 87.3925C34.645 86.905 33.605 86.385 32.5 85.735C31.395 85.085 30.29 84.37 29.25 83.6225V81.25C29.25 76.9402 30.962 72.807 34.0095 69.7595Z", fill: "white" })] })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_2017_174680" }, { children: _jsx("rect", { width: "104", height: "104", fill: "white" }) })) })] })));
};
export default PlanterUserIcon;
