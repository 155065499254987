import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT, getWeek, isBetween } from './dates.utils';
export var datesManager = function (formattedDate, format, addFormat) {
    if (format === void 0) { format = DEFAULT_DATE_FORMAT; }
    if (addFormat === void 0) { addFormat = true; }
    var selectedDate = formattedDate !== null && formattedDate !== void 0 ? formattedDate : dayjs().format(format);
    var date = dayjs(selectedDate, addFormat ? format : undefined);
    return {
        date: date,
        toString: function (format) {
            if (format === void 0) { format = DEFAULT_DATE_FORMAT; }
            return date.format(format);
        },
        isBetween: isBetween(date),
        getWeek: function (options) { return getWeek(selectedDate, options); },
        weekOfYear: function () { return date.week(); },
        diff: function (otherDate, unit, floatingPoint) {
            if (floatingPoint === void 0) { floatingPoint = false; }
            return date.diff(otherDate, unit, floatingPoint);
        },
        isSame: function (otherDate, unitType) {
            return date.isSame(otherDate, unitType);
        },
        isBefore: function (otherDate, unitType) {
            return date.isBefore(otherDate, unitType);
        },
        add: function (number, unit) {
            return date.add(number, unit);
        },
    };
};
