var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { extractDeepChildrenIds, extractPreparationTime } from '../../utils';
import { ExploreCategoryType, } from '../../models';
import { useSearchFilters } from './useSearchFilters';
import { useExploreFacade } from '../explore/useExplore.facade';
import { includes } from 'lodash';
import dayjs from 'dayjs';
export var useLocalSearchFilters = function (_a) {
    if (_a === void 0) { _a = {}; }
    var category = _a.category, featuredContentId = _a.featuredContentId, filtersInit = __rest(_a, ["category", "featuredContentId"]);
    var _b = useSearchFilters(filtersInit), selectedIsFavouritesFilter = _b.selectedIsFavouritesFilter, selectedIsSeasonalFilter = _b.selectedIsSeasonalFilter, selectedMacroNutrientsFilter = _b.selectedMacroNutrientsFilter, confirmedFilters = _b.confirmedFilters, filtersMethods = __rest(_b, ["selectedIsFavouritesFilter", "selectedIsSeasonalFilter", "selectedMacroNutrientsFilter", "confirmedFilters"]);
    var _c = React.useState(new Array()), filteredRecipes = _c[0], setFilteredRecipes = _c[1];
    var _d = useExploreFacade(), featuredContents = _d.featuredContents, seasonalRecipes = _d.seasonalRecipes, sundayRecipes = _d.sundayRecipes;
    var selectedFeaturedContent = featuredContents === null || featuredContents === void 0 ? void 0 : featuredContents.find(function (content) { return content.id === featuredContentId; });
    var categoryToFilter = React.useMemo(function () {
        var _a;
        switch (category) {
            case ExploreCategoryType.FEATURED_CONTENT:
                return (_a = featuredContents === null || featuredContents === void 0 ? void 0 : featuredContents.find(function (fc) { return fc.id === featuredContentId; })) === null || _a === void 0 ? void 0 : _a.recipes;
            case ExploreCategoryType.SEASONAL_RECIPES:
                return seasonalRecipes;
            case ExploreCategoryType.SUNDAY_RECIPES:
                return sundayRecipes;
            default:
                return undefined;
        }
    }, [
        category,
        featuredContentId,
        featuredContents,
        seasonalRecipes,
        sundayRecipes,
    ]);
    var categoriesIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.categories) ||
            confirmedFilters.categories.length === 0)
            return undefined;
        return extractDeepChildrenIds(confirmedFilters.categories);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.categories]);
    var fromWorldIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.fromWorld) || confirmedFilters.fromWorld.length === 0)
            return undefined;
        return extractDeepChildrenIds(confirmedFilters.fromWorld);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.fromWorld]);
    var ingredientsIdsToSearch = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.ingredients) ||
            confirmedFilters.ingredients.length === 0)
            return undefined;
        var ingred = confirmedFilters.ingredients.map(function (i) { return i.id; });
        return ingred;
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.ingredients]);
    var preparationTime = React.useMemo(function () {
        if (!(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.preparationTime))
            return undefined;
        return extractPreparationTime(confirmedFilters.preparationTime);
    }, [confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.preparationTime]);
    console.log(confirmedFilters === null || confirmedFilters === void 0 ? void 0 : confirmedFilters.macroNutrients);
    var filterCategory = React.useCallback(function () {
        var tempRecipes = categoryToFilter
            ? __spreadArray([], categoryToFilter, true) : [];
        console.log(tempRecipes);
        if (preparationTime === null || preparationTime === void 0 ? void 0 : preparationTime.max) {
            tempRecipes = tempRecipes === null || tempRecipes === void 0 ? void 0 : tempRecipes.filter(function (r) { return r.preparationTime && r.preparationTime <= preparationTime.max; });
        }
        if (preparationTime === null || preparationTime === void 0 ? void 0 : preparationTime.min) {
            tempRecipes = tempRecipes === null || tempRecipes === void 0 ? void 0 : tempRecipes.filter(function (r) { return r.preparationTime && r.preparationTime >= preparationTime.min; });
        }
        if (categoriesIdsToSearch && categoriesIdsToSearch.length > 0) {
            tempRecipes = tempRecipes.filter(function (r) {
                return includes(categoriesIdsToSearch, r.recipeCategoryId);
            });
        }
        if (fromWorldIdsToSearch && fromWorldIdsToSearch.length > 0) {
            tempRecipes = tempRecipes.filter(function (r) {
                return includes(fromWorldIdsToSearch, r.cuisineTypeId);
            });
        }
        if (ingredientsIdsToSearch && ingredientsIdsToSearch.length > 0) {
            ingredientsIdsToSearch.forEach(function (ingredient) {
                tempRecipes = tempRecipes === null || tempRecipes === void 0 ? void 0 : tempRecipes.filter(function (r) {
                    return includes(r.ingredients, ingredient);
                });
            });
        }
        if (selectedIsSeasonalFilter) {
            tempRecipes = tempRecipes === null || tempRecipes === void 0 ? void 0 : tempRecipes.filter(function (r) {
                if (r.evergreen)
                    return true;
                else {
                    var currentMonth = dayjs().get('M');
                    var startMonth = dayjs(r.seasonalityStartDate).get('M');
                    var endMonth = dayjs(r.seasonalityEndDate).get('M');
                    // Return recipes that have seasonality month start in the same year with the seasonality month end
                    if (startMonth <= endMonth &&
                        startMonth <= currentMonth &&
                        endMonth >= currentMonth)
                        return true;
                    // Return recipes that have seasonality month end in the next year of the seasonality month start
                    if (startMonth > endMonth &&
                        startMonth >= currentMonth &&
                        endMonth >= currentMonth)
                        return true;
                    return false;
                }
            });
        }
        if (selectedMacroNutrientsFilter) {
            var macros_1 = ['cereals', 'proteinFoods', 'vegetables', 'goodFats', 'fruit'];
            tempRecipes = tempRecipes.filter(function (recipe) {
                return selectedMacroNutrientsFilter.every(function (nutrient) {
                    var castedNutrientId = nutrient.id;
                    if (macros_1.includes(castedNutrientId)) {
                        return recipe[castedNutrientId] > 0;
                    }
                    return false;
                });
            });
        }
        if (selectedIsFavouritesFilter) {
            tempRecipes = tempRecipes === null || tempRecipes === void 0 ? void 0 : tempRecipes.filter(function (r) { return r.isFavourite === selectedIsFavouritesFilter; });
        }
        setFilteredRecipes(tempRecipes);
    }, [
        categoryToFilter,
        preparationTime === null || preparationTime === void 0 ? void 0 : preparationTime.max,
        preparationTime === null || preparationTime === void 0 ? void 0 : preparationTime.min,
        categoriesIdsToSearch,
        fromWorldIdsToSearch,
        ingredientsIdsToSearch,
        selectedIsSeasonalFilter,
        selectedMacroNutrientsFilter,
        selectedIsFavouritesFilter,
    ]);
    React.useEffect(function () {
        filterCategory();
    }, [filterCategory]);
    return __assign({ filteredRecipes: filteredRecipes, selectedFeaturedContent: selectedFeaturedContent, selectedIsSeasonalFilter: selectedIsSeasonalFilter, confirmedFilters: confirmedFilters }, filtersMethods);
};
