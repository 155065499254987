import * as React from 'react';
import { useGetRecipePreviewApi } from '../../hooks';
export var useRecipePreviewFacade = function (_a) {
    var recipeId = _a.recipeId;
    var _b = React.useState(), recipeDetail = _b[0], setRecipeDetail = _b[1];
    var _c = useGetRecipePreviewApi(recipeId), recipeResponse = _c.data, isRecipeLoading = _c.isLoading;
    React.useEffect(function () {
        setRecipeDetail(recipeResponse);
    }, [recipeResponse]);
    return {
        recipeDetail: recipeDetail,
        isRecipeLoading: isRecipeLoading,
    };
};
