var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import BackIcon from '../../../components/icons/BackIcon';
import PlLayoutAction from '../../../components/layouts/PlLayoutAction';
import { useWindowSize } from '../../../hooks/useWindowSize';
var RecipeDetailsHeader = React.memo(function (_a) {
    var imgSrc = _a.imgSrc, alt = _a.alt, onBack = _a.onBack;
    var windowWidth = useWindowSize()[0];
    var actionProps = React.useMemo(function () { return ({
        onClick: onBack,
        icon: _jsx(BackIcon, { size: 10 }),
    }); }, [onBack]);
    var isWindowGreaterThanLayout = windowWidth > 1024;
    return imgSrc && alt ? (_jsxs("div", __assign({ className: "w-full h-[576px] relative" }, { children: [_jsx("img", { className: "absolute w-full h-full object-cover -z-10 ".concat(isWindowGreaterThanLayout ? 'rounded-br-2xl rounded-bl-2xl' : ''), src: imgSrc, alt: alt }), _jsx("div", { className: "absolute inset-0 bg-gradient-to-b from-zinc-700/30 to-zinc-700/0 -z-10 ".concat(isWindowGreaterThanLayout ? 'rounded-br-2xl rounded-bl-2xl' : '') }), onBack && (_jsx("div", __assign({ className: "pt-8 pl-8 max-w-fit" }, { children: _jsx(PlLayoutAction, __assign({}, actionProps)) })))] }))) : (_jsx("div", { children: onBack && (_jsx("div", __assign({ className: "p-8 max-w-fit" }, { children: _jsx(PlLayoutAction, __assign({}, actionProps)) }))) }));
});
export default RecipeDetailsHeader;
