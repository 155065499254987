import { jsx as _jsx } from "react/jsx-runtime";
import { useGenerateRandomProfileColor } from '@planter/common';
import * as React from 'react';
import PlanterUserIcon from './icons/PlanterUserIcon';
var PlProfileImage = function (_a) {
    var profileImage = _a.profileImage, profileId = _a.profileId, _b = _a.size, size = _b === void 0 ? '40px' : _b;
    var getPlColorHsl = useGenerateRandomProfileColor(profileId !== null && profileId !== void 0 ? profileId : '').getPlColorHsl;
    return profileImage ? (_jsx("div", { className: "rounded-full bg-center bg-no-repeat bg-cover", style: {
            backgroundImage: "url(".concat(profileImage, ")"),
            width: size,
            height: size,
        } })) : (_jsx(PlanterUserIcon, { size: size, color: getPlColorHsl() }));
};
export default PlProfileImage;
