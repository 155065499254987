export var groupShoppingListByCategory = function (ingredients) {
    if (!ingredients) {
        return [];
    }
    var categories = ingredients.map(function (ingredient) { return ingredient.shoppingListCategory; });
    var uniqueCategories = categories.filter(function (category, index) { return categories.indexOf(category) === index; });
    return uniqueCategories.map(function (category) { return ({
        id: category,
        title: category,
        data: ingredients.filter(function (ingredient) { return ingredient.shoppingListCategory === category; }),
    }); });
};
