var RefreshAuthDelegate = /** @class */ (function () {
    function RefreshAuthDelegate(authRepository) {
        var _this = this;
        this.authRepository = authRepository;
        this.refresh = function (refreshToken) {
            if (_this.waitingPromise) {
                return _this.waitingPromise;
            }
            _this.waitingPromise = _this.authRepository.refreshAccessToken(refreshToken);
            _this.waitingPromise.then(function (_) { return (_this.waitingPromise = null); });
            return _this.waitingPromise;
        };
    }
    return RefreshAuthDelegate;
}());
export { RefreshAuthDelegate };
