export function getMeasurementUnit(ingredient, quantity) {
    var _a, _b;
    var correctQuantity = quantity !== null && quantity !== void 0 ? quantity : ingredient === null || ingredient === void 0 ? void 0 : ingredient.quantity;
    var ingredientUnitOfMeasure;
    if ('ingredientUnitOfMeasure' in ingredient) {
        ingredientUnitOfMeasure = ingredient.ingredientUnitOfMeasure;
    }
    else if ('unitOfMeasure' in ingredient) {
        ingredientUnitOfMeasure = ingredient.unitOfMeasure;
    }
    switch (typeof ingredientUnitOfMeasure) {
        case 'string':
            var ingredientUnitOfMeasureString = ingredientUnitOfMeasure;
            return ingredientUnitOfMeasureString === null || ingredientUnitOfMeasureString === void 0 ? void 0 : ingredientUnitOfMeasureString.toLowerCase();
        case undefined:
            return '';
        case 'object':
            var ingredientUnitOfMeasureObject = ingredientUnitOfMeasure;
            return correctQuantity >= 2
                ? (_a = ingredientUnitOfMeasureObject === null || ingredientUnitOfMeasureObject === void 0 ? void 0 : ingredientUnitOfMeasureObject.plural) === null || _a === void 0 ? void 0 : _a.toLowerCase()
                : (_b = ingredientUnitOfMeasureObject === null || ingredientUnitOfMeasureObject === void 0 ? void 0 : ingredientUnitOfMeasureObject.name) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        default:
            return '';
    }
}
