var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { FamilyRole } from '../../models';
import { differenceBetweenDates, formatDateTo, parseToDayJS, useLanguage, } from '../../locale';
import { useGetPersonApi } from '../../hooks';
import { useGetCustomerApi } from '../../hooks';
import { useFamilyMemberComposer } from './useFamilyMemberComposer.facade';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralActions, isFamilyMemberRegisterRequested } from '../../redux';
import { useUpdateProfileHelper } from './helpers/useUpdateProfileHelper';
import dayjs from 'dayjs';
var MAX_YEAR_ALLOWED = 130;
export var useInputBirthdateFormFacade = function (_a) {
    var _b;
    var onNext = _a.onNext, _c = _a.format, format = _c === void 0 ? 'DD / MM / YYYY' : _c, personId = _a.personId, _d = _a.isFamilyMemberUpdate, isFamilyMemberUpdate = _d === void 0 ? false : _d;
    var _e = useLanguage(), t = _e[0], locale = _e[2];
    //** REDUX **//
    var isNewFamilyMember = useSelector(isFamilyMemberRegisterRequested);
    var isFamilyMember = isNewFamilyMember || isFamilyMemberUpdate;
    var dispatch = useDispatch();
    // ** API ** //
    var customer = useGetCustomerApi(!isFamilyMember).data;
    var person = useGetPersonApi(personId || (customer === null || customer === void 0 ? void 0 : customer.personId)).data;
    var profile = useFamilyMemberComposer().profile;
    var updateProfile = useUpdateProfileHelper({
        isFamilyMember: isNewFamilyMember,
        person: person,
        customer: customer,
    }).updateProfile;
    //** FORM **//
    var _f = useForm({
        mode: 'onSubmit',
    }), control = _f.control, handleSubmit = _f.handleSubmit, clearErrors = _f.clearErrors;
    var _g = useController({
        name: 'birthDate',
        defaultValue: isNewFamilyMember ? profile.birthDate : person === null || person === void 0 ? void 0 : person.birthDate,
        control: control,
        rules: {
            validate: function (possibleDate) {
                if (!possibleDate) {
                    return t('memberRegistry.inputBirthdateRequiredError');
                }
                var difference = differenceBetweenDates({
                    secondDate: possibleDate,
                    secondDateFormat: format,
                    unit: 'month',
                });
                var differenceInYears = differenceBetweenDates({
                    secondDate: possibleDate,
                    secondDateFormat: format,
                    unit: 'year',
                });
                if (differenceInYears > MAX_YEAR_ALLOWED)
                    return t('memberRegistry.inputBirthdateNotValid');
                if (difference < 0) {
                    return t('memberRegistry.inputBirthdateInFuture');
                }
                if (isNewFamilyMember) {
                    var isInfant = difference < 6;
                    return isInfant
                        ? t('memberRegistry.inputBirthdateInfantAgeError')
                        : true;
                }
                if (!isFamilyMember ||
                    (person === null || person === void 0 ? void 0 : person.familyRole) === FamilyRole.FAMILY_LEADER) {
                    var isAdult = difference / 12 >= 18;
                    return !isAdult
                        ? t('memberRegistry.inputBirthdateAdultAgeError')
                        : true;
                }
                // Non si può passare da maggiorenne a minorenne:
                if (isFamilyMemberUpdate) {
                    var wasAdultDifference = differenceBetweenDates({
                        secondDate: person === null || person === void 0 ? void 0 : person.birthDate,
                        secondDateFormat: format,
                        unit: 'month',
                    });
                    var wasAdult = wasAdultDifference / 12 >= 18;
                    var isNowMinor = difference / 12 < 18;
                    if (wasAdult && isNowMinor) {
                        return t('memberRegistry.inputBirthdateAdultToMinorAgeError');
                    }
                }
            },
        },
    }), field = _g.field, fieldState = _g.fieldState;
    var value = field.value, onChange = field.onChange;
    var date = useMemo(function () {
        if (!value) {
            return;
        }
        return parseToDayJS({ date: value, format: format, locale: locale });
    }, [locale, format, value]);
    var onInputBirthdateChange = useCallback(function (value) {
        onChange(value);
        var date = parseToDayJS({ date: value, format: format, locale: locale });
        var difference = differenceBetweenDates({
            firstDate: dayjs(),
            firstDateFormat: format,
            secondDate: date,
            secondDateFormat: format,
            unit: 'y',
        });
        if (difference < 18) {
            dispatch(GeneralActions.setIsFamilyMemberMinor(true));
            return;
        }
        return dispatch(GeneralActions.setIsFamilyMemberMinor(false));
    }, [locale, dispatch, format, onChange]);
    var onUpdatePersonBirthDate = useMemo(function () {
        return handleSubmit(function () { return __awaiter(void 0, void 0, void 0, function () {
            var birthDateValue;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        birthDateValue = (_a = date === null || date === void 0 ? void 0 : date.locale(locale).format('YYYY-MM-DD').toString()) !== null && _a !== void 0 ? _a : null;
                        return [4 /*yield*/, updateProfile({ birthDate: birthDateValue })];
                    case 1:
                        _b.sent();
                        onNext === null || onNext === void 0 ? void 0 : onNext();
                        return [2 /*return*/];
                }
            });
        }); });
    }, [locale, date, handleSubmit, onNext, updateProfile]);
    var formatDate = useMemo(function () {
        if (isNewFamilyMember) {
            return profile.birthDate
                ? formatDateTo({ date: profile.birthDate, format: format })
                : '';
        }
        if (!(person === null || person === void 0 ? void 0 : person.birthDate)) {
            return '';
        }
        return formatDateTo({ date: person.birthDate, format: format });
    }, [isNewFamilyMember, person, profile.birthDate, format]);
    // ** EFFECTS ** //
    useEffect(function () {
        if (!formatDate) {
            return;
        }
        onChange(formatDate);
    }, [formatDate, onChange, person]);
    return {
        value: date,
        setValue: onInputBirthdateChange,
        errorMessage: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message,
        onUpdatePersonBirthDate: onUpdatePersonBirthDate,
        isFamilyMember: isFamilyMember,
        clearErrors: clearErrors,
    };
};
