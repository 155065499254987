var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import PlBaseSidebar from './PlBaseSidebar';
import PlSidebarHeader from './PlSidebarHeader';
var GenericDrawer = function (_a) {
    var _b;
    var title = _a.title, showDrawer = _a.showDrawer, onCloseDrawer = _a.onCloseDrawer, leftHeaderComponent = _a.leftHeaderComponent, rightHeaderComponent = _a.rightHeaderComponent, children = _a.children, footerContent = _a.footerContent;
    var headerContainerRef = React.useRef(null);
    var footerContainerRef = React.useRef(null);
    var windowHeight = useWindowSize()[1];
    var _c = React.useState(0), mainContentHeight = _c[0], setMainContentHeight = _c[1];
    React.useEffect(function () {
        var _a, _b;
        var calculatedHeight = 0;
        if (headerContainerRef.current) {
            calculatedHeight =
                windowHeight -
                    ((_a = headerContainerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) -
                    8 -
                    (((_b = footerContainerRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) || 0);
        }
        setMainContentHeight(calculatedHeight);
    }, [(_b = headerContainerRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight, footerContent, windowHeight]);
    return (_jsxs(PlBaseSidebar, __assign({ onCloseMenu: onCloseDrawer, isOpen: showDrawer }, { children: [_jsx("div", __assign({ ref: headerContainerRef }, { children: _jsx(PlSidebarHeader, { leftComponent: leftHeaderComponent, title: title, rightComponent: rightHeaderComponent }) })), _jsx("div", __assign({ style: { height: mainContentHeight, overflowY: 'auto' } }, { children: children })), footerContent && _jsx("div", __assign({ ref: footerContainerRef }, { children: footerContent }))] })));
};
export default GenericDrawer;
