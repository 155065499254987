var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/RouteAuthorizer';
import { AppRoutes } from './routes';
var subscriptionRedirectPages = [
    {
        userRole: UserRole.Anonymous,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.NoCountryUser,
        page: AppRoutes.Intro,
    },
    {
        userRole: UserRole.PartialUser,
        page: AppRoutes.JoinFamily,
    },
    {
        userRole: UserRole.PlanPendingUser,
        page: AppRoutes.OnboardingWelcomePage,
    },
    {
        userRole: UserRole.CompleteUser,
        page: AppRoutes.Plan,
    },
];
var SubscriptionPage = React.lazy(function () { return import('../pages/onboarding/subscription/subscription.page'); });
var subscriptionRoutes = [
    {
        path: "".concat(AppRoutes.SubscriptionPage),
        element: (_jsx(RouteAuthorizer, __assign({ userRoles: [UserRole.UserPaymentNeeded], redirectPages: subscriptionRedirectPages }, { children: _jsx(SubscriptionPage, {}) }))),
    },
];
export default subscriptionRoutes;
