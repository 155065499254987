var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useMemo } from 'react';
var useInputSelectFacade = function (_a) {
    var options = _a.options, selectionType = _a.selectionType, selectedOptions = _a.selectedOptions, onSelectedOptionsChange = _a.onSelectedOptionsChange, onExpandPress = _a.onExpandPress, _b = _a.maxSelection, maxSelection = _b === void 0 ? 5 : _b;
    // ** DATA ** //
    var customOptions = useMemo(function () { return selectedOptions.filter(function (option) { return option.type === 'custom'; }); }, [selectedOptions]);
    // ** HELPERS ** //
    var getSelectOptionProps = useCallback(function (_a) {
        var type = _a.type, label = _a.label, value = _a.value, linked = _a.linked;
        if (type === 'custom') {
            return {
                labelToShow: label,
                selected: true,
            };
        }
        if (!linked) {
            var selectedOption = selectedOptions.find(function (option) { return option.label === label; });
            return {
                labelToShow: label,
                selected: Boolean(selectedOption),
            };
        }
        var linkedOption = selectedOptions.find(function (option) { return option.linkedTo === value; });
        if (!linkedOption) {
            return {
                labelToShow: label,
                selected: false,
            };
        }
        return {
            labelToShow: linkedOption.label,
            selected: true,
        };
    }, [selectedOptions]);
    // ** CALLBACKS ** //
    var onOptionPress = useCallback(function (_a) {
        var label = _a.label, expandable = _a.expandable;
        if (expandable) {
            onExpandPress && onExpandPress(label);
            return;
        }
        var possibleOptionsWithoutSelected = selectedOptions.filter(function (option) { return option.label !== label; });
        if (possibleOptionsWithoutSelected.length !== selectedOptions.length) {
            onSelectedOptionsChange(possibleOptionsWithoutSelected);
            return;
        }
        var selectedOption = options === null || options === void 0 ? void 0 : options.find(function (option) { return option.label === label; });
        if (!selectedOption) {
            return;
        }
        var updatedSelectedOptions;
        if (selectionType === 'multiple' &&
            selectedOptions.length < maxSelection) {
            updatedSelectedOptions = __spreadArray(__spreadArray([], selectedOptions, true), [selectedOption], false);
        }
        else if (selectionType === 'multiple') {
            updatedSelectedOptions = __spreadArray([], selectedOptions, true);
        }
        else {
            updatedSelectedOptions = [selectedOption];
        }
        onSelectedOptionsChange(updatedSelectedOptions);
    }, [
        maxSelection,
        onExpandPress,
        onSelectedOptionsChange,
        options,
        selectedOptions,
        selectionType,
    ]);
    return {
        customOptions: customOptions,
        getSelectOptionProps: getSelectOptionProps,
        onOptionPress: onOptionPress,
    };
};
export default useInputSelectFacade;
