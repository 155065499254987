var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import PlText from './PlText';
var PlSidebarHeader = function (_a) {
    var title = _a.title, _b = _a.className, className = _b === void 0 ? '' : _b, leftComponent = _a.leftComponent, rightComponent = _a.rightComponent;
    return (_jsxs("div", __assign({ className: "flex flex-row justify-between items-center px-8 border-b py-4 ".concat(className) }, { children: [_jsx("div", __assign({ className: "w-24" }, { children: leftComponent })), title && _jsx(PlText, { className: "font-medium", label: title }), _jsx("div", __assign({ className: "w-24 flex justify-end" }, { children: rightComponent }))] })));
};
export default PlSidebarHeader;
