var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { toggleElementOnTree, isElementInTree } from '../../utils';
import { cloneDeep } from 'lodash';
export var useLocalCategoriesSelection = function (_a) {
    var allCategories = _a.allCategories, selectedCategoriesFilter = _a.selectedCategoriesFilter, setFilterCategories = _a.setFilterCategories;
    var _b = React.useState(selectedCategoriesFilter ? cloneDeep(selectedCategoriesFilter) : []), selectedLocalCategories = _b[0], setSelectedLocalCategories = _b[1];
    var _c = React.useState({}), accordionsStates = _c[0], setAccordionsStates = _c[1];
    var createToggleCategoryWithChildrenFunction = React.useCallback(function (categoryWithChildren) {
        return function () {
            var activeCategories = __spreadArray([], selectedLocalCategories, true);
            var tmpSelectedCategory = activeCategories.find(function (ac) { return ac.id === categoryWithChildren.id; });
            var allChildrenSelected = tmpSelectedCategory &&
                categoryWithChildren.child.length ===
                    (tmpSelectedCategory === null || tmpSelectedCategory === void 0 ? void 0 : tmpSelectedCategory.child.length);
            categoryWithChildren.child.forEach(function (child) {
                var categoryToToggle = __assign(__assign({}, categoryWithChildren), { child: [child] });
                var isCategoryAlreadySelected = isElementInTree(categoryToToggle, activeCategories);
                if (allChildrenSelected || !isCategoryAlreadySelected) {
                    activeCategories = toggleElementOnTree(categoryToToggle, activeCategories);
                }
            });
            setSelectedLocalCategories(activeCategories);
        };
    }, [selectedLocalCategories]);
    var toggleFunctionsObject = React.useMemo(function () {
        var functions = {};
        allCategories.forEach(function (category) {
            var activeCategories = __spreadArray([], selectedLocalCategories, true);
            if (category.child.length > 0) {
                functions[category.id] =
                    createToggleCategoryWithChildrenFunction(category);
                category.child.forEach(function (child) {
                    functions[child.id] = function () {
                        var categoryToToggle = __assign(__assign({}, category), { child: [child] });
                        setSelectedLocalCategories(toggleElementOnTree(categoryToToggle, activeCategories));
                    };
                });
            }
            else {
                functions[category.id] = function () {
                    setSelectedLocalCategories(toggleElementOnTree(category, activeCategories));
                };
            }
        });
        return functions;
    }, [
        allCategories,
        createToggleCategoryWithChildrenFunction,
        selectedLocalCategories,
    ]);
    var applyCategories = React.useCallback(function () {
        setFilterCategories(selectedLocalCategories);
    }, [selectedLocalCategories, setFilterCategories]);
    var resetLocalCategories = React.useCallback(function () {
        setSelectedLocalCategories([]);
    }, []);
    return {
        allCategories: allCategories,
        resetLocalCategories: resetLocalCategories,
        toggleFunctionsObject: toggleFunctionsObject,
        selectedLocalCategories: selectedLocalCategories,
        applyCategories: applyCategories,
        accordionsStates: accordionsStates,
        setAccordionsStates: setAccordionsStates,
    };
};
