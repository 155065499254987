var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useGetAllFoodStoragesApi } from './api/shoppingList/useGetAllFoodStorages.api';
import { useLanguage } from '../locale';
import { LoaderType } from './api/utils';
export var useFoodStorageManagerHook = function (loaderType) {
    if (loaderType === void 0) { loaderType = LoaderType.FullPage; }
    var today = dayjs().utc();
    var t = useLanguage()[0];
    var _a = useState(false), isRefreshing = _a[0], setIsRefreshing = _a[1];
    var _b = useGetAllFoodStoragesApi(true, loaderType), allFoodStorages = _b.data, isAllFoodStoragesLoading = _b.isLoading, refetch = _b.refetch;
    var sortFoodStoragesByDate = useCallback(function (a, b) {
        if (a.dateLabel === t('today') && b.dateLabel !== t('today')) {
            return -1;
        }
        if (a.dateLabel !== t('today') && b.dateLabel === t('today')) {
            return 1;
        }
        return dayjs(a.validFrom).valueOf() - dayjs(b.validFrom).valueOf();
    }, [t]);
    var foodStoragesList = useMemo(function () {
        if (!allFoodStorages)
            return [];
        var list = allFoodStorages.map(function (foodStorage) {
            var validFrom = foodStorage.validFrom, validTo = foodStorage.validTo;
            var validFromDate = dayjs(validFrom).utc();
            var validToDate = dayjs(validTo).utc();
            if (today.isSame(validFromDate, 'day') &&
                today.isSame(validToDate, 'day')) {
                return __assign(__assign({}, foodStorage), { dateLabel: t('today') });
            }
            return __assign({}, foodStorage);
        });
        return list.sort(sortFoodStoragesByDate);
    }, [allFoodStorages, sortFoodStoragesByDate, t, today]);
    var refetchAllFoodStorage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsRefreshing(true);
                    return [4 /*yield*/, refetch()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsRefreshing(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [refetch]);
    return {
        foodStoragesList: foodStoragesList,
        isAllFoodStoragesLoading: isAllFoodStoragesLoading,
        refetchAllFoodStorage: refetchAllFoodStorage,
        isFetchingAllFoodStorage: isRefreshing,
    };
};
