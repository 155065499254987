var NotImplementedReminderNotifications = /** @class */ (function () {
    function NotImplementedReminderNotifications() {
        this.getInitialNotification = function () {
            throw new Error('getInitialNotification not implemented');
        };
        this.onBackgroundEvent = function () {
            throw new Error('onBackgroundEvent not implemented');
        };
        this.onForegroundEvent = function () {
            throw new Error('onForegroundEvent not implemented');
        };
        this.createTriggerNotification = function () {
            throw new Error('createTriggerNotification not implemented');
        };
        this.cancelTriggerNotifications = function () {
            throw new Error('cancelTriggerNotifications not implemented');
        };
        this.cancelDisplayedNotification = function () {
            throw new Error('cancelDisplayedNotification not implemented');
        };
        this.onIOSInitialNotification = function () {
            throw new Error('onIOSInitialNotification not implemented');
        };
    }
    return NotImplementedReminderNotifications;
}());
export { NotImplementedReminderNotifications };
