export function recipeTimeFormatter(min, t) {
    var hours = Math.floor(min / 60);
    var minutes = min % 60;
    var hourLabelComplete = hours
        ? "".concat(hours, " ").concat(hours > 1 ? t('hours') : t('hour'))
        : '';
    var minuteLabelComplete = minutes
        ? "".concat(minutes, " ").concat(minutes > 1 ? t('minutes') : t('minute'), " ")
        : '';
    return "".concat(hourLabelComplete, " ").concat(hours && minutes ? t('and') : '', " ").concat(minuteLabelComplete);
}
