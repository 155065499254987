var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLanguage } from '@planter/common';
import * as React from 'react';
import WarningIcon from '../../../components/icons/WarningIcon';
var NotifyIngredients = React.memo(function (className) {
    var t = useLanguage()[0];
    return (_jsxs("div", __assign({ className: "rounded-xl bg-yellow p-3 ".concat(className) }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(WarningIcon, {}), _jsx("p", __assign({ className: "pl-3 font-medium" }, { children: t('warning') }))] })), _jsx("p", { children: t('recipeDetail.notifyIngredientsDescription') })] })));
});
export default NotifyIngredients;
