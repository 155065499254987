import { useLanguage } from '../../locale';
import { useCallback, useMemo } from 'react';
export var useMemberRegistryNavigationFacade = function (_a) {
    var navigateBack = _a.navigateBack, navigateTo = _a.navigateTo, lastStep = _a.lastStep, currentStep = _a.currentStep;
    var t = useLanguage()[0];
    var counter = useMemo(function () {
        return t('memberRegistry.counterText', {
            x: String(currentStep + 1).padStart(2, '0'),
            y: String(lastStep).padStart(2, '0'),
        });
    }, [currentStep, lastStep, t]);
    var previousLabel = useMemo(function () { return (!currentStep ? t('memberRegistry.familyCheckNoCode') : t('back')); }, [currentStep, t]);
    var nextLabel = useMemo(function () { return (currentStep === lastStep ? t('forward') : t('memberRegistry.end')); }, [currentStep, lastStep, t]);
    var onNavigateToNextStep = useCallback(function () {
        if (currentStep === lastStep) {
            return;
        }
        navigateTo();
    }, [currentStep, lastStep, navigateTo]);
    return {
        counter: counter,
        previousLabel: previousLabel,
        nextLabel: nextLabel,
        onNavigateToPreviousStep: navigateBack,
        onNavigateToNextStep: onNavigateToNextStep,
    };
};
