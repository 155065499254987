import { createNotImplementedMethod } from "../utils";
var NotImplementedCodeRepository = /** @class */ (function () {
    function NotImplementedCodeRepository() {
        this.updateCheckCode = createNotImplementedMethod();
        this.updateCodeAssign = createNotImplementedMethod();
        this.getCode = createNotImplementedMethod();
    }
    return NotImplementedCodeRepository;
}());
export { NotImplementedCodeRepository };
