var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import colors from '../../styles/colors.module.scss';
var FruitIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, _c = _a.width, width = _c === void 0 ? 24 : _c, _d = _a.height, height = _d === void 0 ? 24 : _d, _e = _a.backgroundColor, backgroundColor = _e === void 0 ? colors['orange-medium'] : _e, _f = _a.iconColor, iconColor = _f === void 0 ? colors.black : _f, className = _a.className;
    return (_jsxs("svg", __assign({ width: width !== null && width !== void 0 ? width : size, height: height !== null && height !== void 0 ? height : size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className }, { children: [_jsx("circle", { opacity: "0.5", cx: "12", cy: "12", r: "12", fill: backgroundColor }), _jsx("path", { d: "M15 8.5H14.86C15.2754 7.91573 15.499 7.21687 15.5 6.5C15.5 6.36739 15.4473 6.24021 15.3536 6.14645C15.2598 6.05268 15.1326 6 15 6C14.3953 6.00081 13.801 6.15829 13.2753 6.4571C12.7495 6.75591 12.3101 7.18585 12 7.705C11.6899 7.18585 11.2505 6.75591 10.7247 6.4571C10.199 6.15829 9.60474 6.00081 9 6C8.86739 6 8.74021 6.05268 8.64645 6.14645C8.55268 6.24021 8.5 6.36739 8.5 6.5C8.5 6.63261 8.55268 6.75979 8.64645 6.85355C8.74021 6.94732 8.86739 7 9 7C9.56614 7.00059 10.1153 7.19332 10.5577 7.54665C11 7.89998 11.3093 8.39299 11.435 8.945C11.17 8.9 10.9 8.835 10.62 8.765C10.0929 8.6105 9.54883 8.5215 9 8.5C7.12 8.5 6 9.81 6 12C6 14.89 7.25 18 10 18C10.5303 17.9906 11.053 17.8714 11.535 17.65C11.6812 17.5759 11.8381 17.5253 12 17.5C12.1619 17.5253 12.3188 17.5759 12.465 17.65C12.947 17.8714 13.4697 17.9906 14 18C16.75 18 18 14.89 18 12C18 9.81 16.88 8.5 15 8.5ZM14.435 7.065C14.3293 7.52036 14.0981 7.93696 13.7675 8.26751C13.437 8.59806 13.0204 8.8293 12.565 8.935C12.6707 8.47964 12.9019 8.06304 13.2325 7.73249C13.563 7.40194 13.9796 7.1707 14.435 7.065ZM14 17C13.6014 16.9919 13.2092 16.8981 12.85 16.725C12.5845 16.594 12.2955 16.5174 12 16.5C11.7045 16.5174 11.4155 16.594 11.15 16.725C10.7908 16.8981 10.3986 16.9919 10 17C7.94 17 7 14.41 7 12C7 10.86 7.345 9.5 9 9.5C9.46769 9.52339 9.93093 9.60227 10.38 9.735C10.9071 9.8895 11.4512 9.9785 12 10C12.1249 10.0075 12.2501 10.0075 12.375 10C12.7955 9.94923 13.2116 9.86733 13.62 9.755C14.0682 9.61549 14.5315 9.52989 15 9.5C16.655 9.5 17 10.86 17 12C17 14.41 16.06 17 14 17Z", fill: iconColor })] })));
};
export default FruitIcon;
