var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCorrectIngredientQuantityForEachMember, useLanguage, } from '@planter/common';
import * as React from 'react';
import PlText from '../../../components/PlText';
var ManageIngredientInformation = function (_a) {
    var nameToShow = _a.nameToShow, ingredient = _a.ingredient, showPortion = _a.showPortion;
    var _b = useLanguage(), t = _b[0], formatNumber = _b[3];
    var quantityLabel = getCorrectIngredientQuantityForEachMember({
        ingredient: ingredient,
        showPortion: showPortion,
        formatNumber: formatNumber,
    });
    return (_jsxs("div", __assign({ className: "py-1" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx(PlText, __assign({ className: "".concat(showPortion ? 'font-normal text-xs' : 'font-medium') }, { children: nameToShow })), _jsx(PlText, __assign({ className: "text-xs ".concat(showPortion ? 'text-black' : 'text-grey') }, { children: quantityLabel }))] })), ingredient.optional && !showPortion && (_jsx(PlText, __assign({ className: "text-xs text-grey" }, { children: t('recipeDetail.optional') })))] }), "".concat(nameToShow, "-").concat(ingredient.id)));
};
export default ManageIngredientInformation;
