import { createNotImplementedMethod } from '../utils';
var NotImplementedRecipeRepository = /** @class */ (function () {
    function NotImplementedRecipeRepository() {
        this.getFeaturedContents = createNotImplementedMethod();
        this.getRecipePreferences = createNotImplementedMethod();
        this.getSeasonalRecipes = createNotImplementedMethod();
        this.getSundayRecipes = createNotImplementedMethod();
        this.getRecipe = createNotImplementedMethod();
        this.getRecipePreview = createNotImplementedMethod();
        this.getRecipes = createNotImplementedMethod();
        this.getWeeklyRecipe = createNotImplementedMethod();
        this.addRecipeToPlan = createNotImplementedMethod();
    }
    return NotImplementedRecipeRepository;
}());
export { NotImplementedRecipeRepository };
