var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import colors from '../../styles/colors.module.scss';
var CircularLoader = function () {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", x: "0", y: "0", version: "1.1", width: "100", height: "100", viewBox: "0 0 100 100", xmlSpace: "preserve" }, { children: _jsx("path", __assign({ fill: colors['orange-dark'], d: "M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50" }, { children: _jsx("animateTransform", { attributeName: "transform", attributeType: "XML", dur: "1s", from: "0 50 50", repeatCount: "indefinite", to: "360 50 50", type: "rotate" }) })) })));
};
export default CircularLoader;
