var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
var PlAddedFamilyMemberBtn = function (_a) {
    var leftIcon = _a.leftIcon, onClickLeftIcon = _a.onClickLeftIcon, label = _a.label, profileRole = _a.profileRole, _b = _a.className, className = _b === void 0 ? '' : _b, rightIcon = _a.rightIcon, onClickRightIcon = _a.onClickRightIcon;
    return (_jsxs("div", __assign({ className: "flex justify-between flex-row bg-grey-extra-light rounded-full ".concat(className) }, { children: [leftIcon && (_jsx("div", __assign({ className: "flex items-center", onClick: onClickLeftIcon }, { children: leftIcon }))), _jsxs("div", __assign({ className: " flex flex-1 ".concat(leftIcon && 'pl-2', " \n        ").concat(rightIcon && 'pr-2', " \n        ").concat(profileRole ? 'flex-col items-start justify-start' : 'items-center') }, { children: [_jsx("p", __assign({ className: "text-base font-medium" }, { children: label })), profileRole && (_jsx("p", __assign({ className: "text-base font-small text-grey" }, { children: profileRole })))] })), rightIcon && (_jsx("div", __assign({ className: "flex items-center justify-end pr-3 pt-1", onClick: onClickRightIcon }, { children: rightIcon })))] })));
};
export default PlAddedFamilyMemberBtn;
