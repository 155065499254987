var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DailyPlanRecipeType, } from '../models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetCustomerApi } from './api/customer/useGetCustomer.api';
import { useGetPersonApi } from './api/user/useGetPerson.api';
import { useGetFamilyApi } from './api/family/useGetFamily.api';
import { useLanguage } from '../locale';
import { isEqual } from 'lodash';
import { useUpdateRecurrentAbsencesApi } from './api/profile/useUpdateRecurrentAbsences.api';
import { LoaderType } from './api/utils';
var initialMeals = [
    { meal: DailyPlanRecipeType.BREAKFAST, days: [] },
    { meal: DailyPlanRecipeType.MORNING_SNACK, days: [] },
    { meal: DailyPlanRecipeType.LUNCH, days: [] },
    { meal: DailyPlanRecipeType.SNACK, days: [] },
    { meal: DailyPlanRecipeType.DINNER, days: [] },
];
export var useEditRecurrencesHook = function (_a) {
    var personId = _a.personId, meals = _a.meals;
    var t = useLanguage()[0];
    var customer = useGetCustomerApi(true).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var family = useGetFamilyApi(person === null || person === void 0 ? void 0 : person.familyId, !!(customer === null || customer === void 0 ? void 0 : customer.personId)).data;
    var updateSchedule = useUpdateRecurrentAbsencesApi(LoaderType.FullPage).mutateAsync;
    var _b = useState(__spreadArray([], initialMeals, true)), mealsState = _b[0], setMealsState = _b[1];
    var _c = useState(false), isModalVisible = _c[0], setIsModalVisible = _c[1];
    var selectedUSer = useMemo(function () {
        var user = family === null || family === void 0 ? void 0 : family.Person.find(function (person) { return person.id === personId; });
        if (!user) {
            return {
                name: t('planPage.allComponents'),
                profileImageUrl: undefined,
            };
        }
        return user;
    }, [family === null || family === void 0 ? void 0 : family.Person, personId, t]);
    var onDayPress = useCallback(function (meal) { return function (selectedDays) {
        setMealsState(function (prevState) {
            return prevState.map(function (item) {
                if (item.meal !== meal) {
                    return item;
                }
                var currentDays = __spreadArray([], item.days, true);
                var selectedDay = selectedDays[0];
                if (selectedDays.length === 7) {
                    return __assign(__assign({}, item), { days: currentDays.length === 7 ? [] : selectedDays });
                }
                var dayInMeal = currentDays.includes(selectedDay);
                return __assign(__assign({}, item), { days: dayInMeal
                        ? currentDays.filter(function (el) { return el !== selectedDay; })
                        : __spreadArray(__spreadArray([], currentDays, true), [selectedDay], false) });
            });
        });
    }; }, [setMealsState]);
    var canUserGoBack = useMemo(function () {
        var allMealsIndex = meals.findIndex(function (newMeal) { return newMeal.meal === 'ALL_MEALS'; });
        if (allMealsIndex !== -1) {
            var daysForAllMeals_1 = __spreadArray([], meals[allMealsIndex].days, true).sort() || [];
            var sortedAllMealsDays = mealsState.map(function (el) { return (__assign(__assign({}, el), { days: __spreadArray([], el.days, true).sort() })); });
            return sortedAllMealsDays.every(function (meal) {
                return isEqual(meal.days, daysForAllMeals_1);
            });
        }
        var matchingMealsStateWithMeals = mealsState.filter(function (meal) {
            return meals.some(function (el) { return el.meal === meal.meal; });
        });
        var mealsStateNonMatchingWithMeals = mealsState.filter(function (meal) { return !meals.some(function (el) { return el.meal === meal.meal; }); });
        var sortedMealsStateFiltered = matchingMealsStateWithMeals.map(function (el) { return (__assign(__assign({}, el), { days: __spreadArray([], el.days, true).sort() })); });
        var userDays = sortedMealsStateFiltered.map(function (_a) {
            var days = _a.days;
            return days;
        });
        var savedApiDays = meals.map(function (_a) {
            var days = _a.days;
            return days;
        });
        return (isEqual(savedApiDays, userDays) &&
            mealsStateNonMatchingWithMeals.every(function (el) { return el.days.length === 0; }));
    }, [meals, mealsState]);
    var onDenyModal = useCallback(function () {
        setIsModalVisible(false);
    }, []);
    var onGoBack = useCallback(function (goBack) {
        if (!canUserGoBack) {
            setIsModalVisible(true);
            return;
        }
        goBack();
    }, [canUserGoBack]);
    var onSavePress = useCallback(function (goBack) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!person) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, updateSchedule({
                            familyId: person.familyId,
                            personId: personId === '-1' ? undefined : personId,
                            schedule: mealsState,
                        })];
                case 1:
                    _a.sent();
                    goBack();
                    return [2 /*return*/];
            }
        });
    }); }, [mealsState, person, personId, updateSchedule]);
    useEffect(function () {
        setMealsState(function (prevMealsState) {
            var allMealsIndex = meals.findIndex(function (newMeal) { return newMeal.meal === 'ALL_MEALS'; });
            if (allMealsIndex !== -1) {
                var daysForAllMeals_2 = meals[allMealsIndex].days || [];
                return prevMealsState.map(function (prevMeal) { return (__assign(__assign({}, prevMeal), { days: daysForAllMeals_2 })); });
            }
            return prevMealsState.map(function (prevMeal) {
                var updatedMeal = meals.find(function (newMeal) { return newMeal.meal === prevMeal.meal; });
                return updatedMeal || prevMeal;
            });
        });
    }, [meals]);
    return {
        t: t,
        onDayPress: onDayPress,
        mealsState: mealsState,
        selectedUSer: selectedUSer,
        canUserGoBack: canUserGoBack,
        isModalVisible: isModalVisible,
        onGoBack: onGoBack,
        onDenyModal: onDenyModal,
        onSavePress: onSavePress,
    };
};
