import { useController, useForm } from 'react-hook-form';
import { useLanguage } from '../locale';
import { emailValidation } from '../utils/regex';
export var useChangeEmailForm = function () {
    var _a, _b;
    var t = useLanguage()[0];
    var _c = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            confirmEmail: '',
        },
    }), control = _c.control, handleSubmit = _c.handleSubmit, setValue = _c.setValue, getValues = _c.getValues;
    var emailController = useController({
        name: 'email',
        control: control,
        rules: {
            required: {
                value: true,
                message: t('emailCheckScreen.emailRequiredError'),
            },
            pattern: {
                value: emailValidation,
                message: t('emailCheckScreen.emailFormatError'),
            },
            validate: function (value) {
                return value === getValues('confirmEmail') || t('errorCode.notConfirmedEmail');
            },
        },
    });
    var confirmEmailController = useController({
        name: 'confirmEmail',
        control: control,
        rules: {
            required: {
                value: true,
                message: t('emailCheckScreen.emailRequiredError'),
            },
            pattern: {
                value: emailValidation,
                message: t('emailCheckScreen.emailFormatError'),
            },
        },
    });
    var onEmailChange = function (e) {
        var trimmedValue = typeof e === 'string' ? e.trim() : e.target.value.trim();
        emailController.field.onChange(trimmedValue === null || trimmedValue === void 0 ? void 0 : trimmedValue.toLowerCase());
    };
    return {
        onEmailChange: onEmailChange,
        emailValue: emailController.field.value,
        onConfirmEmailChange: confirmEmailController.field.onChange,
        confirmEmailValue: confirmEmailController.field.value,
        handleSubmit: handleSubmit,
        errorEmail: (_a = emailController.fieldState.error) === null || _a === void 0 ? void 0 : _a.message,
        errorConfirmEmail: (_b = confirmEmailController.fieldState.error) === null || _b === void 0 ? void 0 : _b.message,
        setValue: setValue,
    };
};
