var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useEffect, useMemo, useState, } from 'react';
import i18n from 'i18n-js';
import it from './values/it';
import en from './values/en';
import us from './values/us';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import { NotificationPosition, useNotificationsMessage, useStorage, } from '../contexts';
import { StorageKeys } from '../services';
var IT = 'it';
var EN = 'en';
var US = 'us';
export var languages = { it: it, en: en, us: us };
export var managedLanguages = [IT, EN, US];
export var localeManager = function (scope, newOptions) {
    var options = newOptions || { locale: IT };
    return i18n.t(scope, options || {});
};
i18n.defaultLocale = IT;
i18n.fallbacks = true;
i18n.translations = { it: it, en: en, us: us };
export var LocalizationContext = createContext({
    t: function (scope, options) {
        if (options === void 0) { options = {}; }
        return i18n.t(scope, __assign({ IT: IT }, options));
    },
    locale: IT,
    setLocale: function () { },
    formatNumber: function (value) { return "".concat(value); },
});
export var LocalizationProvider = function (_a) {
    var _b = _a.preferredLanguage, preferredLanguage = _b === void 0 ? 'en' : _b, children = _a.children;
    var _c = useState(preferredLanguage), locale = _c[0], setLocale = _c[1];
    var t = useMemo(function () {
        return function (scope, options) { return i18n.t(scope, __assign({ locale: locale }, options)); };
    }, [locale]);
    var showNotifications = useNotificationsMessage().showNotifications;
    var storage = useStorage();
    var updateLocale = useCallback(function (language) {
        i18n.locale = language;
        dayjs.locale(language);
        setLocale(language);
        storage.setItem(StorageKeys.APP_LANGUAGE, language);
    }, [storage]);
    var initAppLocale = useCallback(function (language) {
        updateLocale(language);
    }, [updateLocale]);
    var setAppLocale = useCallback(function (language) {
        updateLocale(language);
        showNotifications({
            text: i18n.t('profilePage.languageAndUnitOfMeasurePage.savedChanges'),
            position: NotificationPosition.bottom,
            isError: false,
        });
    }, [showNotifications, updateLocale]);
    var formatNumber = useCallback(function (value) {
        return new Intl.NumberFormat(locale, {}).format(value);
    }, [locale]);
    useEffect(function () {
        storage.getItem(StorageKeys.APP_LANGUAGE).then(function (language) {
            var appLanguage = language;
            initAppLocale(appLanguage !== null && appLanguage !== void 0 ? appLanguage : preferredLanguage);
        });
    }, [initAppLocale, preferredLanguage, setAppLocale, storage]);
    return (_jsx(LocalizationContext.Provider, __assign({ value: {
            t: t,
            locale: locale,
            setLocale: setAppLocale,
            formatNumber: formatNumber,
        } }, { children: children })));
};
