import { createNotImplementedMethod } from '../utils';
var NotImplementedInAppPurchaseRepository = /** @class */ (function () {
    function NotImplementedInAppPurchaseRepository() {
        this.getProducts = createNotImplementedMethod();
        this.createCheckoutSession = createNotImplementedMethod();
        this.checkout = createNotImplementedMethod();
        this.createPortal = createNotImplementedMethod();
    }
    return NotImplementedInAppPurchaseRepository;
}());
export { NotImplementedInAppPurchaseRepository };
