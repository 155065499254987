import { NutrientTypeName } from '../models';
export var convertNutrientToType = function (name) {
    switch (name) {
        case 'proteinFoods':
            return NutrientTypeName.protein;
        case 'fruit':
            return NutrientTypeName.fruit;
        case 'cereals':
            return NutrientTypeName.cereal;
        case 'goodFats':
            return NutrientTypeName.fats;
        case 'vegetables':
            return NutrientTypeName.vegetable;
        default:
            return;
    }
};
