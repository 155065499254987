var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PreparationTime, } from '../models';
export function isElementInTree(categoryToSearch, categories) {
    var categoryIndex = categories.findIndex(function (c) { return categoryToSearch.id === c.id; });
    if (categoryIndex === -1) {
        return false;
    }
    if (categoryToSearch.child.length > 0) {
        return isElementInTree(categoryToSearch.child[0], categories[categoryIndex].child);
    }
    return true;
}
export function toggleElementOnTree(categoryToAdd, categories) {
    if (categories === void 0) { categories = []; }
    var categoryIndex = categories.findIndex(function (c) { return categoryToAdd.id === c.id; });
    // Add child
    if (categoryIndex === -1) {
        return __spreadArray(__spreadArray([], categories, true), [categoryToAdd], false);
    }
    // Check in childrens
    if (categoryToAdd.child.length > 0) {
        var newCategoriesChildren = toggleElementOnTree(categoryToAdd.child[0], categories[categoryIndex].child);
        categories[categoryIndex].child = newCategoriesChildren;
        if (categories[categoryIndex].child.length > 0) {
            return categories;
        }
    }
    // Remove
    categories.splice(categoryIndex, 1);
    return categories;
}
export function extractDeepChildrenIds(categories) {
    return categories.reduce(function (pv, cv) {
        var reducedElements = [];
        if (cv.child.length > 0) {
            reducedElements = extractDeepChildrenIds(cv.child);
        }
        else {
            reducedElements = [cv.id];
        }
        return __spreadArray(__spreadArray([], pv, true), reducedElements, true);
    }, []);
}
export function extractPreparationTime(preparationTime) {
    switch (preparationTime) {
        case PreparationTime.Small:
            return {
                min: 0,
                max: 20,
            };
        case PreparationTime.Medium:
            return {
                min: 20,
                max: 60,
            };
        case PreparationTime.Long:
            return {
                min: 60,
                max: 9999,
            };
    }
}
export function stringifyCategories(categories) {
    return categories.reduce(function (pv, cv, i) {
        var isFirst = i === 0;
        return pv.concat("".concat(isFirst ? '' : ', ').concat(cv.name).concat(cv.child.length > 0 ? " (".concat(cv.child.length, ")") : ''));
    }, '');
}
export function transformIngredientInSelectOption(ingredient) {
    return {
        label: ingredient.value,
        value: ingredient.id,
        type: 'default',
    };
}
export function transformSelectOptionInIngredient(option) {
    return {
        id: option.value,
        name: option.label,
        value: option.label,
    };
}
