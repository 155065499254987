import { createNotImplementedMethod } from '../utils';
var NotImplementedUserRepository = /** @class */ (function () {
    function NotImplementedUserRepository() {
        this.getPersonDetail = createNotImplementedMethod();
        this.createPerson = createNotImplementedMethod();
        this.updatePerson = createNotImplementedMethod();
        this.uploadImage = createNotImplementedMethod();
        this.deleteImage = createNotImplementedMethod();
        this.deleteAccount = createNotImplementedMethod();
        this.updateRecurrentAbsences = createNotImplementedMethod();
    }
    return NotImplementedUserRepository;
}());
export { NotImplementedUserRepository };
