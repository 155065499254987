var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DailyPlanRecipeType, } from '../models';
var hasSameMealsRecurrences = function (obj) {
    return Object.values(obj).every(function (days, index, array) { return JSON.stringify(days) === JSON.stringify(array[0]); });
};
var orderedMealTypes = [
    'ALL_MEALS',
    DailyPlanRecipeType.BREAKFAST,
    DailyPlanRecipeType.MORNING_SNACK,
    DailyPlanRecipeType.LUNCH,
    DailyPlanRecipeType.SNACK,
    DailyPlanRecipeType.DINNER,
];
var emptySchedules = [
    {
        id: '-1',
        meal: 'ALL_MEALS',
        days: [],
    },
];
var sortMeals = function (meals) {
    return meals.sort(function (a, b) {
        var indexA = orderedMealTypes.indexOf(a.meal);
        var indexB = orderedMealTypes.indexOf(b.meal);
        return indexA - indexB;
    });
};
export var getFamilySchedulesUtils = function (family) {
    var familySchedule = family.Person.map(function (person) {
        var _a;
        var _b = person !== null && person !== void 0 ? person : {}, id = _b.id, name = _b.name, profileImageUrl = _b.profileImageUrl, schedule = _b.schedule;
        var baseObject = { id: id, name: name, profileImageUrl: profileImageUrl };
        if (!schedule) {
            return __assign(__assign({}, baseObject), { schedules: emptySchedules });
        }
        var isAllMealsEquals = hasSameMealsRecurrences(schedule);
        if (isAllMealsEquals) {
            return __assign(__assign({}, baseObject), { schedules: [
                    {
                        id: person.id,
                        meal: 'ALL_MEALS',
                        days: (_a = schedule.BREAKFAST) !== null && _a !== void 0 ? _a : [],
                    },
                ] });
        }
        var mappedSchedule = Object.keys(schedule)
            .map(function (element) {
            return {
                id: "".concat(person.id).concat(element),
                meal: element,
                days: schedule[element],
            };
        })
            .filter(function (el) { return el.days.length > 0; });
        return __assign(__assign({}, baseObject), { schedules: sortMeals(mappedSchedule) });
    });
    var scheduleToCompare = familySchedule[0].schedules;
    var commonSchedules = familySchedule
        .slice(1)
        .reduce(function (sharedSchedules, person) {
        if (sharedSchedules.length === 0) {
            return sharedSchedules;
        }
        var _a = sharedSchedules.length > person.schedules.length
            ? [sharedSchedules, person.schedules]
            : [person.schedules, sharedSchedules], filterableSchedules = _a[0], finderSchedules = _a[1];
        return filterableSchedules.reduce(function (nextSharedSchedules, filterableSchedule) {
            var sameMeal = finderSchedules.find(function (schedule) {
                var isSameMeal = schedule.meal === filterableSchedule.meal;
                return isSameMeal || schedule.meal === 'ALL_MEALS';
            });
            if (!sameMeal) {
                return nextSharedSchedules;
            }
            var sameDays = sameMeal.days.filter(function (day) {
                return filterableSchedule.days.includes(day);
            });
            if (sameDays.length === 0) {
                return nextSharedSchedules;
            }
            var sameSchedule = __assign(__assign({}, filterableSchedule), { days: sameDays });
            var scheduleToRemoveIndex = nextSharedSchedules.findIndex(function (next) { return next.meal === filterableSchedule.meal; });
            if (scheduleToRemoveIndex === -1) {
                return __spreadArray(__spreadArray([], nextSharedSchedules, true), [sameSchedule], false);
            }
            return __spreadArray(__spreadArray(__spreadArray([], nextSharedSchedules.slice(0, scheduleToRemoveIndex), true), [
                sameSchedule
            ], false), nextSharedSchedules.slice(scheduleToRemoveIndex + 1), true);
        }, []);
    }, scheduleToCompare);
    var allMembersSchedules = {
        id: '-1',
        name: 'ALL_MEALS',
        profileImageUrl: 'ALL_MEALS',
        schedules: commonSchedules.length > 0 ? commonSchedules : emptySchedules,
    };
    if (family.Person.length > 1) {
        return __spreadArray([allMembersSchedules], familySchedule, true);
    }
    return familySchedule;
};
