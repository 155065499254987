import { createNotImplementedMethod } from "../utils";
var NotImplementedFavouriteRepository = /** @class */ (function () {
    function NotImplementedFavouriteRepository() {
        this.getFavourite = createNotImplementedMethod();
        this.addFavourite = createNotImplementedMethod();
        this.deleteFavourite = createNotImplementedMethod();
    }
    return NotImplementedFavouriteRepository;
}());
export { NotImplementedFavouriteRepository };
