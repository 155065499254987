export var DailyPlanRecipeType;
(function (DailyPlanRecipeType) {
    DailyPlanRecipeType["BREAKFAST"] = "BREAKFAST";
    DailyPlanRecipeType["MORNING_SNACK"] = "MORNING_SNACK";
    DailyPlanRecipeType["LUNCH"] = "LUNCH";
    DailyPlanRecipeType["SNACK"] = "SNACK";
    DailyPlanRecipeType["DINNER"] = "DINNER";
    DailyPlanRecipeType["ADDED"] = "ADDED";
})(DailyPlanRecipeType || (DailyPlanRecipeType = {}));
export var PlanStatus;
(function (PlanStatus) {
    PlanStatus["PLAN_PENDING"] = "PLAN_PENDING";
    PlanStatus["PLAN_CREATION"] = "PLAN_CREATION";
    PlanStatus["PLAN_UPDATE"] = "PLAN_UPDATE";
    PlanStatus["PLAN_DONE"] = "PLAN_DONE";
})(PlanStatus || (PlanStatus = {}));
export var SelectMembersEntity;
(function (SelectMembersEntity) {
    SelectMembersEntity["CHANGE_MEAL"] = "change-meal";
    SelectMembersEntity["CHANGE_RECIPE"] = "change-recipe";
})(SelectMembersEntity || (SelectMembersEntity = {}));
export var CheckNutrientsValueEnum;
(function (CheckNutrientsValueEnum) {
    CheckNutrientsValueEnum["EMPTY_NUTRIENT"] = "EMPTY_NUTRIENT";
    CheckNutrientsValueEnum["ALMOST_FULL_NUTRIENT"] = "ALMOST_FULL_NUTRIENT";
    CheckNutrientsValueEnum["FULL_NUTRIENT"] = "FULL_NUTRIENT";
})(CheckNutrientsValueEnum || (CheckNutrientsValueEnum = {}));
