var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useSaveAlternativeMealFacade } from './useSaveAlternativeMeal.facade';
import { CheckNutrientsValueEnum, NutrientTypeName, } from '../../models';
import { useCallback, useMemo } from 'react';
import { useChangePlanWithSuggestedMealFacade } from './useChangePlanWithSuggestedMeal.facade';
import { useGetCheckNutrientsApi, useGetCustomerApi, useGetPersonApi, } from '../../hooks';
export var useCreateMealFromRecipesFacade = function (_a) {
    var _b;
    var recipes = _a.recipes, selectedMembersDailyPlan = _a.selectedMembersDailyPlan, mealType = _a.mealType, _c = _a.newRecipesIds, newRecipesIds = _c === void 0 ? [] : _c, guests = _a.guests;
    var requiredNutrients = useChangePlanWithSuggestedMealFacade({
        mealType: mealType,
        selectedMembersDailyPlan: selectedMembersDailyPlan,
        guests: guests,
    }).requiredNutrients;
    var initialSelectedNutrients = useMemo(function () {
        var _a;
        return (_a = {},
            _a[NutrientTypeName.cereal] = CheckNutrientsValueEnum.EMPTY_NUTRIENT,
            _a[NutrientTypeName.fruit] = CheckNutrientsValueEnum.EMPTY_NUTRIENT,
            _a[NutrientTypeName.fats] = CheckNutrientsValueEnum.EMPTY_NUTRIENT,
            _a[NutrientTypeName.protein] = CheckNutrientsValueEnum.EMPTY_NUTRIENT,
            _a[NutrientTypeName.vegetable] = CheckNutrientsValueEnum.EMPTY_NUTRIENT,
            _a);
    }, []);
    var customer = useGetCustomerApi(true).data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _d = useGetCheckNutrientsApi({
        familyId: (_b = person === null || person === void 0 ? void 0 : person.familyId) !== null && _b !== void 0 ? _b : '',
        mealType: mealType,
        personToChange: selectedMembersDailyPlan,
        newRecipesIds: newRecipesIds,
    }), checkNutrients = _d.data, isLoading = _d.isLoading;
    var personsAlternativeMeal = useMemo(function () {
        var newRecipeIds = recipes.map(function (meal) { return meal.id; });
        var members = selectedMembersDailyPlan.map(function (member) { return ({
            dailyPlanId: member.dailyPlan,
            mealType: mealType,
            newRecipeIds: newRecipeIds !== null && newRecipeIds !== void 0 ? newRecipeIds : [],
        }); });
        return { persons: members, quantity: guests };
    }, [guests, mealType, recipes, selectedMembersDailyPlan]);
    var saveAlternativeMeal = useSaveAlternativeMealFacade({
        personsAlternativeMeal: personsAlternativeMeal,
    }).saveAlternativeMeal;
    var nutrientTypeToCheckPropMap = useMemo(function () {
        var _a;
        return (_a = {},
            _a[NutrientTypeName.cereal] = 'cereals',
            _a[NutrientTypeName.protein] = 'proteinFoods',
            _a[NutrientTypeName.fats] = 'goodFats',
            _a[NutrientTypeName.vegetable] = 'vegetables',
            _a[NutrientTypeName.fruit] = 'fruit',
            _a);
    }, []);
    var onChangeMealConfirm = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!personsAlternativeMeal) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, saveAlternativeMeal()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [personsAlternativeMeal, saveAlternativeMeal]);
    var selectedNutrients = useMemo(function () {
        return requiredNutrients.reduce(function (acc, currentNutrientType) {
            var _a, _b;
            var checkProp = nutrientTypeToCheckPropMap[currentNutrientType];
            var isNutrientAvailableInRecipes = recipes.some(function (el) {
                if ('nutrients' in el) {
                    return el.nutrients.includes(currentNutrientType);
                }
                return false;
            });
            if (!(checkNutrients === null || checkNutrients === void 0 ? void 0 : checkNutrients[checkProp])) {
                return __assign(__assign({}, acc), (_a = {}, _a[currentNutrientType] = isNutrientAvailableInRecipes
                    ? CheckNutrientsValueEnum.ALMOST_FULL_NUTRIENT
                    : CheckNutrientsValueEnum.EMPTY_NUTRIENT, _a));
            }
            return __assign(__assign({}, acc), (_b = {}, _b[currentNutrientType] = CheckNutrientsValueEnum.FULL_NUTRIENT, _b));
        }, initialSelectedNutrients);
    }, [
        checkNutrients,
        initialSelectedNutrients,
        nutrientTypeToCheckPropMap,
        recipes,
        requiredNutrients,
    ]);
    var hasUserAllNutrients = useMemo(function () {
        if (!checkNutrients) {
            return false;
        }
        var isNutrientPresent = function (nutrientType) {
            return selectedNutrients[nutrientType] ===
                CheckNutrientsValueEnum.FULL_NUTRIENT ||
                selectedNutrients[nutrientType] ===
                    CheckNutrientsValueEnum.ALMOST_FULL_NUTRIENT;
        };
        return requiredNutrients.every(isNutrientPresent);
    }, [requiredNutrients, checkNutrients, selectedNutrients]);
    return {
        onChangeMealConfirm: onChangeMealConfirm,
        requiredNutrients: requiredNutrients,
        selectedNutrients: selectedNutrients,
        hasUserAllNutrients: hasUserAllNutrients,
        isLoading: isLoading,
    };
};
