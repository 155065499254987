export var getLoader = function (loaderKey) { return function (state) {
    return state.general.loaders.find(function (l) { return l === loaderKey; });
}; };
export var isSomethingLoading = function (state) {
    return state.general.fullPageLoaders.length > 0;
};
export var isAuthorizedSelector = function (state) {
    return state.general.isAuthorized;
};
export var isInitializedSelector = function (state) {
    return state.general.isInitialized;
};
export var isWalkThroughCompletedSelector = function (state) {
    return state.general.isWalkThroughCompleted;
};
export var isCountryCompletedSelector = function (state) {
    return state.general.isCountryCompleted;
};
export var redeemCouponSelector = function (state) {
    return state.general.redeemCoupon;
};
export var isMemberRegistryCompletedSelector = function (state) {
    return state.general.isMemberRegistryCompleted;
};
export var isFamilyMemberRegisterRequested = function (state) {
    return state.general.isFamilyMemberRegisterRequested;
};
export var isFamilyMemberMinor = function (state) {
    return state.general.isFamilyMemberMinor;
};
export var isPlanCreatedSelector = function (state) {
    return state.general.isPlanCreated;
};
export var membersColors = function (state) { return state.general.membersColors; };
export var isFirstSignUpSelector = function (state) {
    return state.general.isFirstSignUp;
};
