var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import CircularLoader from './icons/CircularLoader';
var Loader = function () {
    return (_jsx("div", __assign({ className: "fixed inset-0 flex justify-center items-center z-[100] bg-slate-50/60" }, { children: _jsx(CircularLoader, {}) })));
};
export default Loader;
