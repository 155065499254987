var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralActions, membersColors } from '../redux';
export var useGenerateRandomProfileColor = function (id) {
    var usersColors = useSelector(membersColors);
    var dispatch = useDispatch();
    var colorPalette = useMemo(function () { return [
        '#E36159',
        '#F1A09E',
        '#CDB93C',
        '#F2D667',
        '#C2D058',
        '#084B19',
        '#5EAD9E',
        '#B5D9F2',
        '#3478F6',
        '#CB7856',
        '#B0825C',
    ]; }, []);
    var saveMemberColor = useCallback(function (membersColors) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(GeneralActions.setMemberColors(membersColors));
            return [2 /*return*/];
        });
    }); }, [dispatch]);
    var getPlColorHsl = useCallback(function () {
        var _a;
        if (!id) {
            return '#FFF';
        }
        var sum = 0;
        for (var i = 0; i < id.length; i++) {
            sum += id.charCodeAt(i);
        }
        var index = sum % colorPalette.length;
        var color = colorPalette[index];
        if (usersColors[id]) {
            return usersColors[id];
        }
        if (Object.values(usersColors).includes(color)) {
            // If the color is already assigned to another member, find a new color
            var newIndex = (index + 1) % colorPalette.length;
            while (Object.values(usersColors).includes(colorPalette[newIndex])) {
                newIndex = (newIndex + 1) % colorPalette.length;
            }
            return colorPalette[newIndex];
        }
        // Otherwise, assign the color to the member
        saveMemberColor(__assign(__assign({}, usersColors), (_a = {}, _a[id] = color, _a)));
        return color;
    }, [colorPalette, id, saveMemberColor, usersColors]);
    return {
        getPlColorHsl: getPlColorHsl,
    };
};
