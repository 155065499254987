var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useVerifyCodeApi } from '../../hooks/api/authentication/useVerifyCode.api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GeneralActions } from '../../redux';
import { useSignInMutation } from '../../hooks/api/authentication/useSignIn.api';
import { useGetCustomerApi } from '../../hooks';
import { NotificationType, useLoader, useNotificationsMessage, } from '../../contexts';
import { useLanguage } from '../../locale';
import { isTokensResponse } from '../../models';
import { LoaderType } from '../../hooks/api/utils';
export var useOTPValidationFacade = function (email, session, onNext, onLoading) {
    var showNotifications = useNotificationsMessage().showNotifications;
    var t = useLanguage()[0];
    var verifyCodeApi = useVerifyCodeApi(onLoading !== undefined ? LoaderType.None : undefined).mutateAsync;
    var signInApi = useSignInMutation(onLoading !== undefined ? LoaderType.None : undefined).mutateAsync;
    var _a = useGetCustomerApi(false, onLoading !== undefined ? LoaderType.None : undefined), customer = _a.data, refetch = _a.refetch, isCustomerFetching = _a.isFetching;
    var _b = useState(), codeValue = _b[0], setCodeValue = _b[1];
    var _c = useState(true), isButtonDisabled = _c[0], setIsButtonDisabled = _c[1];
    var _d = useLoader(), openLoader = _d[0], closeLoader = _d[1];
    var _e = useState(''), localSession = _e[0], setLocalSession = _e[1];
    var dispatch = useDispatch();
    useEffect(function () {
        setLocalSession(session);
    }, [session]);
    //** CALLBACK **//
    var onPinCodeChange = useCallback(function (code) {
        setCodeValue(code);
        if (!code) {
            return;
        }
        if (code.length === 6) {
            return setIsButtonDisabled(false);
        }
        setIsButtonDisabled(true);
    }, [setCodeValue, setIsButtonDisabled]);
    var executeVerifyCode = useCallback(function () {
        return verifyCodeApi({
            ChallengeResponses: {
                USERNAME: email,
                ANSWER: codeValue,
            },
            Session: localSession,
        });
    }, [codeValue, email, localSession, verifyCodeApi]);
    var onUpdateSession = useCallback(function (newSession) {
        setLocalSession(newSession);
    }, []);
    var onMissingToken = useCallback(function (result) {
        onUpdateSession(result.Session);
        showNotifications({
            text: t('notificationAlert.codeError'),
            type: NotificationType.error,
            isError: true,
        });
        onLoading === null || onLoading === void 0 ? void 0 : onLoading(false);
    }, [onUpdateSession, showNotifications, t, onLoading]);
    var onSuccessToken = useCallback(function () {
        onNext === null || onNext === void 0 ? void 0 : onNext();
        showNotifications({ text: t('notificationAlert.mailVerified') });
    }, [onNext, showNotifications, t]);
    var verifyCode = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onLoading === null || onLoading === void 0 ? void 0 : onLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, executeVerifyCode()];
                case 2:
                    result = _a.sent();
                    if (!isTokensResponse(result)) {
                        onMissingToken(result);
                        return [2 /*return*/];
                    }
                    onSuccessToken();
                    return [4 /*yield*/, refetch()];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    onLoading === null || onLoading === void 0 ? void 0 : onLoading(false);
                    console.log('error', error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [executeVerifyCode, onMissingToken, onSuccessToken, refetch, onLoading]);
    useEffect(function () {
        if (onLoading !== undefined) {
            return;
        }
        if (isCustomerFetching) {
            openLoader();
        }
        else {
            closeLoader();
        }
    }, [closeLoader, isCustomerFetching, openLoader, onLoading]);
    useEffect(function () {
        if (customer) {
            dispatch(GeneralActions.setIsCountryCompleted(Boolean(customer.privacy)));
            dispatch(GeneralActions.setIsMemberRegistryCompleted(undefined));
            dispatch(GeneralActions.setIsAuthorized(true));
        }
    }, [customer, dispatch]);
    var onPressResend = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onLoading === null || onLoading === void 0 ? void 0 : onLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, signInApi({ email: email })];
                case 2:
                    res = _a.sent();
                    setLocalSession(res.Session);
                    showNotifications({ text: t('notificationAlert.mailResent') });
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.log('error', error_2);
                    return [3 /*break*/, 5];
                case 4:
                    onLoading === null || onLoading === void 0 ? void 0 : onLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [email, showNotifications, signInApi, t, onLoading]);
    return {
        onPinCodeChange: onPinCodeChange,
        verifyCode: verifyCode,
        onPressResend: onPressResend,
        isButtonDisabled: isButtonDisabled,
    };
};
