var WebErrorHandler = /** @class */ (function () {
    function WebErrorHandler(nonBlockingErrorFunction, sendLogout) {
        var _this = this;
        this.nonBlockingErrorFunction = nonBlockingErrorFunction;
        this.sendLogout = sendLogout;
        this.blockInteraction = function (message) {
            window.alert(message);
        };
        this.nonBlockingInteractionError = function (message) {
            var _a;
            if (message)
                (_a = _this.nonBlockingErrorFunction) === null || _a === void 0 ? void 0 : _a.call(_this, message);
        };
        this.sendUnauthorized = function () {
            var _a;
            (_a = _this.sendLogout) === null || _a === void 0 ? void 0 : _a.call(_this);
            window.location.reload();
        };
    }
    return WebErrorHandler;
}());
export { WebErrorHandler };
