export var StorageKeys;
(function (StorageKeys) {
    StorageKeys["ACCESS_TOKEN_KEY"] = "ACCESS_TOKEN_KEY";
    StorageKeys["REFRESH_TOKEN_KEY"] = "REFRESH_TOKEN_KEY";
    StorageKeys["IS_WALK_THROUGH_COMPLETED"] = "IS_WALK_THROUGH_COMPLETED";
    StorageKeys["IS_COUNTRY_COMPLETED"] = "IS_COUNTRY_COMPLETED";
    StorageKeys["IS_MEMBER_REGISTRY_COMPLETED"] = "IS_MEMBER_REGISTRY_COMPLETED";
    StorageKeys["IS_PLAN_REQUESTED"] = "IS_PLAN_REQUESTED";
    StorageKeys["CHAT_REQUEST_DATE"] = "CHAT_REQUEST_DATE";
    StorageKeys["FAMILY_TOPIC"] = "FAMILY_TOPIC";
    StorageKeys["LANGUAGE_TOPIC"] = "LANGUAGE_TOPIC";
    StorageKeys["PLAN"] = "PLAN";
    StorageKeys["RECIPE_DETAIL"] = "RECIPE_DETAIL";
    StorageKeys["REMINDERS_SETTINGS"] = "REMINDERS_SETTINGS";
    StorageKeys["COUPON_CODE_KEY"] = "COUPON_CODE_KEY";
    StorageKeys["APP_LANGUAGE"] = "APP_LANGUAGE";
    StorageKeys["UNIT_SYSTEM"] = "UNIT_SYSTEM";
})(StorageKeys || (StorageKeys = {}));
var NotImplementedStorage = /** @class */ (function () {
    function NotImplementedStorage() {
        this.getItem = function () {
            throw new Error('Not implemented');
        };
        this.setItem = function () {
            throw new Error('Not implemented');
        };
        this.removeItem = function () {
            throw new Error('Not implemented');
        };
    }
    return NotImplementedStorage;
}());
export { NotImplementedStorage };
