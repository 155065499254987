export var PreparationTime;
(function (PreparationTime) {
    PreparationTime["Small"] = "prep-time-small";
    PreparationTime["Medium"] = "prep-time-medium";
    PreparationTime["Long"] = "prep-time-long";
})(PreparationTime || (PreparationTime = {}));
export var FiltersSection;
(function (FiltersSection) {
    FiltersSection["Recap"] = "recap";
    FiltersSection["Categories"] = "categories";
    FiltersSection["PreparationTime"] = "preparation-time";
    FiltersSection["Ingredients"] = "ingredients";
    FiltersSection["FromWorld"] = "from-world";
    FiltersSection["Seasonal"] = "seasonal";
    FiltersSection["MacroNutrients"] = "nutrients";
    FiltersSection["Favourites"] = "favourites";
})(FiltersSection || (FiltersSection = {}));
