import { t } from 'i18n-js';
import { PlanterError } from '../models';
export var noop = function () { };
export var translateError = function (errorCode) {
    return t("errorCode.".concat(errorCode));
};
export var translatePlanterError = function (t, errorCode) {
    switch (errorCode) {
        case PlanterError.EmailAlreadyExist:
            return t('errorCode.emailAreadyExist');
        case PlanterError.ChildIsTooYoung:
            return t('errorCode.childIsTooYoung');
        case PlanterError.CodeAlreadyUsed:
            return t('errorCode.codeAlreadyUsed');
        case PlanterError.CodeGenerationImpossibleForDifferentFamily:
            return t('errorCode.codeGenerationImpossibleForDifferentFamily');
        case PlanterError.CodeGenerationImpossibleForFamilyLeader:
            return t('errorCode.codeGenerationImpossibleForFamilyLeader');
        case PlanterError.CodeGenerationNotFamilyLeader:
            return t('errorCode.codeGenerationNotFamilyLeader');
        case PlanterError.CodeNotExist:
            return t('errorCode.codeNotExist');
        case PlanterError.CodeNotFound:
            return t('errorCode.codeNotFound');
        case PlanterError.ExceededAllergies:
            return t('errorCode.exceededAllergies');
        case PlanterError.ExceededIngredients:
            return t('errorCode.exceededIngredients');
        case PlanterError.ExceededMembers:
            return t('errorCode.exceededMembers');
        case PlanterError.NotConfirmedEmail:
            return t('errorCode.notConfirmedEmail');
        case PlanterError.TooYoung:
            return t('errorCode.tooYoung');
        case PlanterError.UserNotFound:
            return t('errorCode.userNotFound');
        case PlanterError.WeeklyRecipeNotFound:
            return t('errorCode.weeklyRecipeNotFound');
        case PlanterError.PlanInCreation:
            return t('errorCode.planInCreation');
        case PlanterError.PlanNotDeleted:
            return t('errorCode.PlanNotDeleted');
        case PlanterError.BirthdayShouldBeGreaterThanThePreviousOne:
            return t('errorCode.BirthdayShouldBeGreaterThanThePreviousOne');
        default:
            return t('notificationAlert.genericError');
    }
};
export var encodeURIExtended = function (str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    });
};
export var encodeImageName = function (url) {
    if (!url)
        return;
    var substrings = url.split('/');
    var folderName = substrings.length === 1
        ? url // delimiter is not part of the string
        : substrings.slice(0, -1).join('/');
    var imgName = url.slice(url.lastIndexOf('/') + 1);
    return "".concat(folderName, "/").concat(encodeURIExtended(imgName));
};
