export var UserRole;
(function (UserRole) {
    UserRole["CompleteUser"] = "complete-user";
    UserRole["PlanPendingUser"] = "plan-pending-user";
    UserRole["UserPaymentNeeded"] = "user-payment-needed";
    UserRole["PartialUser"] = "partial-user";
    UserRole["AnonymousCouponRedeemUser"] = "anonymous-coupon-redeem-user";
    UserRole["RegisteredCouponRedeemUser"] = "registered-coupon-redeem-user";
    UserRole["NoCountryUser"] = "no-country-user";
    UserRole["Anonymous"] = "anonymous";
})(UserRole || (UserRole = {}));
