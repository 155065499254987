var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useMemo } from 'react';
import { useHandlePlanMealApi, useHandlePlanRecipeApi, useGetCustomerApi, useGetFamilyApi, useGetPersonApi, } from '../../hooks';
import { useFamilyAndGuestsManagerFacade } from '../familyAndGuestsManager/useFamilyAndGuestsManagerFacade';
import { NotificationPosition, useNotificationsMessage } from '../../contexts';
import { useLanguage } from '../../locale';
export var useDisableOrEnablePlanEntityFacade = function (_a) {
    var selectedDate = _a.selectedDate, type = _a.type, isDisabling = _a.isDisabling, recipeId = _a.recipeId, selectedRecipe = _a.selectedRecipe, meals = _a.meals;
    var t = useLanguage()[0];
    var customer = useGetCustomerApi(true).data;
    var personLeader = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _b = useGetFamilyApi(personLeader === null || personLeader === void 0 ? void 0 : personLeader.familyId, !!personLeader), family = _b.data, isLoading = _b.isLoading;
    var _c = useHandlePlanRecipeApi(), handlePlanRecipe = _c.mutateAsync, isLoadingHandlePlanRecipe = _c.isLoading;
    var _d = useHandlePlanMealApi(), handlePlanMeal = _d.mutateAsync, isLoadingHandlePlanMeal = _d.isLoading;
    var showNotifications = useNotificationsMessage().showNotifications;
    var isMeal = !recipeId;
    var _e = useFamilyAndGuestsManagerFacade(family === null || family === void 0 ? void 0 : family.Person), selectedMembers = _e.selectedMembers, isConfirmDisabled = _e.isConfirmDisabled, getMembersAddedLabel = _e.getMembersAddedLabel, onMemberPress = _e.onMemberPress, onChangeGuest = _e.onChangeGuest, isMemberSelected = _e.isMemberSelected, onAllMembersPress = _e.onAllMembersPress;
    var membersToShow = useMemo(function () {
        var _a;
        if (!isMeal) {
            var membersFromRecipe = isDisabling
                ? family === null || family === void 0 ? void 0 : family.Person.filter(function (el) { var _a; return (_a = selectedRecipe === null || selectedRecipe === void 0 ? void 0 : selectedRecipe.enabledPeopleIds) === null || _a === void 0 ? void 0 : _a.includes(el.id); })
                : family === null || family === void 0 ? void 0 : family.Person.filter(function (el) { var _a; return (_a = selectedRecipe === null || selectedRecipe === void 0 ? void 0 : selectedRecipe.disabledPeopleIds) === null || _a === void 0 ? void 0 : _a.includes(el.id); });
            return (membersFromRecipe === null || membersFromRecipe === void 0 ? void 0 : membersFromRecipe.length) ? membersFromRecipe : [];
        }
        var requestedPeopleIds = isDisabling
            ? 'enabledPeopleIds'
            : 'disabledPeopleIds';
        var requestedMeal = meals === null || meals === void 0 ? void 0 : meals.find(function (meal) { return (meal === null || meal === void 0 ? void 0 : meal.type) === type; });
        if (!requestedMeal) {
            return [];
        }
        var peopleIds = requestedMeal === null || requestedMeal === void 0 ? void 0 : requestedMeal.courses.flatMap(function (course) {
            return course.recipes.flatMap(function (recipe) { return recipe[requestedPeopleIds]; });
        });
        var uniquePeopleIds = Array.from(new Set(peopleIds));
        return (_a = family === null || family === void 0 ? void 0 : family.Person.filter(function (el) { return uniquePeopleIds === null || uniquePeopleIds === void 0 ? void 0 : uniquePeopleIds.includes(el.id); })) !== null && _a !== void 0 ? _a : [];
    }, [
        family === null || family === void 0 ? void 0 : family.Person,
        isDisabling,
        isMeal,
        meals,
        selectedRecipe === null || selectedRecipe === void 0 ? void 0 : selectedRecipe.disabledPeopleIds,
        selectedRecipe === null || selectedRecipe === void 0 ? void 0 : selectedRecipe.enabledPeopleIds,
        type,
    ]);
    var drawerTitle = useMemo(function () {
        if (isDisabling) {
            return t("planPage.".concat(isMeal ? 'disableMeal' : 'disableRecipe'));
        }
        return t("planPage.".concat(isMeal ? 'enableMeal' : 'enableRecipe'));
    }, [isDisabling, isMeal, t]);
    var componentsFooterDescription = useMemo(function () {
        if (isDisabling) {
            return isMeal
                ? t('plan.disableMealFooterDescription')
                : t('plan.disableRecipeFooterDescription');
        }
        return isMeal
            ? t('plan.enableMealFooterDescription')
            : t('plan.enableRecipeFooterDescription');
    }, [isDisabling, isMeal, t]);
    var guestsId = useMemo(function () {
        var _a;
        if (!recipeId && type) {
            return (membersToShow === null || membersToShow === void 0 ? void 0 : membersToShow.length) === selectedMembers.length
                ? (_a = meals === null || meals === void 0 ? void 0 : meals.find(function (meal) { return (meal === null || meal === void 0 ? void 0 : meal.type) === type; })) === null || _a === void 0 ? void 0 : _a.courses.reduce(function (acc, current) { return __spreadArray(__spreadArray([], acc, true), current.guestsPeopleIds, true); }, [])
                : [];
        }
        return (membersToShow === null || membersToShow === void 0 ? void 0 : membersToShow.length) === selectedMembers.length
            ? selectedRecipe === null || selectedRecipe === void 0 ? void 0 : selectedRecipe.guestsPeopleIds
            : [];
    }, [
        meals,
        membersToShow === null || membersToShow === void 0 ? void 0 : membersToShow.length,
        recipeId,
        selectedMembers.length,
        selectedRecipe === null || selectedRecipe === void 0 ? void 0 : selectedRecipe.guestsPeopleIds,
        type,
    ]);
    var onDisableConfirm = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!recipeId) return [3 /*break*/, 2];
                    return [4 /*yield*/, handlePlanRecipe({
                            isActive: false,
                            peopleId: selectedMembers,
                            guestsId: guestsId,
                            recipeId: recipeId,
                            date: selectedDate,
                            type: type,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, handlePlanMeal({
                        isActive: false,
                        peopleId: selectedMembers,
                        guestsId: guestsId,
                        type: type,
                        date: selectedDate,
                    })];
                case 3:
                    _a.sent();
                    showNotifications({
                        text: t("toast.".concat(recipeId ? 'titleDisableRecipe' : 'titleDisableMeal')),
                        position: NotificationPosition.bottom,
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [
        guestsId,
        handlePlanMeal,
        handlePlanRecipe,
        recipeId,
        selectedDate,
        selectedMembers,
        showNotifications,
        t,
        type,
    ]);
    var onEnableConfirm = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!recipeId) return [3 /*break*/, 2];
                    return [4 /*yield*/, handlePlanRecipe({
                            isActive: true,
                            peopleId: selectedMembers,
                            recipeId: recipeId,
                            date: selectedDate,
                            type: type,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, handlePlanMeal({
                        isActive: true,
                        peopleId: selectedMembers,
                        type: type,
                        date: selectedDate,
                    })];
                case 3:
                    _a.sent();
                    showNotifications({
                        text: t("toast.".concat(recipeId ? 'titleEnableRecipe' : 'titleEnableMeal')),
                        position: NotificationPosition.bottom,
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [
        recipeId,
        handlePlanMeal,
        selectedMembers,
        type,
        selectedDate,
        showNotifications,
        t,
        handlePlanRecipe,
    ]);
    var onConfirm = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isDisabling) return [3 /*break*/, 2];
                    return [4 /*yield*/, onDisableConfirm()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, onEnableConfirm()];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [isDisabling, onDisableConfirm, onEnableConfirm]);
    return {
        family: family,
        getMembersAddedLabel: getMembersAddedLabel,
        isMemberSelected: isMemberSelected,
        isConfirmDisabled: isConfirmDisabled,
        isLoading: isLoading,
        onMemberPress: onMemberPress,
        onChangeGuest: onChangeGuest,
        onConfirm: onConfirm,
        selectedMembers: selectedMembers,
        onAllMembersPress: onAllMembersPress,
        isLoadingDisableEntity: isLoadingHandlePlanMeal || isLoadingHandlePlanRecipe,
        isLoadingEnableEntity: isLoadingHandlePlanMeal || isLoadingHandlePlanRecipe,
        membersToShow: membersToShow,
        drawerTitle: drawerTitle,
        componentsFooterDescription: componentsFooterDescription,
    };
};
