var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AMERICAN_READABLE_DATE_FORMAT, capitalizeFirstLetter, DATE_PICKER_FORMAT, datesManager, groupShoppingListByCategory, PlanStatus, READABLE_DATE_FORMAT, useCreateFoodStorageApi, useFoodStorageManagerHook, useGetCustomerApi, useGetFamilyPlanStatusApi, useGetPersonApi, useGetShoppingList, useLanguage, useShoppingListManagerFacade, } from '@planter/common';
import dayjs from 'dayjs';
import { LoaderType } from '../../hooks/api/utils';
import { getCorrectIngredientQuantityForEachMember } from '../../utils';
export var EXCLUDEDINGREDIENTS = 'CAT_SHOPPING_HIDDEN';
var KILOS = 1000;
export var KG_UNIT = 'Kg';
export var useShoppingListFacade = function (shoppingListScreenParams, loaderType) {
    if (loaderType === void 0) { loaderType = LoaderType.FullPage; }
    // * DATA * //
    var _a = useLanguage(), t = _a[0], locale = _a[2], formatNumber = _a[3];
    var today = dayjs().format(READABLE_DATE_FORMAT).toString();
    /*
     [WARNING] This is a workaround needed to persist route.params when navigating
     from and back to the same screen, otherwise our route.params would be reset
     due to a broken react-navigation implementation.
  
     See here: https://reactnavigation.org/docs/hello-react-navigation
     */
    var _b = useState(shoppingListScreenParams), persistedRouteParams = _b[0], setPersistedRouteParams = _b[1];
    useEffect(function () {
        // this skips the react-navigation screen state reset;
        if (!shoppingListScreenParams) {
            return;
        }
        setPersistedRouteParams(shoppingListScreenParams);
    }, [shoppingListScreenParams]);
    //* FOOD STORAGE IMPLEMENTATION *//
    var _c = useFoodStorageManagerHook(LoaderType.None), foodStoragesList = _c.foodStoragesList, isAllFoodStoragesLoading = _c.isAllFoodStoragesLoading, refetchAllFoodStorage = _c.refetchAllFoodStorage, isFetchingAllFoodStorage = _c.isFetchingAllFoodStorage;
    var createFoodStorage = useCreateFoodStorageApi(LoaderType.None).mutate;
    var lastFoodStoragesItem = useMemo(function () {
        var foodStorage = foodStoragesList.find(function (el) { return el.id === (persistedRouteParams === null || persistedRouteParams === void 0 ? void 0 : persistedRouteParams.id); });
        if (!foodStorage) {
            return foodStoragesList[foodStoragesList.length - 1];
        }
        return foodStorage;
    }, [foodStoragesList, persistedRouteParams]);
    var createFoodStorageName = useCallback(function (dates) {
        var formattedDates = dates.map(function (date) {
            return capitalizeFirstLetter(datesManager(date, DATE_PICKER_FORMAT).toString('dddd DD/MM'));
        });
        return formattedDates.toString();
    }, []);
    var shoppingListRangeDates = useMemo(function () {
        var selectedUserDate = (persistedRouteParams !== null && persistedRouteParams !== void 0 ? persistedRouteParams : {}).selectedUserDate;
        if (selectedUserDate) {
            return selectedUserDate;
        }
        if (lastFoodStoragesItem) {
            return [lastFoodStoragesItem.validFrom, lastFoodStoragesItem.validTo];
        }
    }, [lastFoodStoragesItem, persistedRouteParams]);
    var shoppingListApiParams = useMemo(function () {
        return {
            minDate: dayjs(shoppingListRangeDates === null || shoppingListRangeDates === void 0 ? void 0 : shoppingListRangeDates[0]).toISOString(),
            maxDate: dayjs(shoppingListRangeDates === null || shoppingListRangeDates === void 0 ? void 0 : shoppingListRangeDates[1]).toISOString(),
        };
    }, [shoppingListRangeDates]);
    //* FOOD STORAGE IMPLEMENTATION *//
    var customer = useGetCustomerApi().data;
    var person = useGetPersonApi(customer === null || customer === void 0 ? void 0 : customer.personId).data;
    var _d = useGetFamilyPlanStatusApi(person === null || person === void 0 ? void 0 : person.Family.id), family = _d.data, isLoadingFamilyPlanStatus = _d.isLoading;
    var isPlanDone = (family === null || family === void 0 ? void 0 : family.status) === PlanStatus.PLAN_DONE && !isLoadingFamilyPlanStatus;
    var _e = useGetShoppingList(shoppingListApiParams, isPlanDone && !!shoppingListApiParams, loaderType), shoppingList = _e.data, isShoppingListLoading = _e.isLoading;
    // INGREDIENT LIST GROUPED
    /**
     * It takes an array of ingredients and returns an array of sections, where each section contains a
     * title and an array of ingredients
     * @param {ShoppingListIngredient[]} [ingredients] - ShoppingListIngredient[]
     * @returns An array of objects with a title and data property.
     */
    var groupedShoppingList = useMemo(function () {
        if (!(shoppingList === null || shoppingList === void 0 ? void 0 : shoppingList.ingredients)) {
            return [];
        }
        var convertedIngredients = shoppingList.ingredients.map(function (ingredient) {
            if (ingredient.quantity >= KILOS &&
                ingredient.ingredientUnitOfMeasure === 'g') {
                return __assign(__assign({}, ingredient), { quantity: parseFloat((ingredient.quantity / 1000).toFixed(2)), ingredientUnitOfMeasure: KG_UNIT });
            }
            return ingredient;
        });
        var categories = convertedIngredients.map(function (ingredient) { return ingredient.shoppingListCategory; });
        var uniqueCategories = categories.filter(function (category, index) { return categories.indexOf(category) === index; });
        return uniqueCategories.map(function (category) {
            return {
                title: category,
                id: convertedIngredients.filter(function (ingredient) { return ingredient.shoppingListCategory === category; })[0].shoppingListCategoryId,
                data: convertedIngredients.filter(function (ingredient) { return ingredient.shoppingListCategory === category; }),
            };
        });
    }, [shoppingList === null || shoppingList === void 0 ? void 0 : shoppingList.ingredients]);
    // INGREDIENT LIST GROUPED + ORDERED
    var shoppingCategoriesListOrder = useMemo(function () { return [
        'CAT_SHOPPING_HIDDEN',
        'CAT_SHOPPING_VERDURA',
        'CAT_SHOPPING_FRUTTA',
        'CAT_SHOPPING_PASTA',
        'CAT_SHOPPING_RISO_E_ALTRI_CEREALI',
        'CAT_SHOPPING_CEREALI_DA_COLAZIONE',
        'CAT_SHOPPING_PANE_E_PRODOTTI_DA_FORNO',
        'CAT_SHOPPING_FARINE',
        'CAT_SHOPPING_LEGUMI',
        'CAT_SHOPPING_BANCO_FRIGO',
        'CAT_SHOPPING_BEVANDE_VEGETALI',
        'CAT_SHOPPING_FRUTTA_SECCA_E_SEMI_OLEOSI',
        'CAT_SHOPPING_CONDIMENTI',
        'CAT_SHOPPING_SPEZIE_ED_ERBE_AROMATICHE',
        'CAT_SHOPPING_CONSERVE',
        'CAT_SHOPPING_DOLCIFICANTI',
        'CAT_SHOPPING_VARIE',
    ]; }, []);
    /**
     * A function that takes the groupedShoppingList and the shoppingCategoriesListOrder
     * and returns a new array of ShoppingListSectionListData[] ordered.
     * @returns A new array of ShoppingListSectionListData[] ordered following shoppingCategoriesListOrder
     * without the EXCLUDEDINGREDIENTS section and with the ingredients of each section alphabetically sorted.
     */
    var groupedShoppingListOrdered = useMemo(function () {
        var _a;
        var groupedShoppingListOrdered = [];
        groupedShoppingListOrdered = groupedShoppingList.sort(function (a, b) {
            return (shoppingCategoriesListOrder.indexOf(a === null || a === void 0 ? void 0 : a.id) -
                shoppingCategoriesListOrder.indexOf(b === null || b === void 0 ? void 0 : b.id));
        });
        ((_a = groupedShoppingListOrdered[0]) === null || _a === void 0 ? void 0 : _a.id) === EXCLUDEDINGREDIENTS &&
            groupedShoppingListOrdered.shift();
        // Alphabetical sorting of the ingredients in each category
        groupedShoppingListOrdered.forEach(function (item) {
            item.data.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        });
        return groupedShoppingListOrdered;
    }, [groupedShoppingList, shoppingCategoriesListOrder]);
    var getShoppingListDecimalPart = useCallback(function (ingredient) {
        var _a, _b;
        if (!ingredient)
            return '';
        if (ingredient.ingredientUnitOfMeasure === KG_UNIT) {
            return "".concat(formatNumber(ingredient.quantity), " ").concat((_a = ingredient.ingredientUnitOfMeasure) === null || _a === void 0 ? void 0 : _a.toLowerCase());
        }
        return ((_b = getCorrectIngredientQuantityForEachMember({
            ingredient: ingredient,
            formatNumber: formatNumber,
        })) !== null && _b !== void 0 ? _b : '');
    }, [formatNumber]);
    var datePickerLabel = useMemo(function () {
        if (!shoppingListRangeDates) {
            return '';
        }
        var dates = shoppingListRangeDates.map(function (date) {
            var longDateFormat = locale === 'us' ? AMERICAN_READABLE_DATE_FORMAT : READABLE_DATE_FORMAT;
            if ((shoppingListRangeDates === null || shoppingListRangeDates === void 0 ? void 0 : shoppingListRangeDates.at(0)) === (shoppingListRangeDates === null || shoppingListRangeDates === void 0 ? void 0 : shoppingListRangeDates.at(1))) {
                return dayjs(date, DATE_PICKER_FORMAT).format(longDateFormat);
            }
            var shortFormatDate = locale === 'us' ? 'MM/DD' : 'DD/MM';
            return dayjs(date, DATE_PICKER_FORMAT).format(shortFormatDate);
        });
        var allEqual = dates.every(function (d, _, array) { return d === array[0]; });
        var singleDateToRender = dates[0] === today ? t('today') : dates[0];
        return allEqual ? singleDateToRender : "".concat(dates[0], " - ").concat(dates[1]);
    }, [locale, shoppingListRangeDates, t, today]);
    var daysSelectionLabel = useMemo(function () {
        return (shoppingListRangeDates === null || shoppingListRangeDates === void 0 ? void 0 : shoppingListRangeDates.at(0)) === (shoppingListRangeDates === null || shoppingListRangeDates === void 0 ? void 0 : shoppingListRangeDates.at(1))
            ? t('day')
            : t('fromTo');
    }, [shoppingListRangeDates, t]);
    // * CALLBACK * //
    var groupShoppingListDataByCategory = useMemo(function () { return groupShoppingListByCategory(shoppingList === null || shoppingList === void 0 ? void 0 : shoppingList.ingredients); }, [shoppingList === null || shoppingList === void 0 ? void 0 : shoppingList.ingredients]);
    var _f = useShoppingListManagerFacade({
        groupedShoppingListOrdered: groupedShoppingListOrdered,
        foodStorage: lastFoodStoragesItem,
    }), purchasedIngredients = _f.purchasedIngredients, selectableIngredientsSections = _f.selectableIngredientsSections, onIngredientSelectionPress = _f.onIngredientSelectionPress;
    useEffect(function () {
        if (lastFoodStoragesItem || isAllFoodStoragesLoading) {
            return;
        }
        var todayDateParam = new Date().toISOString();
        console.log('todayDateParam', todayDateParam);
        createFoodStorage({
            validFrom: todayDateParam,
            validTo: todayDateParam,
            name: createFoodStorageName([todayDateParam, todayDateParam]),
        });
    }, [
        createFoodStorage,
        createFoodStorageName,
        isAllFoodStoragesLoading,
        lastFoodStoragesItem,
    ]);
    return {
        isShoppingListLoading: isShoppingListLoading || !isPlanDone || isAllFoodStoragesLoading,
        groupedShoppingListOrdered: groupedShoppingListOrdered,
        planCreated: (family === null || family === void 0 ? void 0 : family.status) === PlanStatus.PLAN_DONE,
        shoppingList: shoppingList,
        getShoppingListDecimalPart: getShoppingListDecimalPart,
        daysSelectionLabel: daysSelectionLabel,
        groupedShoppingList: groupShoppingListDataByCategory,
        datePickerLabel: datePickerLabel,
        purchasedIngredients: purchasedIngredients,
        selectableIngredientsSections: selectableIngredientsSections,
        onIngredientSelectionPress: onIngredientSelectionPress,
        refetchAllFoodStorage: refetchAllFoodStorage,
        isFetchingAllFoodStorage: isFetchingAllFoodStorage,
        t: t,
    };
};
